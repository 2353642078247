@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?z6nlkd');
  src:  url('../fonts/icomoon.eot?z6nlkd#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?z6nlkd') format('truetype'),
  url('../fonts/icomoon.woff?z6nlkd') format('woff'),
  url('../fonts/icomoon.svg?z6nlkd#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clock-standard:before {
  content: "\e983";
}
.icon-condition:before {
  content: "\e984";
}
.icon-cross-alt:before {
  content: "\e985";
}
.icon-cursor:before {
  content: "\e986";
}
.icon-description:before {
  content: "\e987";
}
.icon-eye-alt:before {
  content: "\e988";
}
.icon-file:before {
  content: "\e989";
}
.icon-floorplan-alt:before {
  content: "\e98a";
}
.icon-gallery-upload:before {
  content: "\e98b";
}
.icon-link:before {
  content: "\e98c";
}
.icon-live-integrate:before {
  content: "\e98d";
}
.icon-menu:before {
  content: "\e98e";
}
.icon-pdf:before {
  content: "\e98f";
}
.icon-pencil-fill:before {
  content: "\e990";
}
.icon-replace:before {
  content: "\e991";
}
.icon-review:before {
  content: "\e992";
  color: #6a7074;
}
.icon-rocket:before {
  content: "\e993";
}
.icon-schedule-dashboard:before {
  content: "\e994";
}
.icon-sortby:before {
  content: "\e995";
}
.icon-sortby-ascending:before {
  content: "\e996";
}
.icon-suite:before {
  content: "\e997";
  color: #6a7074;
}
.icon-tv-alt:before {
  content: "\e998";
}
.icon-tv-check:before {
  content: "\e999";
}
.icon-add-group:before {
  content: "\e900";
}
.icon-angle-left:before {
  content: "\e901";
}
.icon-arrow-down:before {
  content: "\e902";
}
.icon-arrow-left:before {
  content: "\e903";
}
.icon-arrow-right:before {
  content: "\e904";
}
.icon-arrow-to-bottom:before {
  content: "\e905";
}
.icon-arrow-up:before {
  content: "\e906";
}
.icon-audio:before {
  content: "\e907";
}
.icon-average-floor:before {
  content: "\e908";
}
.icon-bars:before {
  content: "\e909";
}
.icon-brush:before {
  content: "\e90a";
}
.icon-building:before {
  content: "\e90b";
}
.icon-building-alt:before {
  content: "\e90c";
}
.icon-business:before {
  content: "\e90d";
}
.icon-calendar:before {
  content: "\e90e";
}
.icon-camera:before {
  content: "\e90f";
}
.icon-camera-alt:before {
  content: "\e910";
}
.icon-caret-down:before {
  content: "\e911";
}
.icon-check-circle:before {
  content: "\e912";
}
.icon-chevron-bottom:before {
  content: "\e913";
}
.icon-chevron-down-solid:before {
  content: "\e914";
}
.icon-chevron-left:before {
  content: "\e915";
}
.icon-chevron-right:before {
  content: "\e916";
}
.icon-chevron-top:before {
  content: "\e917";
}
.icon-chevron-up-solid:before {
  content: "\e918";
}
.icon-circle:before {
  content: "\e919";
}
.icon-clipboard-list:before {
  content: "\e91a";
}
.icon-clock:before {
  content: "\e91b";
}
.icon-clock-alt:before {
  content: "\e91c";
}
.icon-compress-wide:before {
  content: "\e91d";
}
.icon-copyright:before {
  content: "\e91e";
}
.icon-cross:before {
  content: "\e91f";
}
.icon-cross-cirlce:before {
  content: "\e920";
}
.icon-dashboard:before {
  content: "\e921";
}
.icon-details:before {
  content: "\e922";
}
.icon-dot-circle:before {
  content: "\e923";
}
.icon-edit:before {
  content: "\e924";
}
.icon-edit-alt:before {
  content: "\e925";
}
.icon-egg-box:before {
  content: "\e926";
}
.icon-ellipsis-h:before {
  content: "\e927";
}
.icon-ellipsis-v:before {
  content: "\e928";
}
.icon-envelope:before {
  content: "\e929";
}
.icon-expand:before {
  content: "\e92a";
}
.icon-external-link:before {
  content: "\e92b";
}
.icon-facebook:before {
  content: "\e92c";
}
.icon-facebook-square:before {
  content: "\e92d";
}
.icon-floorplan:before {
  content: "\e92e";
}
.icon-freight-elevator:before {
  content: "\e92f";
}
.icon-gallery:before {
  content: "\e930";
}
.icon-grid-horizontal:before {
  content: "\e931";
}
.icon-grid-vertical:before {
  content: "\e932";
}
.icon-help:before {
  content: "\e933";
}
.icon-home:before {
  content: "\e934";
}
.icon-instagram:before {
  content: "\e935";
}
.icon-integration:before {
  content: "\e936";
  color: #341062;
}
.icon-join:before {
  content: "\e937";
}
.icon-key:before {
  content: "\e938";
}
.icon-layers:before {
  content: "\e939";
}
.icon-library:before {
  content: "\e93a";
}
.icon-linkedin:before {
  content: "\e93b";
}
.icon-linkedin-square:before {
  content: "\e93c";
}
.icon-list:before {
  content: "\e93d";
}
.icon-location:before {
  content: "\e93e";
}
.icon-lock:before {
  content: "\e93f";
}
.icon-lock-alt:before {
  content: "\e940";
}
.icon-lock-fill:before {
  content: "\e941";
}
.icon-login:before {
  content: "\e942";
}
.icon-logout:before {
  content: "\e943";
}
.icon-mail:before {
  content: "\e944";
}
.icon-management:before {
  content: "\e945";
}
.icon-map-marker:before {
  content: "\e946";
}
.icon-map-marker-alt:before {
  content: "\e947";
}
.icon-map-marker-light:before {
  content: "\e948";
}
.icon-metro:before {
  content: "\e949";
}
.icon-microphone:before {
  content: "\e94a";
}
.icon-microphone-slash:before {
  content: "\e94b";
}
.icon-minus:before {
  content: "\e94c";
}
.icon-minus-circle:before {
  content: "\e94d";
}
.icon-month:before {
  content: "\e94e";
}
.icon-mute:before {
  content: "\e94f";
}
.icon-notification:before {
  content: "\e950";
}
.icon-organisation:before {
  content: "\e951";
}
.icon-paperclip:before {
  content: "\e952";
}
.icon-parking-ratio:before {
  content: "\e953";
}
.icon-passenger-elevator:before {
  content: "\e954";
}
.icon-pause:before {
  content: "\e955";
}
.icon-pause-circle:before {
  content: "\e956";
}
.icon-phone:before {
  content: "\e957";
}
.icon-platform:before {
  content: "\e958";
}
.icon-play:before {
  content: "\e959";
}
.icon-play-circle:before {
  content: "\e95a";
}
.icon-play-video:before {
  content: "\e95b";
}
.icon-plus:before {
  content: "\e95c";
}
.icon-plus-circle:before {
  content: "\e95d";
}
.icon-portal:before {
  content: "\e95e";
  color: #341062;
}
.icon-radio:before {
  content: "\e95f";
}
.icon-renovate:before {
  content: "\e960";
}
.icon-repeat:before {
  content: "\e961";
}
.icon-save:before {
  content: "\e962";
}
.icon-schedule:before {
  content: "\e963";
}
.icon-schedule-alt:before {
  content: "\e964";
}
.icon-search:before {
  content: "\e965";
}
.icon-search-right:before {
  content: "\e966";
}
.icon-setting:before {
  content: "\e967";
}
.icon-share:before {
  content: "\e968";
}
.icon-share-screen:before {
  content: "\e969";
}
.icon-signout:before {
  content: "\e96a";
}
.icon-size:before {
  content: "\e96b";
}
.icon-spacing-horizontal:before {
  content: "\e96c";
}
.icon-spacing-vertical:before {
  content: "\e96d";
}
.icon-stop:before {
  content: "\e96e";
}
.icon-stop-circle:before {
  content: "\e96f";
}
.icon-telecom:before {
  content: "\e970";
}
.icon-tour:before {
  content: "\e971";
}
.icon-trash:before {
  content: "\e972";
}
.icon-tv:before {
  content: "\e973";
}
.icon-twitter:before {
  content: "\e974";
}
.icon-upload:before {
  content: "\e975";
}
.icon-user:before {
  content: "\e976";
}
.icon-user-circle:before {
  content: "\e977";
}
.icon-user-right:before {
  content: "\e978";
}
.icon-users:before {
  content: "\e979";
}
.icon-user-up:before {
  content: "\e97a";
}
.icon-video:before {
  content: "\e97b";
}
.icon-video-slash:before {
  content: "\e97c";
}
.icon-virtual:before {
  content: "\e97d";
}
.icon-volume:before {
  content: "\e97e";
}
.icon-webcam:before {
  content: "\e97f";
}
.icon-year-built:before {
  content: "\e980";
}
.icon-zoom-in:before {
  content: "\e981";
}
.icon-zoom-out:before {
  content: "\e982";
}
