/*
  Media Queries
 */

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .listing--schedule-col {
    flex-direction: column;
  }
  .week--time {
    padding-right: 0;
    margin-bottom: 16px;
  }
  .hours--time,
  .listing--schedule-col .select--group,
  .ca--cover,
  .ca__btn-canvas {
    width: 100%;
  }
  .ca--cover-large {
    width: 55%;
  }
  .group-input .outline__button {
    min-width: 150px;
  }
}
@media screen and (min-width: 576px) {
  .flex-row-sm-reverse {
    flex-direction: row-reverse;
  }
  .modal--uploadPhotos .modal-dialog {
    max-width: 860px;
  }
  .modal-settings .modal-dialog {
    max-width: 700px;
  }
  .listing--modal .modal-dialog,
  .office-modal .modal-dialog {
    max-width: 640px;
  }
  .footer-n .footer__logo {
    margin-right: 20px;
  }
  .modal--video .modal-dialog {
    max-width: 100%;
  }
  .users--content-tenant {
    margin-bottom: -23px;
  }
  .liveTour--amenities-main {
    margin-top: 32px;
  }
  .tourListing--block .tourListing--row .para__md-dark {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ml-sm-20 {
    margin-left: 20px;
  }
  .dashboard--content-new .box--expand {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .tourListing--block .tourListing__img {
    margin-left: -1px;
    margin-top: -1px;
    margin-bottom: -1px;
  }
}
@media screen and (min-width: 768px) {
  .steps--content .row .col-12 {
    border-right: 1px solid rgba(188, 186, 184, 0.5);
  }
  .steps--content .row .col-12:last-child {
    border-right: 0;
  }
  .modal--video-set {
    max-width: 640px;
    height: 360px !important;
  }
  .users--content-tenant {
    margin-top: -90px;
  }
  .liveTour--mobile {
    display: none;
  }
  .sec--vertical-md {
    min-height: 100vh;
  }
  .sec--vertical-md .sec--vertical-middle {
    min-height: calc(100vh - 65px);
    display: flex;
    align-items: center;
  }
  .vt--share {
    min-height: 100vh;
    align-items: center;
  }
  .liveTour--options {
    min-width: 360px;
  }
  .liveTour--content-broker .liveTour--options:before {
    content: '';
    width: 21px;
    background: linear-gradient(95.75deg, #1C1F20 2.38%, rgba(28, 31, 32, 0) 86.57%);
    position: absolute;
    left: -20px;
    top: 0;
    bottom: 0;
    transform: rotate(180deg);
    pointer-events: none;
  }
  .liveTour--options li:last-child .liveTourOpt__box {
    padding-left: 20px;
    border-left: 1px solid rgba(213,217,224,0.3);
  }
  .tourListing--canvas {
    overflow: hidden;
  }
  .tourListing--scroll {
    max-height: 324px;
    overflow-y: auto;
    padding-right: 3px;
  }
  .tourListing--canvas.tourListing--canvas-shadow-effect:after {
    content: '';
    box-shadow: 0px -54px 10px rgba(255, 255, 255, 0.5);
    position: absolute;
    bottom: -58px;
    left: 0;
    right: 0;
    height: 27px;
  }
  .tourListing--scroll::-webkit-scrollbar {
    width: 3px;
  }
  .tourListing--scroll::-webkit-scrollbar-thumb {
    background: #C4C4C4;
    border-radius: 5px;
  }
  .dashboard--newAccount .admin__button {
    min-width: 230px;
  }
  .tourEdit--nl {
    margin-top: 140px;
  }
  .tourPre--content .liveTour--options {
    min-width: 150px;
  }
}
@media screen and (min-width: 992px) {
  .navbar__list {
    margin-right: 50px;
  }
  .navbar-light .navbar__list .nav-link,
  .navbar__list .nav-link {
    margin: 4px 25px;
  }
  .row--space-wide {
    margin-left: -25px;
    margin-right: -25px;
  }
  .row--space-wide>.col-12 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .form--content .navbar__header .navbar-brand,
  .menu__toggle {
    display: none;
  }
  .vc--mobile {
    display: none;
  }
  .about--block:nth-child(odd) .about--detail,
  .about--block:nth-child(even) .about--img {
    padding-right: 120px;
  }
  .about--block:nth-child(even) .about--detail,
  .about--block:nth-child(odd) .about--img {
    padding-left: 120px;
  }
  .form--content .navbar__list {
    margin-left: 0;
  }
  .form--banner .form__brand {
    display: block;
  }
  .form--page,
  .dashboard--page {
    overflow: hidden;
  }
  .form--banner,
  .form--content,
  .dashboard--content {
    height: 100vh;
    overflow-y: auto;
  }
  .dashboard--container {
    padding-left: 70px;
    padding-right: 70px;
  }
  .al--block .al--listings-component {
    padding: 0px 10px;
  }
  .listingFinal--content {
    display: flex;
    align-items: center;
  }
  .virtual--video-block {
    position: sticky;
    top: 0;
    margin-right: 0;
  }
  .empty--box {
    height: calc(100vh - 107px);
    margin-top: 0;
  }
  .empty--listings {
    height: calc(100vh - 152px);
  }
  .standby--content {
    margin-top: 0;
    height: calc(100vh - 72px);
    display: flex;
    align-items: center;
  }
  .info--block {
    padding-left: 24px;
    padding-right: 24px;
  }
  .row--gif .col-lg-2 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .al__address {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .available--listing-cols .scroll--listing-cols {
    max-height: 890px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .liveTour--amenities-main {
    margin-top: 44px;
  }
  .voiceRecord--block {
    max-height: 180px;
    margin-bottom: 16px;
    overflow-y: auto;
  }
  .voiceRecord--block::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
  }
  .voiceRecord--block::-webkit-scrollbar-thumb {
    background-color: #bcbab8;
  }
  .sec--vertical-lg,
  .virtualTour--combine {
    min-height: 100vh;
  }
  .sec--vertical-lg .sec--vertical-middle,
  .vt--combine-alt {
    min-height: calc(100vh - 65px);
    display: flex;
    align-items: center;
  }
  .share--realistic .share--content {
    padding-left: 240px;
  }
  .al--block-lg .al--adress-component {
    min-width: 34%;
    max-width: 34%;
  }
  .al--block-lg .al--listings-component {
    min-width: 42%;
    max-width: 42%;
  }
  .al--block-sm .al--adress-component {
    min-width: 30%;
    max-width: 30%;
  }
  .al--block-sm .al--listings-component {
    min-width: 48%;
    max-width: 48%;
  }
  .al--block-md .al--adress-component {
    min-width: 40%;
    max-width: 40%;
  }
  .al--block-md .al--listings-component {
    min-width: 44%;
    max-width: 44%;
  }
  .al--block .al--btn-component {
    width: auto;
    min-width: 10px;
    max-width: 100%;
  }
  .al--block.buildings--block .bb-sf {
    min-width: 60%;
    max-width: 60%;
  }
  .available--listing-map {
    height: 890px;
    overflow: hidden;
  }
  .unpublished--col {
    margin-top: -60px;
  }
  .toggle__btn {
    display: none;
  }
  .dashboard--sticky {
    overflow-y: hidden;
  }
  .dashboard--bar .dashboard__heading {
    line-height: 48px;
  }
  .al--lg-alt .al--btn-component {
    min-width: 24%;
    max-width: 24%;
  }
  .show--col-list .admin__button {
    min-width: 94px;
  }
}
@media screen and (min-width: 1200px) {
  .container--expand {
    max-width: 1170px;
  }
  .row--space-price,
  .row--space-price .row {
    margin-left: -30px;
    margin-right: -30px;
  }
  .row--space-price .col-12 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .row-availableListings>.col-lg-4 {
    -ms-flex: 0 0 34.5%;
    flex: 0 0 34.5%;
    max-width: 34.5%;
  }
  .row-availableListings>.col-lg-8 {
    -ms-flex: 0 0 65.5%;
    flex: 0 0 65.5%;
    max-width: 65.5%;
  }
  .broker--accordian {
    min-height: 420px;
  }
  .al--block-md .al--adress-component {
    min-width: 34%;
    max-width: 34%;
  }
  .al--block-md .al--listings-component {
    min-width: 48%;
    max-width: 48%;
  }
  .al--block-md .al__address {
    -webkit-line-clamp: 1;
  }
  .ls--left {
    min-width: 204px;
    max-width: 204px;
    margin-right: 20px;
  }
  .ls--bottom .ls--left {
    margin-bottom: 20px;
  }
  .al--block-alt .al__address {
    -webkit-line-clamp: 2;
  }
  .al--block-alt .al--adress-component {
    min-width: 34%;
    max-width: 34%;
  }
  .al--block-md .al--listings-component {
    min-width: 36%;
    max-width: 36%;
  }
  .al--block-alt .al--btn-component {
    min-width: 12%;
    max-width: 12%;
  }
  .photos--listings {
    margin-left: -32px;
    margin-right: -32px;
  }
  .media--video-block .media--video {
    width: 220px;
  }
}
@media screen and (min-width: 1280px) {
  .team--main-sbl {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (min-width: 1440px) {
  .container--expand {
    max-width: 1270px;
  }
  .fluid--expand {
    max-width: 1440px;
  }
  .container--xs {
    max-width: 800px;
  }
  .md--canvas {
    max-width: 960px;
  }
  .vp__box {
    right: -11px;
    top: 132px;
  }
  .vp__box:after {
    height: 31px;
    left: 2px;
    bottom: -22px;
  }
  .vp__logo:before {
    height: 26px;
    right: 45px;
    top: -25px;
  }
  .vt--demand-content {
    min-height: 450px;
  }
  .vt--combine {
    margin: 50px 0;
  }
  .vt--combine .adminSettings--head {
    margin-bottom: 20px;
  }
  .broker--video-placement,
  .broker--video-placement video,
  .broker--video-placement iframe {
    height: 480px;
  }
  .dashboard__btn:not(:disabled),
  .dashboard__btn {
    font-size: 18px;
    padding: 12px 16px;
  }
  .al--block-alt .al--adress-component {
    min-width: 32%;
    max-width: 32%;
  }
  .al--block-alt .al--listings-component {
    min-width: 30%;
    max-width: 30%;
  }
  .al--block-alt .al--btn-component {
    min-width: 15%;
    max-width: 15%;
  }
  .tourPre--top .liveTour--placement,
  .tourPre--top .liveTour--gallery,
  .tourPre--top .liveTour--custom {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .photos--off {
    margin-left: -36px;
    margin-right: -36px;
  }
  .photos--off .photos__control-prev {
    margin-right: 22px;
  }
  .photos--off .photos__control-next {
    margin-left: 22px;
  }
  .media--video-block .media--video {
    width: 330px;
  }
  .media--video-block .media--detail {
    min-width: 190px;
  }
}
@media screen and (min-width: 1620px) {
  .container--expand {
    max-width: 1400px;
  }
  .fluid--expand {
    max-width: 1600px;
  }
  .container--xs {
    max-width: 960px;
  }
  .tour--col {
    min-width: 20%;
  }
  .liveTour--building-top {
    margin-top: 60px;
  }
  .liveTour--specs-main {
    margin-top: 44px;
  }
  .building--widget .bw__arrow {
    font-size: 11px;
    line-height: 12px;
  }
  .vt--container {
    max-width: 960px;
  }
  .listing__btn .para__sm-gray {
    font-size: 14px;
  }
  .time--dropdown .dropdown-toggle {
    font-size: 18px;
  }
  .time--dropdown .dropdown-menu {
    font-size: 16px;
  }
}
@media screen and (max-width: 1440px) {
  .fluid--expand {
    padding-left: 60px;
    padding-right: 60px;
  }
  .container--large,
  .vt--container {
    padding-left: 32px;
    padding-right: 32px;
  }
  .pdf__btn:not([href]), .pdf__btn {
    font-size: 15px;
    padding: 10px 12px;
  }
  .pdf__btn .pdf__btn-icon {
    font-size: 28px;
    margin-right: 12px;
    min-width: 24px;
  }
  .block--search-row .photos--box {
    height: 160px;
  }
  .photos--listings .photos__control {
    top: 70px;
  }
}
@media screen and (max-width: 1300px) {
  .vp__logo {
    left: -20px;
  }
  .vp__logo:before {
    height: 22px;
    left: 80px;
    right: inherit;
    top: -21px;
  }
  .vp__box:after {
    height: 28px;
    left: 0;
    bottom: -18px;
  }
}
@media screen and (max-width: 1280px) {
  .tourEdit--top {
    height: 380px;
  }
  .video--canvas-expand {
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media screen and (max-width: 1200px) {
  .form--banner {
    width: 400px;
    min-width: 400px;
  }
  .form--content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .modal--listing .modal-dialog {
    max-width: 100%;
  }
  .al--block {
    flex-direction: column;
  }
  .al--block .al--block-img {
    width: 100%;
    height: auto;
  }
  .al--block .al--block-detail {
    margin-left: 0;
  }
  .landing__heading {
    font-size: 52px;
  }
  .navbar__list {
    margin-right: 28px;
  }
  .navbar-light .navbar__list-n .nav-link,
  .navbar__list-n .nav-link {
    margin-left: 14px;
    margin-right: 14px;
  }
  .tour--col {
    min-width: 34%;
  }
  .broker--video {
    margin-bottom: 48px;
  }
  .ls--top,
  .ls--bottom {
    flex-direction: column;
    align-items: flex-start;
    min-height: 10px;
  }
  .ls--top {
    min-height: 90px;
    padding-right: 0;
    margin-top: 20px;
  }
  .liveTour--listing-search .ls--top {
    min-height: 10px;
  }
  .ls--sort {
    margin-top: 20px;
    margin-bottom: 16px;
  }
  .ls--opt {
    width: 100%;
    margin-bottom: 20px;
  }
  .ls--top .ls__cross {
    top: 6px;
  }
  .liveTour--listing {
    overflow-y: auto;
  }
  .liveTour--listing::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .search__block {
    margin-bottom: 32px;
  }
  .white--rounded-box {
    padding: 24px;
  }
  .dashboard--card {
    padding-left: 24px;
    padding-right: 24px;
  }
  .al--sm-alt .al--block-img,
  .al--lg-alt .al--block-img,
  .al--block-alt .al--block-img {
    margin-bottom: 20px;
    height: 400px;
    min-width: 10px;
  }
  .dashboard__btn {
    margin-top: 20px;
  }
  .upcomingTour--block .upcomingTour--left {
    min-width: 160px;
    max-width: 160px;
  }
  .al--sm-alt .al--block-detail,
  .al--lg-alt .al--block-detail,
  .al--block-alt .al--block-detail {
    padding-left: 0;
  }
  .subDashboard--bar {
    flex-direction: column;
    align-items: flex-start;
  }
  .subDashboard--bar .subDashboard--top-btns {
    width: 100%;
  }
  .psf--search-row .block--search-col {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .photos--search-row .block--search-col {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .photos--listing-row .block--search-col,
  .photos--row-lg .block--search-col {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media screen and (max-width: 991px) {
  .container--expand,
  .fluid--expand,
  .navbar-expand-lg>.fluid--expand {
    padding-left: 32px;
    padding-right: 32px;
  }
  .container--large,
  .container--expand {
    max-width: 100%;
  }
  .navbar__list {
    padding: 15px;
  }
  .navbar--btns {
    padding: 0px 15px 15px 15px;
  }
  .navbar__header .navbar-collapse {
    background-color: #28313c;
    margin-top: 14px;
    box-shadow: 2px 0px 11px rgba(0,0,0,0.15);
  }
  .nav__signin-button {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar__header .outline__button {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
  .lists__heading {
    font-size: 26px;
  }
  .lists__subHeading {
    font-size: 22px;
  }
  .lists-info li {
    font-size: 20px;
  }
  .fields__icon {
    right: 20px;
    top: 13px;
    font-size: 22px;
  }
  .fields--group {
    margin-bottom: 16px;
  }
  .tour--status {
    font-size: 16px;
    right: 15px;
    top: 15px;
  }
  .heading__landing {
    font-size: 42px;
  }
  .heading__component,
  .form__heading,
  .dashboard__heading {
    font-size: 26px;
  }
  .steps__paragraph {
    font-size: 16px;
  }
  .about--block .about--detail {
    margin-bottom: 40px;
    text-align: center;
  }
  .about--block .about--img {
    text-align: center;
  }
  .about__heading {
    margin-bottom: 16px;
  }
  .footer {
    margin-top: 40px;
  }
  .company__about {
    font-size: 16px;
    line-height: 28px;
  }
  .listing--map {
    height: auto;
    min-height: 320px;
  }
  .form--page {
    flex-direction: column-reverse;
  }
  .form--banner {
    display: none;
  }
  .app__button-sm {
    min-height: 48px;
  }
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    margin-left: -70px;
  }
  .sidebar .sidebar--canvas {
    min-width: 70px;
    width: 70px;
  }
  .sidebar.sidebar--hide {
    margin-left: 0;
    margin-right: 0;
  }
  .sidebar__toggle {
    display: none;
  }
  .dashboard--select-n {
    width: 100%;
  }
  .broker__table {
    min-width: 880px;
  }
  .container--xs {
    max-width: 100%;
  }
  .listing--schedule {
    flex-direction: column;
  }
  .listing--schedule-col {
    padding-left: 0;
    padding-right: 0;
  }
  .hours__change {
    margin: 10px 0;
  }
  .al--block .al--block-detail {
    flex-direction: column;
  }
  .al--listing-box .al--listing,
  .al--listing-box .al--listing:last-child {
    margin-bottom: 16px;
  }
  .customer--block {
    margin: 0px 0px 16px 0px;
  }
  .buildings--block .bb-sf {
    width: 100%;
    justify-content: space-between;
    margin-top: 6px;
  }
  .dashboard--opt {
    min-height: 44px;
  }
  .heading--group .heading__edit {
    margin-top: 4px;
  }
  .vb--positioning {
    margin-right: 0;
  }
  .hours__del {
    margin: 20px 0px 20px auto;
  }
  .opinion__para {
    font-size: 20px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .opinion__para:before {
    background-size: 18px;
    left: 0;
    top: -10px;
  }
  .opinion__para:after {
    background-size: 18px;
    right: 0;
    bottom: -14px;
  }
  .landing__header-n .navbar-collapse {
    background-color: #15235c;
  }
  .navbar-light .navbar__list-n .nav-link,
  .navbar__list-n .nav-link, .navSignin__btn {
    color: #fff;
  }
  .landing__header-n .schedule__btn-outline {
    border-color: #fff;
    color: #fff;
  }
  .landing__heading {
    font-size: 42px;
  }
  .landing__para {
    font-size: 18px;
  }
  .info--block {
    margin-bottom: 48px;
  }
  .info--content .row .col-12:last-child .info--block {
    margin-bottom: 0;
  }
  .info--component {
    padding-top: 140px;
  }
  .navbar-light .navbar__list-n .nav-link,
  .navbar__list-n .nav-link {
    margin-left: 0;
    margin-right: 0;
  }
  .tour--col {
    min-width: 43%;
  }
  .vp__box:after,
  .vp__logo:before,
  .vp__secure:after {
    content: none;
  }
  .approve--video {
    margin-bottom: 32px;
  }
  .vt--combine .adminSettings--head .adminSettings__icon {
    font-size: 20px;
  }
  .vt--combine .adminSettings--head .adminSettings__text {
    font-size: 24px;
    line-height: 30px;
  }
  .share--options {
    display: none;
  }
  .buildings--block .bb--inner {
    width: auto;
  }
  .available--listing-map {
    height: 400px;
    overflow: hidden;
    margin-top: 32px;
  }
  .al--listing-combine {
    justify-content: space-between;
  }
  .ls--box .ls--box-detail {
    max-width: 260px;
    min-width: 260px;
  }
  .dashbaord--opt {
    margin-top: 16px;
  }
  .al--block-alt .al--block-detail {
    padding-left: 0;
  }
  .al--block-alt .buildings__next {
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
  }
  .upcomingTour--block {
    flex-direction: column;
  }
  .upComTour--btn-canvas {
    width: 100%;
  }
  .upComTour__list {
    padding-right: 0;
  }
  .upcomingTour--block .upcomingTour--main {
    width: 100%;
    margin-bottom: 24px;
  }
  .al--block-alt .al--btn-component {
    flex-direction: row;
  }
  .building__btn:not([href]),
  .building__btn {
    margin-left: 6px;
    margin-right: 6px;
  }
  .sidebar .sidebar--detail {
    display: none;
  }
  .sidebar--user-canvas {
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar--hide .sidebar--user-canvas {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sidebar--popover {
    left: -8px !important;
    margin-top: -20px !important;
  }
  .tabBuilding--nav .nav-link .tb__link-text,
  .tel__btn .tel__btn-text {
    display: none;
  }
  .tel__btn:not([href]),
  .tel__btn {
    min-width: 52px;
  }
  .tel--btns-row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .tel--btns-row .tel--btns-col {
    padding-left: 5px;
    padding-right: 5px;
  }
  .tourPre--top .liveTour--gallery-row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .tourPre--top .liveTour--gallery-row .liveTour--gallery-col {
    padding-left: 5px;
    padding-right: 5px;
  }
  .tourPre--top .liveTour--gallery-img {
    margin-bottom: 10px;
  }
  .map--block,
  .sdl--combine {
    padding: 60px 0px;
  }
  .video--canvas-expand {
    height: calc(100vh - 20px);
    margin: 10px;
  }
  .video--box-expand {
    max-height: 500px;
  }
  .video--modal .cross__circle-modal {
    line-height: 20px;
    min-width: 28px;
    height: 28px;
    background-color: #fff;
    border-radius: 50%;
    color: #6A7074;
    position: absolute;
    top: 15px;
    right: 15px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  .listing--btns-top .listing__btn {
    margin-bottom: 20px;
  }
  .listing--btns-top .row .col-12:last-child .listing__btn {
    margin-bottom: 0;
  }
  .lsBlock--btns-single .link__btn {
    margin-left: auto;
  }
  .navbar-light .navbar__list-n .nav-link:hover,
  .navbar__list-n .nav-link:hover,
  .navbar-light .navbar__list-n .nav-link.active,
  .navbar__list-n .nav-link.active,
  .navSignin__btn:not(:disabled):not(.disabled).active,
  .navSignin__btn:not(:disabled):not(.disabled):active,
  .navSignin__btn:hover,
  .navSignin__btn.active {
    color: #EAEDF4;
  }
}
@media screen and (max-width: 767px) {
  .vt--container,
  .container--expand,
  .container--large,
  .fluid--expand,
  .navbar-expand-lg>.fluid--expand {
    padding-left: 20px;
    padding-right: 20px;
  }
  .schedule__button {
    margin-top: 40px;
  }
  .social__list {
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .social__list li a {
    margin-left: 10px;
    margin-right: 10px;
  }
  .gallery--box {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .schedule--content {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .landing--video iframe {
    padding: 9.5% 13.5% 16.8% 14%;
  }
  .chat__app {
    bottom: 15px;
    right: 15px;
  }
  .dashboard--head,
  .listing--head {
    flex-direction: column;
    align-items: flex-start;
  }
  .dashboard__heading,
  .heading--group {
    margin-bottom: 16px;
  }
  .editListing--head-btn {
    width: 100%;
  }
  .editListing--head-btn .outline__button {
    width: 100%;
    margin: 0px 5px 0px 0px;
  }
  .editListing--head-btn .admin__button {
    width: 100%;
    margin: 0px 0px 0px 5px;
  }
  .steps--content,
  .about--content {
    margin-top: 70px;
  }
  .empty--box {
    margin-top: 80px;
  }
  .f__list {
    justify-content: center;
  }
  .f__list li a {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .vp__box {
    right: -10px;
    top: 78px;
    width: 52px;
    height: 52px;
    min-width: 52px;
  }
  .vp__box:before {
    width: 36px;
    height: 36px;
  }
  .vp__secure {
    font-size: 18px;
    padding: 4px 20px;
    top: -58px;
    left: 50px;
  }
  .vp__secure .vp__secure-icon {
    font-size: 18px;
    line-height: 18px;
  }
  .vp__logo {
    left: -10px;
    width: 154px;
    height: 48px;
  }
  .vp__logo img {
    width: 126px;
    height: 33px;
  }
  .tour--row {
    justify-content: flex-start;
  }
  .tour--col {
    min-width: 100%;
  }
  .broker--video-placement,
  .broker--video-placement video,
  .broker--video-placement iframe {
    height: 300px;
  }
  .tenant--video-placement,
  .tenant--video-placement video,
  .tenant--video-placement iframe {
    height: 420px;
  }
  .tenant--video {
    margin-top: -210px;
    margin-bottom: 175px;
  }
  .vt--combine-right {
    padding-top: 40px;
  }
  .suite--box {
    justify-content: space-between;
    padding: 12px 15px;
  }
  .team--main .row .col-12:last-child .team--block {
    margin-bottom: 0;
  }
  .vt--options:after {
    right: auto;
    left: auto;
    top: initial;
    bottom: -18px;
    width: 200px;
    background-size: 200px;
    height: 74px;
  }
  .liveTour--options {
    justify-content: center;
    flex-wrap: wrap;
  }
  .ct--radio-btn {
    min-height: 130px;
  }
  .ct--radio-alt {
    min-height: 10px;
  }
  .ct--radio-btn .checkmark {
    margin-right: 14px;
  }
  .render--component .ct--radio-btn .checkmark {
    margin-right: 5px;
  }
  .component--edit-overlay {
    padding-left: 30px;
  }
  .component--edit .componentEdit--head {
    padding: 20px 24px 10px 24px;
  }
  .component--edit .componentEdit--scrollable {
    padding: 20px 24px 20px 24px;
  }
  .componentEdit--scrollable {
    overflow: initial;
  }
  .search--bs {
    flex-direction: column;
  }
  .search--bs .search--bs-img {
    margin: 0px auto 20px auto;
    height: 80px;
  }
  .search--bs .search--bs-detail {
    flex-direction: column;
  }
  .search--bs .search--bs-inner {
    align-items: center;
    margin-bottom: 20px;
  }
  .search--bs .bs__next {
    margin-left: auto;
  }
  .search--bs-alt .search--bs-inner {
    width: 100%;
    flex-direction: column;
  }
  .search--bs-alt .bs__heading {
    margin: 0px 0px 20px 0px;
  }
  .photos--box {
    width: 120px;
    height: 120px;
    min-width: 120px;
  }
  .video--box,
  .video--iframe {
    width: 180px;
    min-width: 180px;
    height: 130px;
  }
  .psf--search-row .block--search-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .search--group-lg .form-control {
    height: calc(2.8em + .75rem + 2px);
    font-size: 15px;
    line-height: 20px;
    padding: 10px 16px 10px 40px;
  }
  .search--group-lg .search__icon {
    font-size: 16px;
    left: 15px;
    top: 22px;
  }
  .search--group-lg .search__icon-cross {
    font-size: 12px;
    right: 15px;
    top: 23px;
  }
  .al--sm-alt .al--block-img,
  .al--lg-alt .al--block-img,
  .al--block-alt .al--block-img {
    height: 300px;
  }
  .team--block-alt {
    flex-direction: column;
  }
  .team--block-alt .teamBlock--img {
    margin: 0px 0px 20px 0px;
  }
  .team--block-alt .teamBlock__user {
    margin-bottom: 6px;
  }
  .buildings--map,
  .subListing--video,
  .map--box {
    height: 320px;
  }
  .map--block,
  .sdl--combine {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .photos--off {
    margin-top: 80px;
    margin-bottom: 60px;
  }
  .viewBy {
    margin-top: 16px;
    margin-bottom: 10px;
  }
  .video--box-expand {
    max-height: 320px;
  }
  .video--box-expand {
    max-height: 320px;
  }
  .videos--search-row .block--search-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .photos--search-row .block--search-col {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .photos--listing-row .block--search-col,
  .photos--row-lg .block--search-col {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .media--video-block {
    flex-direction: column;
  }
  .media--video-block .media--video {
    width: 100%;
    height: 320px;
    margin: 0px 0px 20px 0px;
  }
  .mediaContent--combine {
    margin-bottom: 10px;
  }
  .mediaContent--combine .mc--box {
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 575px) {
  .navbar--btns {
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar--btns .app__button {
    width: 100%;
    margin-top: 2px;
  }
  .office--component {
    margin-top: 50px;
  }
  .detail--text {
    margin-top: 36px;
  }
  .gradient--component {
    padding-top: 120px;
  }
  .heading__landing {
    font-size: 32px;
  }
  .heading--group .form-control,
  .heading__component,
  .form__heading,
  .dashboard__heading {
    font-size: 24px;
    line-height: 34px;
  }
  .heading__medium {
    font-size: 22px;
    line-height: inherit;
  }
  .lists__heading {
    font-size: 24px;
  }
  .lists__subHeading {
    font-size: 20px;
  }
  .lists-info li {
    font-size: 18px;
  }
  .form--option {
    flex-direction: column;
    align-items: flex-start;
  }
  .form--opt-r {
    width: 100%;
    justify-content: space-between;
    margin-top: 24px;
  }
  .form__link {
    margin-left: auto;
  }
  .signin--canvas {
    max-width: 100%;
  }
  .signin--btn-canvas,
  .btn-top {
    margin-top: 40px;
  }
  .btn-top {
    width: 100%;
  }
  .forms--btn-canvas .app__button,
  .forms--btn-canvas .admin__button,
  .adminSettings-btn .outline__button {
    width: 100%;
  }
  .form--content {
    padding-top: 40px;
    padding-bottom: 48px;
  }
  .signup--inner {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .stepBtn-canvas-1 {
    padding-top: 0px;
    margin-top: 24px;
  }
  .stepBtn-canvas .admin__button,
  .al--btn-component .admin__button,
  .annex--card-btn,
  .modal--btn-canvas .admin__button {
    width: 100%;
  }
  .al--btn-component {
    margin-top: 10px;
  }
  .al--block .al--block-img {
    width: 100%;
    height: auto;
    box-shadow: none;
  }
  .al__heading-medium {
    font-size: 20px;
  }
  .annex--card {
    flex-direction: column;
    align-items: flex-start;
  }
  .annex--card {
    padding-left: 20px;
    padding-right: 20px;
  }
  .listing--video,
  .virtualTour--video {
    height: 320px;
  }
  .listing--video video,
  .virtualTour--video video {
    height: 320px;
  }
  .listing__button {
    margin-top: 10px;
  }
  .bl--head-btn {
    width: 100%;
  }
  .b--sf-heading {
    font-size: 32px;
    line-height: 30px;
  }
  .al--block .al--block-detail {
    padding: 15px;
    margin-top: -1px;
  }
  .heading--group .heading__edit {
    margin-top: 6px;
  }
  .virtual--carousel .carousel-item {
    height: 400px;
  }
  .notfound__heading {
    font-size: 136px;
    line-height: 124px;
  }
  .nav__signin-button {
    margin-bottom: 10px;
  }
  .empty--box .admin__button {
    width: 100%;
  }
  .office-modal .modal-header,
  .office-modal .modal-body {
    padding-left: 28px;
    padding-right: 28px;
  }
  .personalize--block {
    padding: 32px 20px 20px 20px;
  }
  .landing__header-n .schedule__btn-outline {
    width: 100%;
  }
  .landing__header-n .schedule__btn-outline:hover,
  .landing__header-n .schedule__btn-outline:focus,
  .landing__header-n .schedule__btn-outline:active {
    border-color: #fff;
  }
  .broker--video-placement,
  .broker--video-placement video,
  .broker--video-placement iframe {
    height: 250px;
  }
  .tenant--video-placement,
  .tenant--video-placement video,
  .tenant--video-placement iframe {
    height: 320px;
  }
  .footer-n {
    padding-top: 240px;
  }
  .vp__secure {
    font-size: 10px;
    padding: 3px 12px;
    top: -42px;
    left: 37px;
  }
  .vp__secure .vp__secure-icon {
    font-size: 10px;
    line-height: 12px;
  }
  .personalize--block-about {
    margin-bottom: 112px;
  }
  .tenant--video {
    margin-top: -160px;
    margin-bottom: 112px;
  }
  .vp__box {
    width: 30px;
    height: 30px;
    min-width: 30px;
    top: 54px;
    right: -5px;
  }
  .vp__box:before {
    width: 20px;
    height: 20px;
  }
  .vp__logo {
    width: 106px;
    height: 36px;
    top: 16px;
  }
  .vp__logo img {
    width: 78px;
    height: 22px;
  }
  .vt--btns,
  .group-input {
    margin-bottom: 40px;
  }
  .group-input {
    flex-direction: column;
  }
  .group-input .outline__button {
    margin-left: 0;
    margin-top: 16px;
    width: 100%;
  }
  .vt--combine .dashboard__heading {
    padding-bottom: 20px;
  }
  .recording__heading {
    font-size: 24px;
  }
  .recording__timer {
    font-size: 100px;
    line-height: 100px;
  }
  .voiceRecord--block .voiceRecord--box:nth-child(odd) {
    justify-content: flex-start;
    padding-right: 10px;
  }
  .voiceRecord--block .voiceRecord--box:nth-child(even) {
    justify-content: flex-end;
    padding-left: 10px;
    padding-right: 0;
  }
  .voiceRecord--box {
    width: 50%;
  }
  .record__voice {
    width: 42px;
    height: 42px;
    min-width: 42px;
  }
  .modal--video-set {
    height: 250px !important;
  }
  .mv__cross {
    right: 4px;
  }
  .c--post-canvas .admin__button,
  .ca--cover,
  .ca__btn-canvas {
    width: 100%;
  }
  .liveTour--top-custom {
    height: auto;
  }
  .users--content .row {
    flex-direction: column-reverse;
    align-items: center;
  }
  .users--row-right {
    border-bottom: 1px solid rgba(213,217,224,0.3);
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .liveTour--options {
    margin: 0;
  }
  .liveTour--options li a {
    margin-bottom: 16px;
  }
  .tour--btn-canvas {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .tourMobile--block {
    padding: 24px 0;
  }
  .users--content-tenant .users--row-left {
    padding-top: 0px;
  }
  .notfound__img {
    width: 268px;
    height: 189px;
    margin-bottom: 40px;
  }
  .available--listing-map {
    height: 250px;
    margin-top: 20px;
  }
  .search__block .ls__search-icon {
    display: none;
  }
  .listing--group .form-control {
    font-size: 13px;
    padding: 6px 56px 6px 10px;
    height: calc(1.7em + .75rem + 2px);
    min-height: 40px;
  }
  .listing--group .enter__btn {
    font-size: 10px;
    padding: 3px 6px;
    min-width: 44px;
    min-height: 20px;
    top: 10px;
  }
  .cancel__link {
    font-size: 12px;
  }
  .liveTour--listing .container-fluid {
    justify-content: start;
  }
  .ls--sort {
    flex-direction: column;
    align-items: flex-start;
  }
  .ls__label {
    margin: 10px 0px 12px 0px;
  }
  .ls__list {
    width: 100%;
  }
  .ls--listings::-webkit-scrollbar {
    height: 14px;
    width: 10px;
  }
  .ls__search-icon {
    margin-right: 10px;
    font-size: 24px;
  }
  .sec--vertical .sec--vertical-middle .heading__medium {
    margin-bottom: 18px;
  }
  .dashbaord--opt {
    flex-direction: column;
  }
  .dashbaord--opt .admin__button {
    margin-left: 0;
    width: 100%;
    margin-top: 16px;
  }
  .white--rounded-box,
  .dashboard--card {
    padding-left: 16px;
    padding-right: 16px;
  }
  .upcomingTour--block .upcomingTour--main {
    flex-direction: column;
    margin-bottom: 16px;
  }
  .upcomingTour--block .upcomingTour--left {
    padding-right: 0;
    max-width: 100%;
    width: 100%;
    margin-right: 0;
    border-right: 0;
  }
  .upcomingTour--block .upcomingTour--right {
    border-left: 0;
    padding-left: 0;
    width: 100%;
    margin-top: 8px;
  }
  .dashboard--card .card-header {
    padding: 5px 0px;
  }
  .dashboard--card .card-header .btn-link {
    font-size: 20px;
  }
  .al--sm-alt .al--block-detail,
  .al--lg-alt .al--block-detail,
  .al--block-alt .al--block-detail {
    padding: 0;
  }
  .ct__btn-canvas {
    padding-top: 0px;
  }
  .tour__btn-canvas .btn,
  .btns-mobile-expand .btn {
    width: 100%;
  }
  .tourListing--block {
    flex-direction: column;
    padding: 16px;
  }
  .tourListing--block .tourListing--row {
    flex-direction: column;
    align-items: center;
    padding: 0;
    width: 100%;
  }
  .tourListing--block .tourListing--row .para__md-dark {
    margin-right: initial;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
  }
  .dashboard__btn:not(:disabled),
  .dashboard__btn {
    min-height: 60px;
  }
  .subDashboard--bar .heading__medium {
    width: 100%;
  }
  .tourEdit--top {
    height: 250px;
  }
  .tourEdit--top-gallery .liveTour--gallery-img {
    height: 58px;
  }
  .upload--gallery-img {
    font-size: 9px;
  }
  .upload--gallery-img i {
    font-size: 10px;
    margin-bottom: 6px;
  }
  .tourEdit__btn-canvas .btn {
    width: 100%;
  }
  .component--edit .form--group-alt .form-control {
    font-size: 12px;
  }
  .component--edit .search--group-alt .form-control {
    padding-left: 38px;
  }
  .component--edit .search--group-alt .search__icon {
    font-size: 14px;
    top: 18px;
  }
  .psf--search-row .block--search-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .al--sm-alt .al--block-img,
  .al--lg-alt .al--block-img,
  .al--block-alt .al--block-img {
    height: 180px;
  }
  .dashboard__heading-alt {
    font-size: 26px;
  }
  .subdomain__footer {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 34px;
  }
  .subdomain__footer .sd__footer-list {
    justify-content: center;
  }
  .subdomain__footer .sd__footer-l {
    align-items: center;
  }
  .photos--box-lg {
    width: 100%;
    min-width: 10px;
  }
  .listingModal--request {
    min-height: 350px;
  }
  .tour--btn-nowrap {
    flex-wrap: nowrap;
  }
  .photos--search-row .block--search-col {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .photos--listing-row .block--search-col,
  .photos--row-lg .block--search-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .lsBlock--btns .link__btn {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .key--details .row .col-6,
  .listings .row .col-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .gallery--box {
    height: auto;
  }
  .landing--video iframe {
    padding: 10.5% 13.5% 17.8% 14%;
  }
  .listing--schedule-col {
    flex-direction: column;
  }
  .listing--schedule-col .select--group {
    margin: 0px 0px 16px 0px;
    width: 100%;
  }
  .hours__change {
    margin: 6px 0px 16px 0px;
  }
  .week--time {
    padding-right: 0;
  }
  .hours--time {
    width: 100%;
  }
  .editListing--head-btn {
    flex-direction: column;
  }
  .editListing--head-btn .sm__button {
    margin: 0px 0px 20px 0px;
  }
  .virtual--carousel .carousel-item {
    height: 300px;
  }
  .hours__del {
    margin-top: 0;
  }
  .broker--video-placement,
  .broker--video-placement video,
  .broker--video-placement iframe {
    height: 180px;
  }
  .media--video-block .media--video,
  .buildings--map,
  .map--box,
  .subListing--video,
  .tenant--video-placement,
  .tenant--video-placement video,
  .tenant--video-placement iframe {
    height: 220px;
  }
  .tenant--video {
    margin-top: -110px;
  }
  .vp__secure {
    top: -34px;
    left: 32px;
  }
  .form-label {
    font-size: 11px;
  }
  .modal--video-set {
    height: 200px !important;
  }
  .drawer--right {
    width: 90%;
    padding-left: 40px;
    padding-right: 40px;
    margin-right: -100%;
  }
  .drawer--right-show {
    margin-right: 0;
  }
  .photos--search-row .block--search-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .photos--listing-row .block--search-col,
  .photos--row-lg .block--search-col,
  .videos--search-row .block--search-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .video--box,
  .video--iframe {
    width: 100%;
    min-width: 10px;
  }
}

/*
  Media Queries
 */

