
.bb--alt-shimmer .al--block-img,
.alb--alt-shimmer .al--block-img {
  background-color: #F5F5F5;
  border-radius: 10px;
}
.bb--alt-shimmer .al__address,
.alb--alt-shimmer .al__address {
  width: 100%;
  max-width: 230px;
  height: 24px;
  background-color: #F5F5F5;
  border-radius: 4px;
  margin-bottom: 8px;
}
.bb--alt-shimmer .al__address:hover,
.alb--alt-shimmer .al__address:hover {
  cursor: initial;
}
.al--listing-box-sh .al--listing .al__icon {
  width: 20px;
  height: 20px;
  min-width: 20px;
  background-color: #F5F5F5;
  border-radius: 4px;
}
.al--listing-box-sh .al--listing .al__text {
  width: 120px;
  height: 20px;
  background-color: #F5F5F5;
  border-radius: 4px;
  margin-right: 0;
}
.alb--alt-shimmer .buildingBtn__shimmer {
  margin: 5px 0;
}
.badge--off {
  display: inline-flex;
  align-items: center;
}
.buildingBtn__shimmer {
  width: 90px;
  min-width: 90px;
  height: 24px;
  background-color: #D2D2D2;
  border-radius: 12px;
  display: inline-flex;
}
.alb--alt-shimmer .buildings__next {
  color: #F5F5F5;
}
.bb--alt-shimmer,
.alb--alt-shimmer,
.upcomingTour--shimmer {
  cursor: initial;
}
.bb--alt-shimmer:hover,
.alb--alt-shimmer:hover,
.upcomingTour--shimmer:hover {
  border-color: #BCBAB8;
}
.upcomingTour--shimmer .upComTour__icon {
  background-color: #EDEDED;
  border-radius: 4px;
  height: 24px;
  width: 28px;
  margin-bottom: 4px;
}
.upcomingTour--shimmer .upComTour__name {
  background-color: #EDEDED;
  border-radius: 4px;
  width: 126px;
  height: 18px;
  margin-top: 8px;
}
.upcomingTour--shimmer .upComTour__list li .upComTour__list-icon {
  background-color: #EDEDED;
  border-radius: 4px;
  width: 18px;
  height: 18px;
}
.upcomingTour--shimmer .upComTour__list li .upComTour__list-text {
  background-color: #EDEDED;
  border-radius: 4px;
  height: 18px;
}
.upcomingTour--shimmer .upComTour__list li:nth-of-type(1) .upComTour__list-text {
  width: 80%;
}
.upcomingTour--shimmer .upComTour__list li:nth-of-type(2) .upComTour__list-text {
  width: 60%;
}
.upcomingTour--shimmer .upComTour__list li:nth-of-type(3) .upComTour__list-text {
  width: 50%;
}
.upcomingTour--shimmer .btn__span {
  background-color: #EDEDED;
  border-radius: 10px;
  height: 32px;
  width: 100%;
  min-width: 200px;
  display: inline-flex;
}
.tl--shimmer .tourListing__img,
.tl--plus-shimmer .tourListing__img {
  background-color: #F5F5F5;
  border-radius: 10px;
}
.tl--shimmer .tourListing--row .para__md-dark,
.tl--plus-shimmer .tourListing--row .para__md-dark,
.tl--alt-shimmer .tourListing--row .para__md-dark {
  background-color: #EDEDED;
  border-radius: 4px;
  height: 20px;
  width: 80%;
}
.tl--plus-shimmer .plus__shimmer,
.tl--alt-shimmer .plus__shimmer {
  color: #fff;
  font-size: 12px;
  width: 32px;
  height: 32px;
  min-width: 32px;
  background-color: #EDEDED;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.tl--alt-shimmer .tourListing__img {
  background-color: #EDEDED;
}
.addBtn__shimmer {
  padding: 5px;
  border-radius: 10px;
  min-width: 94px;
  min-height: 32px;
  display: inline-flex;
  background-color: #EDEDED;
}
.breadcrumb--shimmer {
  margin-bottom: 10px;
  display: flex;
}
.breadcrumb--shimmer .breadcrumbShimmer__span {
  background-color: #EDEDED;
  border-radius: 4px;
  height: 16px;
  display: inline-flex;
  margin-right: 8px;
}
.breadcrumb--shimmer .breadcrumbShimmer__span:first-child {
  width: 40px;
}
.breadcrumb--shimmer .breadcrumbShimmer__span:last-child {
  width: 158px;
}
.dashboard--bar-shimmer {
  min-height: 107px;
}
.dashboard--bar-shimmer .db--preview {
  background-color: #EDEDED;
  border-radius: 4px;
  height: 20px;
  width: 100%;
  max-width: 300px;
  margin-top: 10px;
  margin-bottom: 8px;
  display: flex;
}
.db__btns {
  width: 188px;
  height: 48px;
  background-color: #EDEDED;
  border-radius: 10px;
  display: inline-flex;
}
.tourEditTop--shimmer {
  background-color: #F5F5F5;
  border-radius: 15px;
}
.placement--shimmer-alt {
  background-color: #EBEBEB;
  border-radius: 15px;
  min-height: 327px;
}
.dropdown--shimmer {
  border: 1px solid #BCBAB8;
  border-radius: 10px;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 14px;
}
.dropdown--shimmer .dropdownShimmer__icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
  background-color: #EDEDED;
  border-radius: 4px;
  margin-right: 12px;
  display: inline-flex;
}
.dropdown--shimmer .dropdownShimmer__text {
  background-color: #EDEDED;
  border-radius: 4px;
  height: 18px;
  width: 100%;
  max-width: 200px;
  display: inline-flex;
  margin-right: auto;
}
.dropdown--shimmer .dropdownShimmer__arrow {
  display: inline-flex;
  color: #222222;
  font-size: 10px;
  line-height: 10px;
  margin-right: 2px;
  margin-left: 8px;
}
.lt__shimmer {
  display: inline-flex;
  min-width: 74px;
  height: 20px;
  background-color: #EDEDED;
  margin-left: 12px;
  border-radius: 4px;
}
.tourEdit--listing-shimmer .tel--top .telTop__icon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  margin-right: 8px;
  display: inline-flex;
  background-color: #EDEDED;
  border-radius: 4px;
}
.tourEdit--listing-shimmer .tel--top .telTop__text {
  height: 18px;
  min-width: 18px;
  display: inline-flex;
  background-color: #EDEDED;
  border-radius: 4px;
  width: 100%;
  max-width: 270px;
}
.telBtn__shimmer {
  min-height: 48px;
  min-width: 76px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #BCBAB8;
  border-radius: 10px;
  padding: .375rem .65rem;
}
.telBtn__shimmer .telBtn__shimmer-icon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  background-color: #EDEDED;
  border-radius: 4px;
}
.telBtn__shimmer .telBtn__shimmer-text {
  height: 18px;
  min-width: 50px;
  background-color: #EDEDED;
  border-radius: 4px;
  flex: 1;
}
.teamBlock--shimmer .teamBlock--img {
  background-color: #EDEDED;
}
.teamBlock--shimmer .teamBlock__user {
  height: 16px;
  width: 110px;
  background-color: #EDEDED;
  border-radius: 4px;
  display: inline-flex;
  margin-bottom: 6px;
}
.teamBlock--shimmer .teamBlock__email {
  height: 16px;
  width: 150px;
  background-color: #EDEDED;
  border-radius: 4px;
  display: inline-flex;
}
.search--shimmer-lg {
  display: flex;
  align-items: center;
  min-height: 70px;
  padding: 12px 24px;
  background-color: #F5F5F5;
  border-radius: 10px;
}
.search--shimmer-lg .search__icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-color: #EDEDED;
  border-radius: 4px;
  margin-right: 16px;
  display: inline-flex;
}
.search--shimmer-lg .search__text {
  width: 63%;
  height: 24px;
  background-color: #EDEDED;
  border-radius: 4px;
  display: inline-flex;
}
.searchBs--shimmer {
  display: flex;
  border: 1px solid #BCBAB8;
  border-radius: 16px;
  padding: 14px 16px;
  margin-bottom: 20px;
  min-height: 94px;
}
.searchBs--shimmer .sb__shimmer-img {
  width: 224px;
  min-width: 224px;
  height: 64px;
  border-radius: 10px;
  display: flex;
  margin-right: 20px;
  background-color: #EDEDED;
}
.searchBs--shimmer .search--bs-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
}
.searchBs--shimmer .search--bs-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
}
.searchBs--shimmer .sb__shimmer-heading {
  height: 20px;
  width: 50%;
  background-color: #EDEDED;
  border-radius: 4px;
  margin-bottom: 12px;
}
.searchBs--shimmer .bs__next {
  color: #EDEDED;
  font-size: 16px;
  margin: 0px 8px 0px 16px;
}
.searchBs--shimmer-alt {
  min-height: 55px;
}
.searchBs--shimmer-alt .search--bs-inner {
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}
.searchBs--shimmer-alt .sb__shimmer-heading {
  margin-bottom: 0;
  margin-right: 30px;
  width: 100%;
  max-width: 232px;
}
.searchBs--shimmer-alt .buildingBtn__shimmer {
  margin-left: 8px;
  margin-right: 8px;
}
.photos--box-shimmer {
  width: 160px;
  height: 160px;
  min-width: 160px;
  display: flex;
  border-radius: 16px;
  position: relative;
  margin-bottom: 14px;
  background-color: #EDEDED;
}
.block--search-row .photos--box-shimmer {
  width: 100%;
  min-width: 10px;
  height: 180px;
}
.video--box-shimmer {
  width: 330px;
  min-width: 330px;
  height: 185px;
  border-radius: 16px;
  display: flex;
  position: relative;
  margin-bottom: 14px;
  background-color: #EDEDED;
}
.block--search-row .video--box-shimmer {
  width: 100%;
  min-width: 10px;
}
.pdf__btn-shimmer {
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  padding: 11px 16px;
  width: 100%;
  min-height: 72px;
  margin-bottom: 14px;
  background-color: #F6F6F6;
}
.pdf__btn-shimmer .pdf__btn-icon {
  min-width: 32px;
  height: 38px;
  display: inline-flex;
  margin-right: 18px;
  background-color: #EDEDED;
  border-radius: 4px;
}
.pdf__btn-shimmer .pdf__btn-text {
  width: 100%;
  height: 18px;
  display: inline-flex;
  background-color: #EDEDED;
  border-radius: 4px;
  margin-bottom: 8px;
}
.pdf__btn-shimmer .pdf__btn-off .pdf__btn-text:last-child {
  margin-bottom: 0;
}
.pdf__btn-shimmer .pdf__btn-off {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.sidebar--shimmer {
  background-color: #757879;
}
.dh--shimmer .dhShimmer__heading {
  background-color: #EDEDED;
  width: 100%;
  max-width: 200px;
  height: 28px;
  border-radius: 4px;
  display: inline-flex;
}
.dh--shimmer .dhShimmer__para {
  background-color: #EDEDED;
  width: 100%;
  max-width: 90%;
  height: 20px;
  border-radius: 4px;
  display: inline-flex;
}
.db--shimmer {
  background-color: #F5F5F5;
  min-height: 70px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 12px;
}
.db--shimmer .dbShimmer__text {
  height: 22px;
  background-color: #EDEDED;
  border-radius: 4px;
  width: 100%;
  max-width: 152px;
}
.db--shimmer .dbShimmer__icon {
  width: 22px;
  height: 22px;
  min-width: 22px;
  background-color: #EDEDED;
  border-radius: 4px;
  margin-left: 10px;
}
.search--shimmer {
  display: flex;
  align-items: center;
  min-height: 48px;
  padding: 10px 16px;
  background-color: #F5F5F5;
  border-radius: 10px;
  width: 100%;
}
.search--shimmer .searchShimmer__icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
  background-color: #EDEDED;
  border-radius: 4px;
  margin-right: 12px;
  display: inline-flex;
}
.search--shimmer .searchShimmer__text {
  width: 70%;
  height: 16px;
  background-color: #EDEDED;
  border-radius: 4px;
  display: inline-flex;
}
.button__shimmer {
  border: none;
  text-align: center;
  padding: 10px 20px;
  min-height: 48px;
  color: #fff;
  background-color: #F5F5F5;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.button__shimmer .button__shimmer-text {
  width: 100%;
  max-width: 100px;
  height: 16px;
  background-color: #EDEDED;
  border-radius: 4px;
}
.button__shimmer .button__shimmer-icon {
  width: 14px;
  min-width: 14px;
  height: 14px;
  background-color: #EDEDED;
  border-radius: 4px;
  margin-left: 8px;
}
.button__shimmer-lg {
  min-width: 200px;
}
.button__shimmer-sm {
  padding: 10px 16px;
  min-width: 188px;
}
.dashbaord--opt .button__shimmer-sm {
  margin-left: 20px;
}
.shimmer__style {
  background-color: #EDEDED;
  border-radius: 4px;
  display: inline-flex;
}
.header__logo-shimmer {
  width: 151px;
  height: 39px;
}
.header__bars-shimmer {
  width: 24px;
  height: 18px;
}
.viewBy--shimmer {
  min-height: 26px;
}
.viewBy--shimmer .viewBy__text {
  height: 18px;
  min-width: 61px;
}
.viewBy--shimmer .viewBy__marker {
  height: 22px;
  min-width: 15px;
}
.grid--shimmer .grid__view {
  width: 20px;
  height: 22px;
}
.shimmer__heading-al {
  width: 50%;
  height: 28px;
}
.alb--lg-shimmer:hover {
  cursor: initial;
  border-color: #BCBAB8;
}
.alb--lg-shimmer .al__address {
  width: 100%;
  max-width: 230px;
  height: 24px;
  margin-bottom: 12px;
}
.sdf__para-shimmer {
  width: 100%;
  max-width: 210px;
  height: 18px;
  margin-bottom: 16px;
}
.fl--shimmer {
  min-height: 24px;
}
.fl--shimmer .fl--shimmer-span {
  height: 18px;
  margin-right: 24px;
}
.fl--shimmer .fl--shimmer-span:last-child {
  margin-right: 0;
}
.fl--shimmer .fl--shimmer-span:nth-of-type(1) {
  min-width: 55px;
}
.fl--shimmer .fl--shimmer-span:nth-of-type(2) {
  min-width: 95px;
}
.fl--shimmer .fl--shimmer-span:nth-of-type(3) {
  min-width: 136px;
}
.fp__box {
  min-height: 88px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}
.fp__box .fp__box-span {
  height: 18px;
  width: 100%;
  max-width: 152px;
  margin-bottom: 4px;
}
.fp__box .fp__box-span:nth-of-type(2) {
  max-width: 235px;
}
.shimmer__back {
  display: inline-flex;
  align-items: center;
  min-height: 24px;
}
.shimmer__back .back__btn-icon {
  color: #EDEDED;
}
.shimmer__back .shimmer__back-text {
  height: 20px;
  width: 40px;
}
.shimmer__heading-lg {
  height: 40px;
  width: 100%;
  max-width: 50%;
}
.shimmer__heading-md {
  height: 24px;
  width: 100%;
  max-width: 25%;
}
.shimmer--keybox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: #F7F7F7;
  border-radius: 20px;
  margin-bottom: 20px;
  min-height: 118px;
}
.shimmer--keybox .keyBox--head {
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
}
.shimmer--keybox .keyBox--head .keyBoxHead__icon {
  height: 16px;
  min-width: 16px;
  margin-right: 10px;
}
.shimmer--keybox .keyBox--head .keyBoxHead__text {
  height: 16px;
  width: 100%;
  max-width: 104px;
}
.shimmer--keybox .shimmer__heading-md {
  height: 18px;
  max-width: 90px;
  margin-bottom: 10px;
}
.shimmer--keybox .small__paragraph {
  height: 16px;
  width: 100%;
  max-width: 90px;
}
.shimmer--keybox-expand .small__paragraph {
  max-width: 275px;
}
.shimmer--video {
  height: 467px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 20px;
  background-color: #F7F7F7;
}
.shimmer__play-btn {
  color: #fff;
  font-size: 28px;
  width: 60px;
  height: 60px;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.button__shimmer.button__shimmer-expand .button__shimmer-text {
  max-width: 300px;
}
.photos--lg-shimmer {
  width: 200px;
  height: 200px;
  min-width: 200px;
  display: flex;
  border-radius: 16px;
  margin-bottom: 14px;
}
.block--search-row .photos--lg-shimmer {
  width: 100%;
  min-width: 10px;
}
.shimmer--dc .para__md-gray {
  height: 20px;
  width: 100%;
  margin-bottom: 8px;
}
.shimmer--dc .dc--para-column .para__md-gray:last-child {
  margin-bottom: 0;
}
.map--block-shimmer .shimmer__heading-lg,
.sdl--combine .shimmer__heading-lg {
  max-width: 100%;
  width: initial;
  min-width: 224px;
}
.spaces--box-shimmer {
  min-height: 21px;
}
.spaces--box-shimmer .para__sm-gray {
  min-height: 16px;
  min-width: 112px;
}
.shimmer--floorplan .floorplan--img {
  height: 350px;
  width: 100%;
}
.map--block-shimmer .map--box {
  border-radius: 20px;
}
.specs--box-shimmer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  min-height: 104px;
  background-color: #F6F6F6;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;
}
.specs--box-shimmer .specsBox__head {
  min-height: 21px;
  width: 100%;
}
.specs--box-shimmer .specsBox__head .specsBox__head-icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
.specs--box-shimmer .specsBox__head .specsBox__head-text {
  width: 100%;
  max-width: 88px;
  height: 14px;
}
.specs--box-shimmer .para__sm-gray {
  height: 18px;
  width: 100%;
  max-width: 88px;
  margin-top: auto;
  margin-bottom: auto;
}
.specsBox--img-shimmer {
  flex-direction: row;
  justify-content: center;
}
.specs--box-shimmer .specsBox__head-img {
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.specs--box-shimmer .para__sm-gray {
  margin-bottom: 6px;
}
.specs--box-shimmer .para--column {
  min-width: 88px;
}
.specs--box-shimmer .para--column .para__sm-gray:last-child {
  margin-bottom: 0;
}
.specsBox--shimmer-alt {
  justify-content: center;
}
.specsBox--shimmer-alt .para__sm-gray {
  max-width: 150px;
}
.subdomain__footer-shimmer {
  background-color: #757879;
  min-height: 306px;
}
.shimmer__md .shimmer__md-text {
  height: 24px;
  width: 100%;
  max-width: 80%;
}
.shimmer__md .shimmer__md-link {
  height: 24px;
  min-width: 85px;
}
.do--btn-canvas {
  display: inline-flex;
}
.do--btn-canvas .db__btns {
  margin-left: 20px;
}
.tab--shimmer {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(188,186,184,0.5);
}
.scroll--hide-x {
  overflow-x: auto;
}
.scroll--hide-x::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.tab--shimmer .tabShimmer--item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  min-height: 52px;
  position: relative;
}
.tab--shimmer .tabShimmer--link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 0px 20px;
  min-width: 60px;
}
.tab--shimmer .tabShimmer__icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
}
.tab--shimmer .tabShimmer__text {
  height: 16px;
  width: 100%;
  min-width: 60px;
  margin-left: 16px;
}
.tab--shimmer .tabShimmer__border {
  height: 2px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.subTours--block-shimmer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: 152px;
}
.subTours--video-shimmer {
  width: 100%;
  height: 112px;
  border-radius: 10px;
  background-color: #F6F6F6;
  margin-bottom: 16px;
}
.subTours--block-shimmer .para__sm-gray {
  height: 20px;
  width: 100%;
  max-width: 150px;
}
.bb--inner-shimmer {
  min-height: 70px;
}
.bb--inner-shimmer .biShimmer__heading {
  height: 30px;
  width: 100%;
  max-width: 90px;
  margin-bottom: 20px;
}
.bb--inner-shimmer .biShimmer__text {
  height: 20px;
  width: 100%;
  max-width: 120px;
}
.bb--alt-shimmer .buildings__next {
  color: #F5F5F5;
}
.dashbord__shimmer-md {
  height: 20px;
  width: 100%;
  max-width: 150px;
}











@keyframes fullView {
  100% {
    width: 100%;
  }
}
.animate {
  animation : shimmer 4s infinite linear;
  background: linear-gradient(to right, #F5F5F5 4%, #e2e2e2 25%, #F5F5F5 36%);
  background-size: 1000px 100%;
}
.animate--dark {
  animation : shimmer 4s infinite linear;
  background: linear-gradient(to right, #EDEDED 4%, #e2e2e2 25%, #EDEDED 36%);
  background-size: 1000px 100%;
}
.animate--gray {
  animation : shimmer 4s infinite linear;
  background: linear-gradient(to right, #e8ecef 4%, #e2e2e2 25%, #e8ecef 36%);
  background-size: 1000px 100%;
}
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}








@media screen and (min-width: 992px) {
  .shimmer--dc {
    min-height: 520px;
  }
  .shimmer--floorplan {
    min-height: 550px;
  }
}
@media screen and (max-width: 1440px) {
  .pdf__btn-shimmer {
    padding: 10px 12px;
  }
  .pdf__btn-shimmer .pdf__btn-icon {
    margin-right: 12px;
    min-width: 24px;
    height: 28px;
  }
  .pdf__btn-shimmer .pdf__btn-text {
    height: 16px;
  }
  .block--search-row .photos--box-shimmer {
    height: 160px;
  }
}
@media screen and (max-width: 1200px) {
  .db--btns-shimmer {
    width: 100%;
  }
  .search--shimmer-lg .search__text {
    width: 80%;
  }
  .searchBs--shimmer .sb__shimmer-heading {
    width: 70%;
  }
  .db--shimmer {
    margin-top: 20px;
  }
}
@media screen and (max-width: 991px) {
  .alb--alt-shimmer .buildingBtn__shimmer {
    margin-left: 6px;
    margin-right: 6px;
  }
  .alb--alt-shimmer .al--listing-box-sh {
    margin-top: 14px;
  }
  .shimmer__heading-al {
    width: 75%;
  }
  .alb--lg-shimmer .al__address {
    margin-bottom: 20px;
  }
  .tab--shimmer .tabShimmer__text {
    display: none;
  }
  .bb--alt-shimmer .bb--inner-shimmer {
    width: 100%;
    margin-top: 12px;
  }
  .bb--alt-shimmer .bb--sf-shimmer .bb--inner-shimmer:first-child {
    align-items: flex-start;
    padding-right: 8px;
  }
  .bb--alt-shimmer .bb--sf-shimmer .bb--inner-shimmer:last-child {
    align-items: flex-end;
    padding-left: 8px;
  }
}
@media screen and (max-width: 767px) {
  .search--shimmer-lg {
    padding: 4px 15px;
    min-height: 56px;
  }
  .search--shimmer-lg .search__icon {
    width: 16px;
    height: 16px;
    min-width: 16px;
    margin-right: 10px;
  }
  .search--shimmer-lg .search__text {
    height: 16px;
    width: 100%;
  }
  .searchBs--shimmer .sb__shimmer-img {
    margin: 0px auto 20px auto;
    height: 80px;
  }
  .searchBs--shimmer .search--bs-inner {
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
  }
  .searchBs--shimmer .sb__shimmer-heading {
    width: 100%;
    max-width: 240px;
  }
  .searchBs--shimmer {
    flex-direction: column;
  }
  .searchBs--shimmer .search--bs-detail {
    flex-direction: column;
  }
  .searchBs--shimmer .bs__next {
    margin-left: auto;
  }
  .searchBs--shimmer-alt .search--bs-inner {
    margin-bottom: 14px;
    width: 100%;
    flex-direction: column;
  }
  .searchBs--shimmer-alt .sb__shimmer-heading {
    margin: 0px 0px 20px 0px;
  }
  .photos--lg-shimmer,
  .photos--box-shimmer {
    width: 120px;
    height: 120px;
    min-width: 120px;
  }
  .video--box-shimmer {
    width: 180px;
    min-width: 180px;
    height: 130px;
  }
  .viewBy--shimmer {
    margin-top: 16px;
    margin-bottom: 10px;
  }
  .shimmer__heading-al {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .do--btn-canvas,
  .tl--shimmer .tourListing--row .para__md-dark,
  .tl--plus-shimmer .tourListing--row .para__md-dark,
  .tl--alt-shimmer .tourListing--row .para__md-dark,
  .db--btns-shimmer .db__btns {
    width: 100%;
  }
  .placement--shimmer-alt {
    min-height: 165px;
  }
  .db--shimmer {
    min-height: 60px;
  }
  .lt__shimmer {
    margin-left: 0;
    max-width: 76px;
  }
  .dashbaord--opt .button__shimmer {
    width: 100%;
    margin-top: 16px;
    margin-left: 0;
  }
  .button__shimmer .button__shimmer-text {
    max-width: 120px;
  }
  .photos--lg-shimmer {
    width: 100%;
    min-width: 10px;
  }
  .subdomain__footer-shimmer .fp__box {
    align-items: center;
  }
  .do--btn-canvas .db__btns {
    margin-left: 0;
    width: 100%;
    margin-top: 16px;
  }
}
@media screen and (max-width: 480px) {
  .photos--box-shimmer,
  .video--box-shimmer {
    width: 100%;
    min-width: 10px;
  }
}

