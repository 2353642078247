@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.2a4235cf.eot);
  src:  url(/static/media/icomoon.2a4235cf.eot#iefix) format('embedded-opentype'),
  url(/static/media/icomoon.ac0af35c.ttf) format('truetype'),
  url(/static/media/icomoon.eb03ec0b.woff) format('woff'),
  url(/static/media/icomoon.118bf6c3.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clock-standard:before {
  content: "\E983";
}
.icon-condition:before {
  content: "\E984";
}
.icon-cross-alt:before {
  content: "\E985";
}
.icon-cursor:before {
  content: "\E986";
}
.icon-description:before {
  content: "\E987";
}
.icon-eye-alt:before {
  content: "\E988";
}
.icon-file:before {
  content: "\E989";
}
.icon-floorplan-alt:before {
  content: "\E98A";
}
.icon-gallery-upload:before {
  content: "\E98B";
}
.icon-link:before {
  content: "\E98C";
}
.icon-live-integrate:before {
  content: "\E98D";
}
.icon-menu:before {
  content: "\E98E";
}
.icon-pdf:before {
  content: "\E98F";
}
.icon-pencil-fill:before {
  content: "\E990";
}
.icon-replace:before {
  content: "\E991";
}
.icon-review:before {
  content: "\E992";
  color: #6a7074;
}
.icon-rocket:before {
  content: "\E993";
}
.icon-schedule-dashboard:before {
  content: "\E994";
}
.icon-sortby:before {
  content: "\E995";
}
.icon-sortby-ascending:before {
  content: "\E996";
}
.icon-suite:before {
  content: "\E997";
  color: #6a7074;
}
.icon-tv-alt:before {
  content: "\E998";
}
.icon-tv-check:before {
  content: "\E999";
}
.icon-add-group:before {
  content: "\E900";
}
.icon-angle-left:before {
  content: "\E901";
}
.icon-arrow-down:before {
  content: "\E902";
}
.icon-arrow-left:before {
  content: "\E903";
}
.icon-arrow-right:before {
  content: "\E904";
}
.icon-arrow-to-bottom:before {
  content: "\E905";
}
.icon-arrow-up:before {
  content: "\E906";
}
.icon-audio:before {
  content: "\E907";
}
.icon-average-floor:before {
  content: "\E908";
}
.icon-bars:before {
  content: "\E909";
}
.icon-brush:before {
  content: "\E90A";
}
.icon-building:before {
  content: "\E90B";
}
.icon-building-alt:before {
  content: "\E90C";
}
.icon-business:before {
  content: "\E90D";
}
.icon-calendar:before {
  content: "\E90E";
}
.icon-camera:before {
  content: "\E90F";
}
.icon-camera-alt:before {
  content: "\E910";
}
.icon-caret-down:before {
  content: "\E911";
}
.icon-check-circle:before {
  content: "\E912";
}
.icon-chevron-bottom:before {
  content: "\E913";
}
.icon-chevron-down-solid:before {
  content: "\E914";
}
.icon-chevron-left:before {
  content: "\E915";
}
.icon-chevron-right:before {
  content: "\E916";
}
.icon-chevron-top:before {
  content: "\E917";
}
.icon-chevron-up-solid:before {
  content: "\E918";
}
.icon-circle:before {
  content: "\E919";
}
.icon-clipboard-list:before {
  content: "\E91A";
}
.icon-clock:before {
  content: "\E91B";
}
.icon-clock-alt:before {
  content: "\E91C";
}
.icon-compress-wide:before {
  content: "\E91D";
}
.icon-copyright:before {
  content: "\E91E";
}
.icon-cross:before {
  content: "\E91F";
}
.icon-cross-cirlce:before {
  content: "\E920";
}
.icon-dashboard:before {
  content: "\E921";
}
.icon-details:before {
  content: "\E922";
}
.icon-dot-circle:before {
  content: "\E923";
}
.icon-edit:before {
  content: "\E924";
}
.icon-edit-alt:before {
  content: "\E925";
}
.icon-egg-box:before {
  content: "\E926";
}
.icon-ellipsis-h:before {
  content: "\E927";
}
.icon-ellipsis-v:before {
  content: "\E928";
}
.icon-envelope:before {
  content: "\E929";
}
.icon-expand:before {
  content: "\E92A";
}
.icon-external-link:before {
  content: "\E92B";
}
.icon-facebook:before {
  content: "\E92C";
}
.icon-facebook-square:before {
  content: "\E92D";
}
.icon-floorplan:before {
  content: "\E92E";
}
.icon-freight-elevator:before {
  content: "\E92F";
}
.icon-gallery:before {
  content: "\E930";
}
.icon-grid-horizontal:before {
  content: "\E931";
}
.icon-grid-vertical:before {
  content: "\E932";
}
.icon-help:before {
  content: "\E933";
}
.icon-home:before {
  content: "\E934";
}
.icon-instagram:before {
  content: "\E935";
}
.icon-integration:before {
  content: "\E936";
  color: #341062;
}
.icon-join:before {
  content: "\E937";
}
.icon-key:before {
  content: "\E938";
}
.icon-layers:before {
  content: "\E939";
}
.icon-library:before {
  content: "\E93A";
}
.icon-linkedin:before {
  content: "\E93B";
}
.icon-linkedin-square:before {
  content: "\E93C";
}
.icon-list:before {
  content: "\E93D";
}
.icon-location:before {
  content: "\E93E";
}
.icon-lock:before {
  content: "\E93F";
}
.icon-lock-alt:before {
  content: "\E940";
}
.icon-lock-fill:before {
  content: "\E941";
}
.icon-login:before {
  content: "\E942";
}
.icon-logout:before {
  content: "\E943";
}
.icon-mail:before {
  content: "\E944";
}
.icon-management:before {
  content: "\E945";
}
.icon-map-marker:before {
  content: "\E946";
}
.icon-map-marker-alt:before {
  content: "\E947";
}
.icon-map-marker-light:before {
  content: "\E948";
}
.icon-metro:before {
  content: "\E949";
}
.icon-microphone:before {
  content: "\E94A";
}
.icon-microphone-slash:before {
  content: "\E94B";
}
.icon-minus:before {
  content: "\E94C";
}
.icon-minus-circle:before {
  content: "\E94D";
}
.icon-month:before {
  content: "\E94E";
}
.icon-mute:before {
  content: "\E94F";
}
.icon-notification:before {
  content: "\E950";
}
.icon-organisation:before {
  content: "\E951";
}
.icon-paperclip:before {
  content: "\E952";
}
.icon-parking-ratio:before {
  content: "\E953";
}
.icon-passenger-elevator:before {
  content: "\E954";
}
.icon-pause:before {
  content: "\E955";
}
.icon-pause-circle:before {
  content: "\E956";
}
.icon-phone:before {
  content: "\E957";
}
.icon-platform:before {
  content: "\E958";
}
.icon-play:before {
  content: "\E959";
}
.icon-play-circle:before {
  content: "\E95A";
}
.icon-play-video:before {
  content: "\E95B";
}
.icon-plus:before {
  content: "\E95C";
}
.icon-plus-circle:before {
  content: "\E95D";
}
.icon-portal:before {
  content: "\E95E";
  color: #341062;
}
.icon-radio:before {
  content: "\E95F";
}
.icon-renovate:before {
  content: "\E960";
}
.icon-repeat:before {
  content: "\E961";
}
.icon-save:before {
  content: "\E962";
}
.icon-schedule:before {
  content: "\E963";
}
.icon-schedule-alt:before {
  content: "\E964";
}
.icon-search:before {
  content: "\E965";
}
.icon-search-right:before {
  content: "\E966";
}
.icon-setting:before {
  content: "\E967";
}
.icon-share:before {
  content: "\E968";
}
.icon-share-screen:before {
  content: "\E969";
}
.icon-signout:before {
  content: "\E96A";
}
.icon-size:before {
  content: "\E96B";
}
.icon-spacing-horizontal:before {
  content: "\E96C";
}
.icon-spacing-vertical:before {
  content: "\E96D";
}
.icon-stop:before {
  content: "\E96E";
}
.icon-stop-circle:before {
  content: "\E96F";
}
.icon-telecom:before {
  content: "\E970";
}
.icon-tour:before {
  content: "\E971";
}
.icon-trash:before {
  content: "\E972";
}
.icon-tv:before {
  content: "\E973";
}
.icon-twitter:before {
  content: "\E974";
}
.icon-upload:before {
  content: "\E975";
}
.icon-user:before {
  content: "\E976";
}
.icon-user-circle:before {
  content: "\E977";
}
.icon-user-right:before {
  content: "\E978";
}
.icon-users:before {
  content: "\E979";
}
.icon-user-up:before {
  content: "\E97A";
}
.icon-video:before {
  content: "\E97B";
}
.icon-video-slash:before {
  content: "\E97C";
}
.icon-virtual:before {
  content: "\E97D";
}
.icon-volume:before {
  content: "\E97E";
}
.icon-webcam:before {
  content: "\E97F";
}
.icon-year-built:before {
  content: "\E980";
}
.icon-zoom-in:before {
  content: "\E981";
}
.icon-zoom-out:before {
  content: "\E982";
}

body {
  font-family: 'Poppins', sans-serif;
}
ul, ol {
  padding: 0;
  margin: 0;
}
ul li,
ol li  {
  list-style: none;
}
a,
button {
  cursor: pointer;
  transition: all .2s ease;
}
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus,
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
}
img{
  max-width: 100%;
}
button:focus,
input:focus,
textarea:focus,
.form-control:focus {
  outline: none;
  box-shadow: none;
}









/*
  Navbar Style
*/



.navbar-brand {
  line-height: 25px;
}
.navbar-brand img {
  width: 150px;
  height: 26px;
}
.navbar-light .navbar__list .nav-link,
.navbar__list .nav-link {
  color: #E6E6E6;
  font-weight: normal;
  font-size: 14px;
  padding: 0;
  margin: 4px 0;
  line-height: 18px;
}
.navbar-light .navbar__list .nav-link.active,
.navbar-light .navbar__list .nav-link:hover,
.navbar-light .navbar__list .nav-link:focus,
.navbar__list .nav-link:hover {
  color: #C2FFF0;
}
.navbar__list {
  margin-right: 0;
  margin-left: auto;
  padding-left: 20px;
}
.landing__header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 5;
}
.navbar__header {
  padding-left: 0;
  padding-right: 0;
  padding-top: 26px;
  padding-bottom: 26px;
  min-height: 88px;
}
.navbar-toggler {
  width: 26px;
  height: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0;
  border: none;
  margin-left: auto;
  margin-top: 6px;
  margin-bottom: 6px;
}
.navbar-toggler span {
  display: inline-block;
  width: 26px;
  height: 2px;
  background-color: #C2FFF0;
}
.navbar-toggler span:nth-of-type(2) {
  width: 20px;
}
.navbar--btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container--expand {
  padding-left: 20px;
  padding-right: 20px;
}
.app__button,
.app__button-outline {
  color: #1C1F20;
  font-size: 16px;
  font-weight: normal;
  border-radius: 0;
  border: 1px solid #C2FFF0;
  min-width: 200px;
  text-align: center;
  padding: 10px 20px;
  min-height: 48px;
  background-color: #C2FFF0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.app__button-outline {
  color: #143452;
  border: 2px solid #C2FFF0;
  background-color: transparent;
}
.app__button:not(:disabled):not(.disabled):active:focus,
.app__button:not(:disabled):not(.disabled):active,
.app__button:hover,
.app__button:focus {
  background-color: #a6e8d8;
  color: #1C1F20;
  box-shadow: none;
  border-color: #a6e8d8;
}
.app__button.disabled {
  background-color: transparent !important;
  border: 1px solid #BCBAB8 !important;
  color: #BCBAB8 !important;
}
.app__button-outline:not(:disabled):not(.disabled):active:focus,
.app__button-outline:not(:disabled):not(.disabled):active,
.app__button-outline:hover,
.app__button-outline:focus {
  border-color: #a6e8d8;
  color: #1C1F20;
  background-color: #a6e8d8;
  box-shadow: none;
}
.app__button-white {
  color: #341062;
  background-color: #fff;
}
.app__button-white:not(:disabled):not(.disabled):active:focus,
.app__button-white:not(:disabled):not(.disabled):active,
.app__button-white:hover,
.app__button-white:focus {
  background-color: #341062;
  color: #fff;
}
.underline__button {
  position: relative;
}
.underline__button:after {
  content: '';
  background-color: #1C1F20;
  height: 1px;
  width: 120px;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -8px;
}
.underline__button.disabled:after {
  background-color: #BCBAB8;
}
.nav__signin-button {
  color: #C2FFF0;
  border: none;
  font-size: 16px;
  font-weight: normal;
  background-color: transparent;
}
.nav__signin-button:not(:disabled):not(.disabled).active,
.nav__signin-button:not(:disabled):not(.disabled):active,
.nav__signin-button:hover,
.nav__signin-button:focus {
  color: #a6e8d8;
  background-color: transparent;
}


/*
  Navbar Style
*/
















/*
  Component Style
*/





.gradient--component {
  padding-top: 170px;
  background: linear-gradient(76.88deg, #1C1F20 36.61%, #3B4C66 164.46%);
}
.heading__landing {
  font-size: 48px;
  font-weight: normal;
}
.heading__landing-white {
  color: #fff;
}
.heading__landing-green {
  color: #C2FFF0;
}
.video--block {
  position: relative;
}
.video--component {
  max-width: 1620px;
  margin-left: auto;
  margin-right: auto;
}
.landing--video {
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;
}
.landing--video iframe {
  height: 850px;
  background: url('/images/video-cover.png') center center no-repeat;
  background-size: contain;
  padding: 8.5% 13.5% 15.8% 14%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video--component img,
.video--block img {
  width: 100%;
}
.video--component video:focus {
  outline: none;
}
.status__circle {
  color: #E42346;
  font-size: 12px;
  display: inline-block;
}
.heading__component {
  font-size: 34px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
}
.heading__component-white {
  color: #E6E6E6;
}
.heading__component-black {
  color: #222222;
}
.steps--component {
  padding-top: 90px;
  padding-bottom: 90px;
}
.steps--content {
  margin-top: 90px;
}
.steps--block {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  max-width: 326px;
  margin-left: auto;
  margin-right: auto;
}
.steps--img {
  color: #1A1725;
  font-size: 24px;
  width: 80px;
  height: 80px;
  min-width: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #C2FFF0;
  margin-left: auto;
  margin-right: auto;
}
.steps--block .heading__medium {
  margin: 44px 0px 40px 0px;
}
.heading__medium {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}
.heading__medium-green {
  color: #C2FFF0;
}
.heading__medium-black {
  color: #222222;
}
.heading__medium-dark {
  color: #1C1F20;
}
.steps__paragraph {
  font-size: 18px;
  color: #fff;
  font-weight: normal;
  margin-bottom: 0;
}
.mt--btns-block {
  margin-top: 70px;
}
.steps__button {
  color: #fff;
}
.subdomain--component {
  padding-top: 80px;
  background: url('/images/subdomain-bg.png') center bottom no-repeat;
  background-size: cover;
  background-color: #DBFFFF;
  height: 620px;
}
.about--component {
  padding-top: 90px;
  padding-bottom: 90px;
}
.about__heading {
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.about__paragraph {
  color: #6A7074;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 24px;
}
.about--block .row {
  align-items: center;
}
.about--block {
  margin-bottom: 90px;
}
.about--block .about--img {
  pointer-events: none;
}
.about--content {
  margin-top: 110px;
}
.lists--component {
  padding-top: 90px;
  padding-bottom: 90px;
  background: linear-gradient(58.95deg, #1C1F20 36.61%, #3B4C66 164.46%);
}
.lists--block {
  margin-top: 66px;
  border: 4px solid #C2FFF0;
}
.lists--block .lists--head {
  background-color: #C2FFF0;
  text-align: center;
  padding: 22px 18px;
}
.lists__heading {
  color: #1C1F20;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 8px;
}
.lists__subHeading {
  color: #1C1F20;
  font-size: 26px;
  font-weight: normal;
  margin-bottom: 0;
}
.lists-info {
  padding: 70px 15px 70px 36px;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.lists-info li {
  font-size: 20px;
  color: #fff;
  margin-bottom: 30px;
  list-style-type: disc;
}
.lists-info li:last-child {
  margin-bottom: 0;
}
.schedule--component {
  padding-top: 90px;
  padding-bottom: 90px;
}
.schedule__paragraph {
  font-size: 18px;
  color: #6A7074;
  font-weight: 500;
  text-align: center;
}
.schedule--conent {
  margin-top: 66px;
}
.fields--group {
  position: relative;
  margin-bottom: 30px;
}
.fields--group .form-control {
  font-size: 16px;
  font-weight: normal;
  height: 48px;
  border-radius: 0;
  border: 1px solid #BCBAB8;
  padding: 12px 16px;
  color: rgba(106, 112, 116, 0.8);
  background-color: transparent;
}
.fields--group .form-control::-webkit-input-placeholder {
  color: rgba(106, 112, 116, 0.8);
}
.fields--group .form-control::-ms-input-placeholder {
  color: rgba(106, 112, 116, 0.8);
}
.fields--group .form-control::placeholder {
  color: rgba(106, 112, 116, 0.8);
}
.custom-checkbox {
  padding-left: 0;
}
.custom-checkbox .custom-control-input {
  width: 16px;
  height: 16px;
  top: 3px;
}
.custom-checkbox .custom-control-label {
  font-size: 16px;
  color: #6A7074;
  font-weight: normal;
  padding-left: 28px;
  padding-top: 0px;
  padding-bottom: 0;
  cursor: pointer;
  line-height: 24px;
}
.custom-checkbox .custom-control-label::before {
  width: 16px;
  height: 16px;
  border: 1px solid #6A7074;
  border-radius: 0;
  box-shadow: none;
  left: 0;
  top: 3px;
}
.custom-checkbox .custom-control-label::after {
  left: 0;
  top: 3px;
  width: 16px;
  height: 16px;
  box-shadow: none;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  border-color: #341062;
  background-color: #341062;
  box-shadow: none;
}
.custom-checkbox .custom-control-label:focus {
  outline: none;
}
.schedule__button {
  width: 100%;
}
.container--app {
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}



/*
  Component Style
*/
















/*
  Footer Style
*/






.footer {
  background-color: #222222;
  padding-top: 70px;
  padding-bottom: 24px;
}
.footer__logo {
  min-width: 32px;
}
.company__img {
  display: inline-block;
}
.company__about {
  font-size: 14px;
  color: rgba(255,255,255,0.2);
  font-weight: 500;
}
.social__list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.social__list li a {
  color: #fff;
  font-size: 20px;
  margin-left: 20px;
  display: inline-block;
}
.social__list li a:hover,
.social__list li a:focus {
  color: #C2FFF0;
}
.footer__list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 54px;
}
.footer__list li a {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255,255,255,0.5);
  padding: 0 20px;
  border-right: 1px solid rgba(255,255,255,0.5);
  display: inline-block;
  line-height: 20px;
}
.footer__list li:last-child a {
  border-right: 0;
}
.footer__list li a:hover,
.footer__list li a:focus {
  color: #C2FFF0;
}



/*
  Footer Style
*/


















/*
  Forms Style
*/




.form--page {
  display: flex;
}
.form--content {
  width: 100%;
  padding: 90px 40px 60px 40px;
}
.form--banner {
  padding-left: 45px;
  padding-right: 25px;
  width: 490px;
  min-width: 490px;
  background-color: #1C1F20;
}
.formBanner--img {
  margin-top: 24px;
  pointer-events: none;
}
.form--content .navbar__header .container {
  padding-left: 0;
  padding-right: 0;
}
.form--banner .form__brand {
  display: none;
}
.form__brand img {
  width: 151px;
  height: 39px;
}
.formBanner--canvas {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 32px;
}
.signup--inner {
  margin-top: 65px;
  margin-bottom: 65px;
}
.container--xs {
  width: 100%;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}
.mt-sixty {
  margin-top: 60px;
}
.form__heading {
  font-size: 36px;
  font-weight: 400;
  color: #1C1F20;
  margin-bottom: 24px;
}
.form--banner .form__heading {
  margin-top: 46px;
  line-height: 54px;
  margin-bottom: auto;
}
.form__heading-green {
  color: #C2FFF0;
}
.form__heading-blue {
  color: #0099FF;
}
.form__heading-white {
  color: #fff;
}
.form__para {
  color: #6A7074;
  font-size: 16px;
  margin-bottom: 0;
}
.forms--group {
  position: relative;
  margin-bottom: 38px;
}
.datePicker--group input,
.forms--group .form-control {
  border: 1px solid #BCBAB8;
  border-radius: 0;
  color: #222222;
  font-size: 14px;
  font-weight: normal;
  background-color: transparent;
  padding: 13px;
  height: auto;
  min-height: 48px;
  line-height: 19px;
}
.datePicker--group input {
  width: 100%;
  padding-right: 32px;
}
.forms--group .form-control::-webkit-input-placeholder {
  color: #6A7074;
}
.forms--group .form-control::-ms-input-placeholder {
  color: #6A7074;
}
.forms--group .form-control::placeholder {
  color: #6A7074;
}
.forms--group .form-control:focus {
  border-color: #0638E7;
}
.forms--group .form-control.error {
  border-color: #E7063C;
}
.forms--group .error__msg {
  position: absolute;
  left: 0;
  top: 50px;
  color: #E7063C;
  font-size: 12px;
  margin-bottom: 0;
}
.signin--btn-canvas {
  margin-top: 78px;
}
.form--option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.formOpt--terms {
  margin-bottom: 38px;
}
.form__text {
  color: #6A7074;
  font-size: 14px;
  line-height: 22px;

}
.form__link:not([href]),
.form__link {
  color: #1C1F20;
  font-size: 14px;
  font-weight: normal;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 22px;
  display: inline-block;
  position: relative;
}
.form__link-underline:after {
  content: '';
  height: 1px;
  width: 92px;
  background-color: #222222;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.form__link:not([href]):hover,
.form__link:not([href]):focus,
.form__link:hover,
.form__link:focus {
  color: #280E49;
}
.form__icon {
  display: inline-block;
  margin-left: 28px;
  line-height: 16px;
}
.form--opt-r {
  display: flex;
  align-items: center;
}
.select--group .form-control {
  padding-right: 36px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}
select::-ms-expand {
  display: none;
}
.select--group .select__caret-down {
  position: absolute;
  top: 21px;
  right: 16px;
  font-size: 10px;
  line-height: 10px;
  pointer-events: none;
}
.checkbox--set {
  display: block;
  position: relative;
  padding-left: 32px;
  padding-top: 1px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  color: #6A7074;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox__link {
  color: #1C1F20;
  text-decoration: underline;
}
.checkbox__link:hover,
.checkbox__link:focus {
  color: #1C1F20;
}
.checkbox--set input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox--set .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border: 1px solid #BCBAB8;
}
.checkbox--set input:checked ~ .checkmark {
  background-color: #341062;
  border-color: #341062;
}
.checkbox--set .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox--set input:checked ~ .checkmark:after {
  display: block;
}
.checkbox--set .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.admin__button:not([href]),
.admin__button-outline:not([href]),
.admin__button,
.admin__button-outline {
  font-size: 14px;
  font-weight: normal;
  border-radius: 0;
  border: none;
  min-width: 200px;
  text-align: center;
  padding: 10px 20px;
  min-height: 48px;
  color: #fff;
  background-color: #0638E7;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.admin__button-outline:not([href]),
.admin__button-outline {
  background-color: transparent;
  border: 1px solid #0638E7;
  color: #0638E7;
}
.admin__button-outline:not([href]):not(:disabled):not(.disabled):active:focus,
.admin__button-outline:not([href]):not(:disabled):not(.disabled):active,
.admin__button-outline:not([href]):hover,
.admin__button-outline:not([href]):focus,
.admin__button:not([href]):not(:disabled):not(.disabled):active:focus,
.admin__button:not([href]):not(:disabled):not(.disabled):active,
.admin__button:not([href]):hover,
.admin__button:not([href]):focus,
.admin__button-outline:not(:disabled):not(.disabled):active:focus,
.admin__button-outline:not(:disabled):not(.disabled):active,
.admin__button-outline:hover,
.admin__button-outline:focus,
.admin__button:not(:disabled):not(.disabled):active:focus,
.admin__button:not(:disabled):not(.disabled):active,
.admin__button:hover,
.admin__button:focus {
  background-color: #0c30af;
  color: #fff;
  box-shadow: none;
  border: none;
}
.admin__button-outline.disabled,
.admin__button.disabled {
  background-color: transparent !important;
  border: 1px solid #BCBAB8 !important;
  color: #BCBAB8 !important;
}
.admin__button .icon-arrow-right {
  margin-left: 10px;
  font-size: 12px;
}
.admin__button-sm:not([href]),
.admin__button-sm {
  padding: 10px 16px;
  min-width: 188px;
}
.admin__button-radius:not([href]),
.admin__button-radius {
  border-radius: 10px;
}
.admin__button-mini:not([href]),
.admin__button-mini {
  min-height: 32px;
  padding: 6px 10px;
  min-width: 160px;
}
.arrow__btn-set:not([href]),
.arrow__btn-set {
  padding-left: 30px;
  padding-right: 30px;
}
.arrow__btn-set .icon-arrow-right {
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
.outline__button:not([href]),
.outline__button {
  border: 1px solid #222222;
  color: #222222;
  font-size: 14px;
  font-weight: normal;
  background-color: transparent;
  padding: 10px 20px;
  min-height: 48px;
  border-radius: 0;
  min-width: 200px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.outline__button-sm:not([href]),
.outline__button-sm {
  min-height: 32px;
  padding: 6px 10px;
  min-width: 160px;
}
.outline__button-md:not([href]),
.outline__button-md {
  min-height: 32px;
  padding: 6px 10px;
  min-width: 188px;
}
.outline__button:not([href]):not(:disabled):not(.disabled):active,
.outline__button:not([href]):not(:disabled):not(.disabled):active:focus,
.outline__button:not([href]):hover,
.outline__button:not([href]):focus,
.outline__button:not(:disabled):not(.disabled):active,
.outline__button:not(:disabled):not(.disabled):active:focus,
.outline__button:hover,
.outline__button:focus {
  background-color: #222222;
  color: #fff;
  border-color: #222222;
  box-shadow: none;
}
.outline__button.disabled {
  color: #BCBAB8 !important;
  border-color: #BCBAB8 !important;
}
.outline__button-white:not([href]),
.outline__button-white {
  border-color: #fff;
  color: #fff;
}
.outline__button-white:not([href]):not(:disabled):not(.disabled):active,
.outline__button-white:not([href]):not(:disabled):not(.disabled):active:focus,
.outline__button-white:not([href]):hover,
.outline__button-white:not([href]):focus,
.outline__button-white:not(:disabled):not(.disabled):active,
.outline__button-white:not(:disabled):not(.disabled):active:focus,
.outline__button-white:hover,
.outline__button-white:focus {
  background-color: #fff;
  color: #222222;
  border-color: #fff;
  box-shadow: none;
}
.form--banner::-webkit-scrollbar {
  width: 6px;
}
.form--banner::-webkit-scrollbar-thumb {
  background: #0099FF;
}
.row--space-10 {
  margin-left: -10px;
  margin-right: -10px;
}
.row--space-10 .col,
.row--space-10 .col-12 {
  padding-left: 10px;
  padding-right: 10px;
}
.chat__app {
  position: fixed;
  right: 50px;
  bottom: 20px;
  width: 60px;
}
.custom__file {
  color: #1C1F20;
  font-size: 16px;
  margin-bottom: 0;
  position: relative;
  cursor: pointer;
  padding-bottom: 3px;
}
.custom__file input {
  display: none;
}
.custom__file-icon {
  margin-left: 12px;
  font-size: 15px;
  line-height: 18px;
  display: inline-block;
  position: relative;
  top: 1px;
}
.custom__file:before {
  content: '';
  width: 140px;
  height: 1px;
  background-color: #222222;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 0;
}
.uploaded--img-block {
  margin-right: 32px;
  margin-bottom: 30px;
}
.uploaded--img {
  width: 66px;
  height: 66px;
  min-width: 66px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.uploaded--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.uploaded-trash {
  display: inline-block;
  color: #6A7074;
}
.file--uploaded {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.form-label {
  font-size: 12px;
}
.custom__radioBtn {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-top: 16px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom__radioBtn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
  top: 0;
}
.custom__radioBtn .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #6A7074;
  border-radius: 50%;
}
.custom__radioBtn .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custom__radioBtn input:checked ~ .checkmark:after {
  display: block;
}
.custom__radioBtn .checkmark:after {
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #0638E7;
}
.signin--page .form--banner {
  background-color: #071033;
}
.signin--page .form__heading-green {
  color: #EAEDF4;
}
.signin--page .app__button {
  background-color: #EAEDF4;
  border-color: #EAEDF4;
}
.signin--page .app__button:not(:disabled):not(.disabled):active:focus,
.signin--page .app__button:not(:disabled):not(.disabled):active,
.signin--page .app__button:hover,
.signin--page .app__button:focus {
  color: #1C1F20;
  background-color: #e8dec0;
  border-color: #e8dec0;
}
.signin--page .app__button.disabled,
.signin--page .app__button:disabled {
  background-color: transparent !important;
  border: 1px solid #BCBAB8 !important;
  color: #BCBAB8 !important;
}





/*
  Forms Style
*/



















/*
  Sidebar Style
*/





.sidebar {
  z-index: 55;
  background-color: #1C1F20;
  position: relative;
  border-radius: 0px 20px 20px 0px;
  transition: all .2s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.sidebar--canvas {
  width: 270px;
  min-width: 270px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all .2s ease;
}
.sidebar--head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 20px 38px 20px;
}
.sidebar__logo {
  width: 151px;
  height: 39px;
  overflow: hidden;
}
.sidebar__logo-alt {
  width: 34px;
  height: 39px;
  margin-left: auto;
  margin-right: auto;
}
.sidebar .sidebar__logo-alt {
  display: none;
}
.sidebar--content {
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 20px;
}
.sidebar--content::-webkit-scrollbar {
  width: 6px;
}
.sidebar--content::-webkit-scrollbar-thumb {
  background: #0099FF;
}
.sidebar__list {
  margin-bottom: 24px;
}
.sidebar__list li a {
  color: #BCBAB8;
  font-size: 16px;
  font-weight: normal;
  padding: 12px 0;
  display: flex;
  align-items: center;
  min-height: 46px;
  line-height: 24px;
}
.sidebar__list li a i {
  font-size: 16px;
  margin-right: 10px;
}
.sidebar__list li a:hover,
.sidebar__list li a.active {
  color: #0099FF;
}
.sidebar__list li a .sidebar__list-text {
  white-space: nowrap;
}
.sidebar__list-alt {
  margin-bottom: 10px;
  margin-top: auto;
}
.sidebar__toggle {
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #fff;
  width: 28px;
  height: 36px;
  border-radius: 21px;
  font-size: 12px;
  background-color: #111212;
}
.menu__toggle {
  width: 22px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0;
  background-color: transparent;
  border: none;
  margin-left: auto;
}
.toggle__span {
  display: inline-block;
  width: 22px;
  height: 2px;
  background-color: #341062;
}
.menu__toggle .toggle__span:nth-of-type(2) {
  width: 14px;
}
.sidebar--canvas::-webkit-scrollbar {
  width: 6px;
}
.sidebar--canvas::-webkit-scrollbar-thumb {
  background: #270F46;
}
.sidebar--user-canvas {
  padding: 20px 20px 24px 20px;
}
.sidebar--user {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 270px;
  min-height: 56px;
  cursor: pointer;
}
.sidebar--img {
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  overflow: hidden;
  border-radius: 50%;
  -khtml-border-radius: 50%;
}
.sidebar--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sidebar__name {
  font-size: 16px;
  color: #fff;
  margin-bottom: 2px;
  word-break: break-word;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.sidebar__mention {
  font-size: 14px;
  color: #BCBAB8;
  margin-bottom: 0;
  word-break: break-word;
  font-weight: normal;
}
.dropdown__item-span {
  color: #fff;
  margin-left: 10px;
  font-size: 10px;
}
.sidebar--detail {
  width: 100%;
}
.sidebar--popover {
  border-radius: 10px;
  width: 220px;
  border: 1px solid rgba(188, 186, 184, 0.33);
  box-shadow: 0px 4px 15px rgba(165, 165, 165, 0.22);
  left: -20px !important;
  margin-top: -26px !important;
}
.sidebar--popover-sm {
  left: -8px !important;
  margin-top: -15px !important;
}
.sidebar--popover[x-placement^=right]>.arrow::after,
.sidebar--popover>.arrow::after {
  border-width: .55rem .7rem .55rem 0;
}
.sidebar--popover .arrow {
  top: 8px !important;
}
.sidebar--popover .popover-body {
  padding: 15px;
}
.options__list li a {
  color: #6A7074;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 6px 0;
  word-break: break-word;
  white-space: normal;
}
.options__list li a .options__list-icon {
  margin-right: 10px;
}
.options__list li a:hover,
.options__list li a:focus {
  color: #0638e7;
}
.sidebar--content-bottom {
  display: flex;
  flex-direction: column;
}
.sidebar--hide {
  margin-right: 40px;
}
.sidebar--hide .sidebar--canvas {
  width: 70px;
  min-width: 70px;
}
.sidebar--hide .sidebar--card .card-body,
.sidebar--hide .sidebar__list li a .sidebar__list-text,
.sidebar--hide .sidebar--card .card-header .btn-link .sidebar__list-text,
.sidebar--hide .sidebar--detail,
.sidebar--hide .sidebar__logo {
  display: none;
}
.sidebar--hide .sidebar--content::-webkit-scrollbar {
  width: 2px;
}
.sidebar--hide .sidebar--content {
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
}
.sidebar--hide .sidebar--head {
  padding-left: 10px;
  padding-right: 10px;
}
.sidebar--hide .sidebar--content-bottom {
  align-items: center;
}
.sidebar--hide .sidebar--img {
  margin-left: auto;
  margin-right: auto;
}
.sidebar--hide .sidebar__logo-alt {
  display: block;
}
.sidebar--hide .sidebar__list li a,
.sidebar--hide .sidebar--card .card-header .btn-link {
  justify-content: center;
}
.sidebar--hide .sidebar__list li a i,
.sidebar--hide .sidebar--card .card-header .btn-link i {
  margin-left: auto;
  margin-right: auto;
}
.sidebar--hide .sidebar--user-canvas {
  padding-left: 10px;
  padding-right: 10px;
}
.sidebar--hide .sidebar--user {
  min-height: 10px;
}
.sidebar--hide .sidebar__toggle {
  transform: rotate(180deg);
  right: 8px;
}
.sidebar--card {
  background-color: transparent;
  border: none;
}
.accordion>.sidebar--card>.card-header,
.sidebar--card .card-header {
  padding: 0;
  margin-bottom: 0;
  border-bottom: 0;
}
.sidebar--card .card-header .btn-link {
  color: #BCBAB8;
  font-size: 16px;
  font-weight: normal;
  padding: 12px 0;
  min-height: 46px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
}
.sidebar--card .card-header .btn-link i {
  font-size: 16px;
  margin-right: 10px;
}
.sidebar--card .card-header .btn-link:hover,
.sidebar--card .card-header .btn-link:focus,
.sidebar--card .card-header .btn-link.active {
  color: #0099FF;
  box-shadow: none;
  text-decoration: none;
}
.sidebar--card .card-body {
  padding: 0px 0px 0px 36px;
}
.toggle__btn {
  width: 30px;
  height: 30px;
  min-width: 30px;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 8px;
  background-color: #1c1f20;
  border: none;
  margin-left: auto;
  margin-bottom: 20px;
}
.toggle__btn:hover,
.toggle__btn:focus,
.toggle__btn:active,
.toggle__btn:not(:disabled):not(.disabled):active:focus,
.toggle__btn:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #1c1f20;
  border: none;
  box-shadow: none;
}




/*
  Sidebar Style
*/























/*
  Dashboard Style
*/




.dashboard--page {
  display: flex;
}
.dashboard--content {
  width: 100%;
  padding-top: 26px;
  padding-bottom: 40px;
}
.dashboard--container {
  width: 100%;
  max-width: 1420px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.dashboard--opt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard--opt .back__btn,
.dashboard--opt .menu__toggle {
  margin-bottom: 20px;
}
.back__btn:not([href]),
.back__btn {
  font-size: 16px;
  color: #6A7074;
  display: inline-flex;
  align-items: center;
}
.back__btn-icon {
  margin-right: 8px;
  font-size: 11px;
  line-height: 12px;
}
.back__btn:hover,
.back__btn:focus {
  color: #0638e7;
}
.dashboard--head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.dashboard__heading {
  color: #1C1F20;
  font-size: 34px;
  font-weight: normal;
  word-break: break-word;
  margin-bottom: 0;
}
.step__text {
  color: #6A7074;
  font-size: 14px;
  display: inline-block;
  margin-right: 12px;
}
.step__info {
  color: #1C1F20;
  font-size: 14px;
  font-weight: 500;
}
.step--head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
}
.dashboard--select-n select.form-control {
  border-color: #222222;
  font-size: 14px;
  color: #222222;
  padding: 12px 35px 12px 15px;
  min-width: 200px;
}
.dashboard--select-n:after {
  content: '';
  background-color: #222222;
  height: 1px;
  width: 100px;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -8px;
}
.search--group .form-control {
  padding: 13px 13px 13px 44px;
}
.search--group .search__icon {
  position: absolute;
  left: 16px;
  top: 16px;
  color: #6A7074;
  font-size: 16px;
  line-height: 20px;
}
.step__button {
  min-width: 240px;
}
.btn__mw {
  min-width: 10px;
  width: 100%;
}
.dashboard--top-content {
  margin-top: 36px;
}
.dashboard--top-content .forms--group {
  margin-bottom: 30px;
}
.dashboard__paragraph {
  color: #222222;
  font-size: 18px;
}
.dashboard--canvas {
  width: 100%;
}
.mb-66 {
  margin-bottom: 66px;
}
.mb-48 {
  margin-bottom: 48px;
}
.mb-28 {
  margin-bottom: 28px;
}
.step--upload-block {
  min-height: 72px;
  margin-bottom: 36px;
}
.step--upload-block .custom__file {
  margin-top: 40px;
}
.text-black {
  color: #000;
}
.stepBtn-canvas {
  margin-top: 60px;
  text-align: right;
}
.stepBtn-canvas-1 {
  padding-top: 32px;
}
.row--space-12 {
  margin-left: -12px;
  margin-right: -12px;
}
.row--space-12 .col-12 {
  padding-left: 12px;
  padding-right: 12px;
}
.sf--group .form-control {
  padding-right: 30px;
}
.sf--group .sf__text {
  color: #6A7074;
  font-size: 14px;
  position: absolute;
  right: 14px;
  top: 15px;
  pointer-events: none;
  line-height: 18px;
  text-transform: uppercase;
}
.combine--group {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
}
.combine--group .forms--group {
  margin-bottom: 0;
  width: 100%;
}
.combine__group-cross {
  font-size: 10px;
  color: #1C1F20;
  padding: 6px;
}
.listing--schedule-block {
  margin-bottom: 38px;
}
.listing--schedule {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.listing--schedule-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.listing--schedule-col .select--group {
  min-width: 110px;
  margin-bottom: 0;
}
.week--time {
  width: 100%;
  padding-right: 10px;
}
.hours__change {
  color: #000000;
  font-size: 16px;
  display: inline-block;
}
.hours--time {
  display: flex;
  align-items: center;
}
.hours__separate {
  display: inline-block;
  min-width: 14px;
  text-align: center;
}
.listing--schedule-left {
  padding-right: 10px;
}
.listing--schedule-right {
  padding-left: 10px;
}
.add__options-button {
  font-size: 14px;
  color: #222222;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #222222;
  padding: 4px 10px;
  min-width: 220px;
}
.add__options-button:hover,
.add__options-button:focus {
  color: #fff;
  background-color: #222222;
}
.listing--dropdown .error__msg {
  position: absolute;
  left: 0;
  top: 52px;
  color: #E7063C;
  font-size: 12px;
}
.listing--dropdown {
  margin-bottom: 38px;
}
.listing--dropdown.mb-66 {
  margin-bottom: 66px;
}
.listing--dropdown .dropdown-toggle {
  border: 1px solid #BCBAB8;
  border-radius: 0;
  color: #222222;
  font-size: 14px;
  font-weight: normal;
  background-color: transparent;
  padding: 13px;
  height: auto;
  min-height: 48px;
  line-height: 19px;
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}
.listing--dropdown-pad .dropdown-toggle {
  padding-right: 36px;
}
.listing--dropdown .dropdown-toggle .select__caret-down {
  position: absolute;
  top: 18px;
  right: 16px;
  color: #222222;
  font-size: 10px;
  line-height: 10px;
  pointer-events: none;
}
.listing--dropdown .dropdown-toggle:after {
  content: none;
}
.listing--dropdown.show>.dropdown-toggle,
.listing--dropdown.show>.dropdown-toggle:focus,
.listing--dropdown .dropdown-toggle:not(:disabled):not(.disabled):active,
.listing--dropdown .dropdown-toggle:focus,
.listing--dropdown .dropdown-toggle:active {
  color: #222222;
  background-color: transparent;
  box-shadow: none;
  border-color: #0638E7;
}
.listing--dropdown.show .dropdown-toggle .select__caret-down {
  transform: rotate(180deg);
  top: 17px;
}
.listing--dropdown .dropdown-menu {
  right: 0px !important;
  padding: 10px 13px;
  margin-top: -1px;
  border-radius: 0;
  border-color: #0638E7;
  max-height: 240px;
  overflow-y: auto;
}
.listing--dropdown .dropdown-menu::-webkit-scrollbar {
  width: 4px;
}
.listing--dropdown .dropdown-menu::-webkit-scrollbar-thumb {
  background: #BCBAB8;
}
.listing--dropdown .dropdown-item {
  padding: 8px 13px;
  font-size: 14px;
  color: #6A7074;
  border-radius: 2px;
  word-break: break-word;
  white-space: normal;
}
.listing--dropdown .dropdown-item:hover,
.listing--dropdown .dropdown-item:focus,
.listing--dropdown .dropdown-item:active {
  background-color: #0638e7;
  color: #fff;
}
.accordion>.listing--card:not(:last-of-type),
.listing--card {
  border: none;
  background-color: #fff;
  border-bottom: 1px solid #BCBAB8;
}
.listing--card .card-header {
  padding: 0;
  background-color: transparent;
  border: none;
  margin-bottom: 0;
}
.listing--card .btn-link {
  font-size: 14px;
  color: #6A7074;
  padding: 10px 0;
  width: 100%;
  text-align: left;
}
.listing--card .btn-link:hover,
.listing--card .btn-link:focus {
  text-decoration: none;
  box-shadow: none;
}
.listing--card .accordian__icon {
  position: absolute;
  right: 1px;
  top: 16px;
  font-size: 12px;
  line-height: 10px;
}
.listing--card .accrodian__minus {
  display: none;
}
.listing--card .btn-link.active .accrodian__plus {
  display: none;
}
.listing--card .btn-link.active .accrodian__minus {
  display: block;
}
.listing--accordian .listing--card:last-child {
  border-bottom: 0;
}
.listing--card .card-body {
  padding: 0px;
}
.listing--card .card-body a {
  font-size: 14px;
  color: #6A7074;
  display: flex;
}
.link__gray:not([href]),
.link__gray {
  color: #222222;
  text-decoration: underline;
}
.link__gray:not([href]):hover,
.link__gray:not([href]):focus,
.link__gray:hover,
.link__gray:focus {
  color: #222222;
}
.text__gray {
  color: #6A7074;
}
.custom__file-sm:before {
  width: 66px;
}
.step--content-3 .step--upload-block {
  min-height: 30px;
}
.forms--group textarea.form-control {
  height: 130px;
}
.rent--group .form-control {
  padding-right: 68px;
}
.rent--group .rent__text {
  color: #6A7074;
  font-size: 14px;
  position: absolute;
  right: 14px;
  top: 15px;
  pointer-events: none;
  line-height: 18px;
}
.icons--group .form-control {
  padding-right: 32px;
}
.icons--group .icons__text {
  color: #6A7074;
  font-size: 14px;
  position: absolute;
  right: 14px;
  top: 16px;
  pointer-events: none;
  line-height: 16px;
}
.editListing--head-btn {
  display: flex;
  align-items: center;
}
.editListing--head-btn .sm__button {
  margin-left: 20px;
}
.prev--next-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}
.pages__option {
  display: inline-flex;
  align-items: center;
  color: #222222;
}
.pages__option .pages__option-text {
  text-decoration: underline;
  display: inline-block;
}
.pages__option .pages__option-icon {
  line-height: 16px;
}
.prev__option .pages__option-icon {
  margin-right: 10px;
}
.next__option .pages__option-icon {
  margin-left: 10px;
}
.pages__option:hover,
.pages__option:focus {
  color: #0638E7;
}
.upload__button {
  color: #1C1F20;
  font-size: 16px;
  margin-bottom: 0;
  position: relative;
  cursor: pointer;
  padding-bottom: 3px;
  background-color: transparent;
  padding: 0;
  border: none;
  white-space: nowrap;
}
.upload__button:before {
  content: '';
  width: 66px;
  height: 1px;
  background-color: #222222;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 0;
}
.upload__button .upload__button-icon {
  margin-left: 12px;
  font-size: 15px;
  line-height: 18px;
  display: inline-block;
  position: relative;
  top: 1px;
}
.upload__button:not(:disabled):not(.disabled):active,
.upload__button:not(:disabled):not(.disabled):active:focus,
.upload__button:hover,
.upload__button:focus {
  background-color: transparent;
  box-shadow: none;
  color: #1C1F20;
}


/*
  Dashboard Style
*/



















/*
  Admin/Broker Home Style
*/





.admin--home {
  padding-top: 0;
}
.admin--banner {
  background: url('/images/admin-home.png') center center no-repeat;
  background-size: cover;
  height: 270px;
  position: relative;
}
.home__mark {
  background: linear-gradient(218.62deg, #1B8FFA 4.75%, #0638E7 95.82%);
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -50px;
  margin-left: auto;
  margin-right: auto;
}
.adminHome--canvas {
  margin-top: 100px;
}
.annex--block {
  margin-top: 44px;
}
.annex--card {
  border: 1px solid #BCBAB8;
  padding: 32px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.annex--info {
  display: flex;
  margin-bottom: 14px;
}
.annex__info-icon {
  color: #222222;
  font-size: 17px;
  min-width: 18px;
  text-align: center;
  display: inline-block;
  line-height: 18px;
  margin-right: 10px;
  margin-top: 3px;
}
.annex__info-heading {
  margin-bottom: 0;
  color: #222222;
  font-size: 20px;
  font-weight: normal;
}
.sm__button:not([href]),
.sm__button {
  min-width: 156px;
}
.sm__button.underline__button:not([href]):hover,
.sm__button.underline__button:after {
  width: 78px;
}
.menuToggle--canvas {
  text-align: right;
  padding-top: 26px;
  display: flex;
  justify-content: flex-end;
}
.menu__toggle-second .toggle__span {
  background-color: #fff;
}
.annex--card-blue {
  background-color: #0638E7;
  border-color: #0638E7;
}
.annex--card-blue .annex__info-icon,
.annex--card-blue .annex__info-heading,
.annex--card-blue .form__para {
  color: #fff;
}
.admin__button-white:not([href]),
.admin__button-white {
  font-size: 14px;
  font-weight: normal;
  border-radius: 0;
  min-width: 156px;
  text-align: center;
  padding: 10px 20px;
  min-height: 48px;
  color: #0638E7;
  background-color: #fff;
  border: 1px solid #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.admin__button-white:not([href]):not(:disabled):not(.disabled):active:focus,
.admin__button-white:not([href]):not(:disabled):not(.disabled):active,
.admin__button-white:not([href]):hover,
.admin__button-white:not([href]):focus,
.admin__button-white:not(:disabled):not(.disabled):active:focus,
.admin__button-white:not(:disabled):not(.disabled):active,
.admin__button-white:hover,
.admin__button-white:focus {
  background-color: transparent;
  color: #fff;
  box-shadow: none;
  border-color: #fff;
}
.adminButton__white-lg:not([href]),
.adminButton__white-lg {
  min-width: 200px;
}
.admin__button-white:after {
  content: '';
  background-color: #fff;
  height: 1px;
  width: 78px;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -8px;
}
.adminButton__white-lg:after:not([href]),
.adminButton__white-lg:after {
  width: 120px;
}


/*
  Admin/Broker Home Style
*/


















/*
  Listing Style
*/


.container--large {
  max-width: 1330px;
}
.listing {
  padding-top: 25px;
}
.listing--content {
  margin-top: 50px;
}
.listing--head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.listing--head .dashboard__heading {
  margin-top: 4px;
}
.listing--head .heading--group .dashboard__heading {
  margin-top: 0;
}
.listing--options {
  display: inline-flex;
  align-items: center;
}
.options__box {
  width: 38px;
  height: 38px;
  min-width: 38px;
  color: #fff;
  background-color: #341062;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 7px;
}
.option__share {
  background-color: #4C316F;
}
.options__box:hover,
.options__box:focus {
  color: #fff;
  background-color: #542492;
}
.listing--video {
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.listing--video img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.listing--video video {
  height: 360px;
  width: 100%;
}
.listing--video-overlay {
  background-color: rgba(0, 102, 178, 0.21);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.play__button:not([href]),
.play__button {
  display: inline-block;
  color: #fff;
  font-size: 40px;
  width: 34px;
  height: 40px;
  line-height: 40px;
}
.play__button:not([href]):hover,
.play__button:not([href]):focus,
.play__button:hover,
.play__button:focus {
  color: #0638E7;
}
.gallery--box {
  width: 100%;
  height: 200px;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
}
.gallery--box img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.small__paragraph {
  color: #6A7074;
  font-size: 14px;
  line-height: 24px;
}
.small__heading {
  color: #222222;
  font-size: 14px;
  font-weight: 500;
}
.description__para {
  font-size: 16px;
}
.listing-component {
  margin-bottom: 60px;
}
.spec--data .small__heading {
  text-transform: uppercase;
}
.spec--data {
  margin-bottom: 6px;
}
.key--details {
  padding: 16px;
  margin-top: 20px;
  background-color: #F7F7F7;
  min-height: 232px;
}
.keyDetails__heading {
  color: #222222;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 34px;
}
.key--box {
  margin-bottom: 25px;
}
.keyBox--head {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}
.keyBox--head i {
  color: #7D7D7D;
  margin-right: 8px;
  font-size: 15px;
}
.keyBox--head i.icon-size {
  font-size: 14px;
}
.keyBox--head .small__heading,
.keyBox--head .small__paragraph {
  margin-bottom: 0;
}
.key--box .small__heading {
  color: #6A7074;
  font-weight: normal;
}
.key--box .small__paragraph {
  color: #222222;
  font-weight: normal;
  margin-bottom: 0;
  line-height: inherit;
}
.listing--map {
  position: relative;
  height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 20px;
}
.listing--map img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.pdf__button {
  font-size: 16px;
}








/*
  Listing Style
*/













/*
  Building / Building Listings Style
*/



.available--listing {
  padding: 25px 0;
}
.available--listing-content {
  margin-top: 50px;
}
.al__heading {
  margin-bottom: 20px;
}
.available--listing-view {
  margin-top: 52px;
  margin-bottom: 32px;
}
.available--listing-view .forms--group,
.available--listing-view .listing--dropdown {
  margin-bottom: 0;
}
.viewBy__text {
  color: #000000;
  font-size: 16px;
  line-height: 26px;
  display: inline-block;
}
.viewBy__marker {
  font-size: 20px;
  color: #0638E7;
  line-height: 20px;
  display: inline-block;
}
.viewBy__marker.disabled {
  color: #BCBAB8;
}
.grid__view {
  line-height: 20px;
  font-size: 20px;
}
.grid__horizontal {
  color: #BCBAB8;
}
.grid__vertical {
  color: #0638E7;
}
.dropdown--checkbox {
  border-bottom: 1px solid #BCBAB8;
  padding-left: 30px;
  line-height: 16px;
  padding-bottom: 15px;
  margin-top: 10px;
}
.dropdown--checkbox .checkmark {
  width: 16px;
  height: 16px;
  top: 1px;
}
.dropdown--checkbox .checkmark:after {
  left: 4px;
  top: 2px;
  width: 5px;
  height: 9px;
}
.available--listing-off {
  margin-bottom: 32px;
}
.available--listing-cols {
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 32px;
}
.available--listing-cols .available--listing-off:last-child {
  margin-bottom: 0;
}
.available--listing-cols .available--listing-off:last-child .al--block:last-child {
  margin-bottom: 0;
}
.available--listing-cols .scroll--listing-cols::-webkit-scrollbar {
  width: 0px;
}
.subdomain__footer {
  background-color: #1C1F20;
  border-radius: 22px 22px 0px 0px;
  padding-top: 54px;
  padding-bottom: 32px;
}
.sd__footer-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.sd__footer-list li a {
  color: #0099FF;
  font-size: 14px;
  margin-right: 24px;
  display: inline-block;
}
.sd__footer-list li:last-child a {
  margin-right: 0;
}
.sd__footer-list li a:hover,
.sd__footer-list li a:focus {
  color: #288ed2;
}
.sd__footer-para {
  font-size: 14px;
  color: #fff;
  font-weight: normal;
  line-height: 24px;
}
.subdomain__footer .sd__footer-l {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-height: 100%;
  justify-content: space-between;
}
.subdomain__footer .sd__footer-l .sd__footer-para {
  font-size: 12px;
  line-height: 16px;
}
.subdomain__footer .sd__footer-para .icon-copyright {
  font-size: 10px;
}
.al__heading-medium {
  color: #222222;
  margin-bottom: 28px;
  font-weight: normal;
  font-size: 24px;
}
.al--block {
  display: flex;
  margin-bottom: 20px;
}
.al--block .al--block-img {
  width: 242px;
  height: 158px;
  min-width: 242px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.al--block .al--block-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.al--block .al--block-detail {
  padding: 22px 20px;
  width: 100%;
  margin-left: -1px;
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  border: 1px solid #BCBAB8;
}
.al__address {
  font-size: 20px;
  color: #222222;
  margin-bottom: 12px;
  line-height: 22px;
  font-weight: 400;
  display: flex;
  align-items: center;
  word-break: break-word;
}
.al__address .add__logo {
  display: inline-block;
  width: 22px;
  height: 22px;
  line-height: 18px;
  margin-left: 12px;
}
.al--listing {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.al--listing-box .al--listing:last-child {
  margin-bottom: 0;
}
.al--listing .al__icon {
  display: inline-block;
  line-height: 18px;
  color: #6A7074;
  margin-right: 10px;
}
.al--listing .al__text {
  color: #6A7074;
  font-size: 15px;
  margin-right: 8px;
  display: inline-block;
  min-width: 46px;
}
.al--listing .alText__detail {
  color: #222222;
  margin-right: 0;
}
.al--block .al--btn-component {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.heading--group .form-control {
  padding: 0;
  border: none;
  color: #1C1F20;
  font-size: 34px;
  font-weight: normal;
  word-break: break-word;
  margin-bottom: 0;
  height: auto;
  line-height: 46px;
}
.heading--group .form-control::-webkit-input-placeholder {
  color: #1C1F20;
}
.heading--group .form-control::-ms-input-placeholder {
  color: #1C1F20;
}
.heading--group .form-control::placeholder {
  color: #1C1F20;
}
.heading--group .heading__edit {
  color: #222222;
  font-size: 18px;
  line-height: 20px;
  margin-top: 10px;
  margin-left: 10px;
}
.heading--group .heading__edit:hover {
  color: #222222;
}
.heading--group {
  display: flex;
  padding-right: 24px;
  width: 100%;
  overflow: hidden;
}
.list__button:not([href]),
.list__button {
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  padding: 4px 7px;
  min-width: 92px;
  text-align: center;
  margin-bottom: auto;
  margin-top: auto;
}
.list__button-draft {
  background-color: #BCBAB8;
}
.list__button-review {
  background-color: rgba(255, 141, 57, 0.8);
}
.list__button-published {
  background-color: #222222;
}
.list__button:not([href]):hover,
.list__button:hover {
  color: #fff;
  opacity: .8;
}
.customer--block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 12px;
}
.customer--img {
  width: 40px;
  height: 40px;
  min-width: 40px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -6px;
}
.customer--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.customer--block .customer--img:first-child {
  margin-left: 0;
}
.customer--add:not([href]),
.customer--add {
  font-size: 16px;
  color: #fff;
  min-width: 32px;
  height: 32px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0638E7;
  margin-left: -6px;
}
.customer--add:not([href]):hover,
.customer--add:not([href]):focus,
.customer--add:hover,
.customer--add:focus {
  color: #fff;
  background-color: #0c30af;
}
.buildingListing--inner {
  padding-top: 30px;
}
.bl--head-btn {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}
.modified__text {
  color: #BCBAB8;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  margin-top: auto;
}
.modified__text .modified__text-icon {
  display: inline-block;
  line-height: 18px;
  margin-right: 10px;
}
.buildings--block .ald--inner {
  display: flex;
  flex-direction: column;
}
.buildings--block .bb-sf {
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.buildings--block .bb--inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buildings--block .bb--inner {
  width: 50%;
}
.b--sf-heading,
.b--sf-text {
  color: #222222;
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 10px;
}
.b--sf-text {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}
.sd__bars:not([href]),
.sd__bars {
  display: inline-flex;
  flex-direction: column;
  color: #C4C4C4;
}
.sd__bars:not([href]):hover,
.sd__bars:not([href]):focus,
.sd__bars:hover,
.sd__bars:focus {
  color: #C4C4C4;
}
.sd__bars .bars__bg {
  display: block;
  width: 36px;
  height: 4px;
  background: #C4C4C4;
  margin-bottom: 6px;
}
.contact__btn:not([href]),
.contact__btn {
  color: #222222;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #222222;
  padding: 3px 10px;
  text-align: center;
}
.contact__btn:not([href]):hover,
.contact__btn:not([href]):focus,
.contact__btn:hover,
.contact__btn:focus {
  color: #fff;
  background-color: #222222;
}
.helping--block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.requestTour--box {
  border: 1px solid #0638E7;
  padding: 43px 25px;
  position: relative;
  min-height: 360px;
}
.cross__btn {
  color: #6A7074;
  background-color: transparent;
  border: navajowhite;
  padding: 0;
  line-height: 16px;
  display: inline-block;
  position: absolute;
  right: 0;
  top: -32px;
}
.requestTour--box-sent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rt--sent-canvas {
  text-align: center;
}
.rt__ent-heading {
  color: #0638E7;
  font-size: 24px;
}
.rt--sent-logo,
.sub__logo {
  width: 80px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.rt--sent-logo img,
.sub__logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.listing__logo,
.available--listing-logo {
  width: 151px;
  height: 39px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.gallery--carousel .carousel-indicators {
  bottom: -15px;
}
.gallery--carousel .carousel-indicators li {
  width: 16px;
  height: 16px;
  background-color: #cdd7fa;
  margin-left: 11px;
  margin-right: 11px;
  border: none;
  opacity: 1;
}
.gallery--carousel .carousel-indicators li.active {
  background-color: #0638E7;
}
.gallery--carousel .carousel-control-next,
.gallery--carousel .carousel-control-prev {
  display: none;
}




/*
  Building / Building Listings Style
*/
















/*
  Subdomain Style
*/




.virtualTour--content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8%;
}
.tour--video {
  width: 100%;
  max-width: 420px;
  height: 236px;
  overflow: hidden;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
}
.tour--video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tour--video video {
  height: 236px;
  width: 100%;
}
.font-semibold {
  font-weight: 600;
}
.text__dark {
  color: #222222;
}
.vc--mobile {
  text-align: center;
  margin-bottom: 24px;
  margin-top: 0 !important;
  padding-top: 16px;
}
.tenat--feature {
  margin-bottom: 30px;
}
.tenat--feature .tf__icon {
  font-size: 42px;
  color: #000;
  display: inline-block;
  line-height: 40px;
}
.app__link:not([href]),
.app__link {
  font-size: 16px;
  color: #0638E7;
  font-weight: normal;
  display: inline-block;
}
.app__link:not([href]):hover,
.app__link:not([href]):focus,
.app__link:hover,
.app__link:focus {
  color: #0638E7;
  text-decoration: underline;
}
.virtualTour--video {
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.virtualTour--video video {
  height: 360px;
  width: 100%;
}
.virtualTour--video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video__expand {
  color: #222222;
  font-size: 22px;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  box-shadow: 0px 4px 10px rgba(78, 72, 72, 0.25);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.video__expand:hover {
  color: #0638E7;
}
.virtualTour--overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: rgba(21, 35, 92, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.virtualTour__overlay-icon {
  color: #fff;
  font-size: 32px;
  line-height: 32px;
}
.virtualTour__overlay-text {
  color: #fff;
  font-size: 16px;
  margin-top: 14px;
}
.gallery--box-overlay {
  background-color: rgba(0, 102, 178, 0.21);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery--overlay-icon {
  color: #fff;
  font-size: 32px;
  cursor: pointer;
  line-height: 30px;
  display: inline-block;
}
.virtualTour--video-sm {
  height: 224px;
}
.virtualTour--video-sm video {
  height: 224px;
}
.key--details-vt {
  min-height: 100px;
}
.virtual--video-block {
  width: 100%;
  max-width: 368px;
  position: relative;
  z-index: 5;
  margin-left: auto;
  margin-right: auto;
}
.virtual--video-main {
  border: 1px solid #222222;
}
.virtual--video-off {
  display: flex;
  flex-wrap: wrap;
}
.virtual--video {
  width: 100%;
  height: 210px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.virtual--video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.virtual--video video {
  height: 210px;
  width: 100%;
}
.virtual--video-col .virtual--video {
  width: 50%;
}
.small--video-col .virtual--video {
  height: 105px;
}
.small--video-col .virtual--video video {
  height: 105px;
}
.vv--overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  display: flex;
  justify-content: center;
}
.vv--overlay .vv--overlay-off {
  padding: 5px 10px;
  min-width: 230px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(34,34,34,0.7);
}
.vv--overlay .vv--overlay-text,
.vv--overlay .vv--overlay-btn {
  color: #fff;
  font-size: 16px;
  display: inline-block;
  line-height: 24px;
}
.virtual--video-col .vv--overlay .vv--overlay-off {
  min-width: 100px;
}
.virtual--video-col .vv--overlay .vv--overlay-text {
  font-size: 12px;
}
.vv--overlay .vv--overlay-btn {
  margin-left: 12px;
  font-size: 18px;
  background-color: transparent;
  border: none;
  line-height: 20px;
}
.vv--options {
  border: 1px solid #222222;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 15px;
  margin-top: -1px;
  min-height: 66px;
  margin-bottom: 20px;
}
.vvOptions-btn {
  border: none;
  background-color: transparent;
  margin-right: 20px;
  padding: 0;
  font-size: 16px;
  min-width: 20px;
}
.vvOptions-rec {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #222222;
  border-radius: 2px;
  padding: 2px 6px;
  line-height: 16px;
  text-align: center;
  border: none;
  text-transform: uppercase;
}
.more--dropdown .dropdown-toggle {
  color: #434343;
  font-size: 16px;
  min-width: 18px;
  text-align: center;
  line-height: 16px;
  padding: 0 2px;
  background-color: transparent;
  box-shadow: none;
  border: none;
  transition: all .2s ease;
}
.more--dropdown .dropdown-toggle:after {
  content: none;
}
.more--dropdown .btn-primary:not(:disabled):not(.disabled).active,
.more--dropdown .btn-primary:not(:disabled):not(.disabled):active,
.more--dropdown.show>.btn-primary.dropdown-toggle {
  background-color: transparent;
  box-shadow: none;
  border: none;
  color: #4A4A4A;
}
.more--dropdown .dropdown-menu {
  right: 24px !important;
  left: inherit !important;
  border-radius: 0px;
  margin-top: 4px;
  transform: translate3d(0px, 0px, 0px) !important;
  border: none;
  background-color: #F7F7F7;
  padding: 0;
  min-width: 144px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.14);
}
.more--dropdown .dropdown-menu .dropdown-item {
  font-size: 14px;
  color: #434343;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  transition: all .2s ease;
}
.more--dropdown .dropdown-menu .dropdown-item i {
  font-size: 10px;
  margin-right: 10px;
  min-width: 10px;
  text-align: center
}
.more--dropdown .dropdown-menu .dropdown-item i.icon-slack {
  font-size: 11px;
}
.more--dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #0638E7;
  color: #fff;
}
.virtual--expanded {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  z-index: 5;
  padding: 48px 0px 24px 0px;
}
.virtual--gallery {
  background-color: rgba(34,34,34,0.8);
}
.back__btn-white,
.back__btn-white:hover,
.back__btn-white:focus {
  color: #fff;
}
.virtual--carousel .carousel-indicators {
  display: none;
}
.virtual--carousel .carousel-control-next,
.virtual--carousel .carousel-control-prev {
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  opacity: 1;
  margin-top: auto;
  margin-bottom: auto;
  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,.25);
}
.virtual--carousel .carousel-control-prev {
  left: 15px;
}
.virtual--carousel .carousel-control-next {
  right: 15px;
}
.virtual--carousel .carousel-control-prev .carousel-control-prev-icon {
  background: url('/images/prev.svg') center center no-repeat;
  background-size: 13px;
}
.virtual--carousel .carousel-control-next .carousel-control-next-icon {
  background: url('/images/next.svg') center center no-repeat;
  background-size: 13px;
}
.virtual--carousel .carousel-control-next:hover,
.virtual--carousel .carousel-control-prev:hover {
  background-color: #222222;
  border-color: #222222;
}
.virtual--carousel .carousel-item {
  height: 500px;
}
.virtual--carousel .carousel-item img {
  height: 100%;
  object-fit: cover;
}
.virtual--carousel {
  margin-bottom: 36px;
}
.gallery--box-sm {
  width: 90px;
  height: 90px;
  min-width: 90px;
  min-height: 90px;
  margin-right: 20px;
}
.virtual__frame {
  width: 100%;
  height: 100%;
  border: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.virtual--expanded .back__btn {
  position: relative;
  z-index: 5;
}
.ve--gradient:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 150px;
  z-index: 5;
  background: linear-gradient(180deg, #1A1A1A 0%, rgba(26, 26, 26, 0) 100%);
}
.direction__control:not([href]),
.direction__control {
  width: 40px;
  height: 40px;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #564f49;
  position: absolute;
  top: 10px;
  z-index: 5;
}
.direction__control:not([href]):hover,
.direction__control:hover {
  background-color: #222222;
  color: #fff;
}
.direction__control-next {
  right: 10px;
}
.direction__control-prev {
  left: 10px;
}
.tourEnd--content .form__para {
  font-size: 18px;
}





/*
  Subdomain Style
*/










/*
  Admin Settings Style
*/


.adminSettings--head {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.adminSettings--head .adminSettings__icon {
  font-size: 24px;
  display: inline-block;
  margin-right: 16px;
  line-height: 24px;
}
.adminSettings--head .adminSettings__text {
  color: #222222;
  font-size: 24px;
}
.profile--img {
  width: 236px;
  height: 236px;
  min-width: 236px;
  overflow: hidden;
  background-color: #BCBAB8;
  border-radius: 50%;
  -khtml-border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.profile--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile--img-upload {
  cursor: pointer;
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  height: 90px;
  background-color: rgba(34,34,34,0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profile--img-upload input {
  display: none;
}
.profile--img-upload .imgUpload-icon {
  font-size: 20px;
  display: inline-block;
  line-height: 20px;
  color: #fff;
}
.profile--img-upload .imgUpload-text {
  color: #fff;
  font-size: 16px;
  display: inline-block;
  margin: 8px 0;
}
.saveBtn__icon {
  line-height: 14px;
  display: inline-block;
}
.adminSettings--row {
  margin-bottom: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid #BCBAB8;
}
.adminSettings--upload {
  display: flex;
  align-items: center;
  position: relative;
}
.adminSettings--upload-item {
  border: 1px solid #BCBAB8;
  border-radius: 0;
  color: #222222;
  font-size: 14px;
  font-weight: normal;
  background-color: transparent;
  padding: 13px;
  height: auto;
  min-height: 48px;
  line-height: 19px;
  width: 100%;
  margin-bottom: 0;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.adminSettings--upload-item input {
  display: none;
}
.adminSettings--upload-del {
  display: inline-block;
  font-size: 16px;
  color: #222222;
  margin-left: 20px;
  margin-right: 10px;
}
.adminSettings--upload-del:hover,
.adminSettings--upload-del:focus {
  color: #0638E7;
}
.uploaded--content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 11px 13px;
}
.uploaded--content .uploadedContent__img {
  width: 102px;
  min-width: 102px;
  height: 24px;
  margin-right: 12px;
  background-color: rgba(188,186,184,0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.uploaded--content .uploadedContent__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.uploaded--content .uploadedContent__name {
  color: #222222;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.default--upload-item input {
  display: block;
  font-size: 14px;
  line-height: 14px;
  width: 84px;
  min-width: 96px;
  overflow: hidden;
}
.default--upload-item .de__text {
  font-size: 14px;
  color: #222222;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
}
.interface--dropdown .dropdown-menu {
  padding-left: 0;
  padding-right: 0;
}
.interface--dropdown .dropdown-item:hover,
.interface--dropdown .dropdown-item:focus {
  background-color: #f8f9fa;
}
.color__item {
  display: flex;
  align-items: center;
}
.color__item .color__box {
  width: 16px;
  height: 16px;
  min-width: 16px;
  display: inline-block;
  text-indent: -999px;
  margin-right: 10px;
}
.color__item .color__text {
  color: #6A7074;
  font-size: 16px;
  display: inline-block;
}
.color__item .bg-white {
  border: 1px solid #6A7074;
}
.bg-blue {
  background-color: #0638E7;
}
.bg-lightBlue {
  background-color: #0099FF;
}
.bg-purple {
  background-color: #621EB8;
}
.bg-orange {
  background-color: #EA5C0C;
}
.bg-red {
  background-color: #ED2C2C;
}
.bg-green {
  background-color: #219D35;
}
.bg-mintGreen {
  background-color: #C2FFF0;
}
.bg-black {
  background-color: #000;
}
.listingFinal--detail {
  margin: 20px 0;
}
.listingFinal__logo {
  width: 80px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
  overflow: hidden;
}
.listingFinal__logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



/*
  Admin Settings Style
*/













/*
  Modal Style
*/



.modal .modal-content {
  border: none;
  border-radius: 0;
}
.modal .modal-title {
  font-size: 30px;
  font-weight: normal;
  color: #000;
  line-height: 32px;
}
.cross__circle-modal {
  width: 34px;
  height: 34px;
  padding: 0;
  background-color: transparent;
  border: none;
  font-size: 34px;
  line-height: 34px;
  position: absolute;
  right: 10px;
  top: 12px;
  margin: 0;
}
.cross__circle-modal:not(:disabled):not(.disabled):active:focus,
.cross__circle-modal:not(:disabled):not(.disabled):active,
.cross__circle-modal:hover,
.cross__circle-modal:focus {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.modal--uploadPhotos .modal-header {
  padding: 32px;
  border-bottom: 0;
  align-items: center;
}
.modal--uploadPhotos .modal-body {
  padding: 16px 32px 32px 32px;
}
.custom__file-modal {
  color: #000;
  font-size: 14px;
  position: relative;
  cursor: pointer;
  border: 2px dashed #BCBAB8;
  width: 100%;
  height: 310px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.custom__file-modal input {
  display: none;
}
.cf__modal-para {
  color: #BCBAB8;
  margin: 22px 0px 0px 0px;
}
.uploadPhoto--row {
  margin-bottom: 14px;
  display: flex;
}
.uploadPhoto--img {
  width: 50px;
  height: 50px;
  min-width: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.uploadPhoto--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.uploadPhoto--detail {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uploadPhoto--inner {
  width: 100%;
  padding-left: 12px;
  padding-right: 20px;
}
.uploadPhoto__name {
  font-size: 14px;
  color: #222222;
  font-weight: normal;
  line-height: 20px;
  display: inline-block;
  word-break: break-word;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.uploadPhoto--progress {
  display: flex;
  align-items: center;
}
.uploadPhoto--progress .uploadPhoto__bar {
  display: inline-block;
  width: 100%;
  height: 5px;
  text-indent: -999px;
  background-color: #BCBAB8;
  overflow: hidden;
  position: relative;
}
.uploadPhoto--progress .uploadPhoto__bar .bar__fill {
  background-color: #0638E7;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
.uploadPhoto__text {
  font-size: 11px;
  color: #BCBAB8;
  display: inline-block;
  margin-left: 10px;
}
.uploadPhoto__delete {
  color: #222222;
  font-size: 18px;
  display: inline-block;
  line-height: 22px;
}
.uploadPhoto--block .uploadPhoto--row:last-child {
  margin-bottom: 0;
}
.uploadPhoto--block {
  max-height: 310px;
  overflow-y: auto;
}
.uploadPhoto--block::-webkit-scrollbar {
  width: 1px;
}
.modal .modal-body::-webkit-scrollbar {
  width: 3px;
}
.modal .modal-body::-webkit-scrollbar-thumb {
  background: #BCBAB8;
}
.modal__cross {
  background-color: transparent;
  border: none;
  font-size: 18px;
  color: #6A7074;
  padding: 0;
  line-height: 22px;
}
.modal__cross:not(:disabled):not(.disabled):active:focus,
.modal__cross:not(:disabled):not(.disabled):active,
.modal__cross:hover,
.modal__cross:focus {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #6A7074;
}
.modal-settings .close {
  display: none;
}
.modal-settings .modal-header {
  padding: 34px 28px;
  border-bottom: 0;
  align-items: center;
}
.modal-settings .modal-body {
  padding: 32px 28px;
}
.modalSetting-canvas {
  width: 100%;
  max-width: 510px;
  margin-left: auto;
  margin-right: auto;
}
.office-modal .modal-header {
  border-bottom: 0;
  padding: 34px 60px 28px 60px;
}
.office-modal .modal-body {
  padding: 0px 60px 30px 60px;
}
.modal--video {
  background-color: rgba(26,27,30,0.25);
}
.modal--video .modal-content {
  background-color: transparent;
  height: 100%;
}
.modal--video .modal-body {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.modal--video-set {
  width: 100% !important;
  max-width: 540px;
  height: 300px !important;
  background-color: #6A7074;
}
.mv__cross {
  position: absolute;
  right: 32px;
  top: 0;
  color: #fff;
  padding: 0;
  background-color: transparent;
  border: none;
}
.mv__cross:not(:disabled):not(.disabled):active:focus,
.mv__cross:not(:disabled):not(.disabled):active,
.mv__cross:not(:disabled):not(.disabled):active,
.mv__cross:hover,
.mv__cross:focus {
  color: #fff;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
}
.listing--modal .modal-content {
  border-radius: 20px;
}
.listing--modal .modal-body {
  padding: 40px 40px 34px 40px;
}
.listing--modal .forms--group textarea.form-control {
  height: 104px;
}
.btn--canvas-sl .admin__button.disabled {
  background-color: #BCBAB8 !important;
  border-color: #BCBAB8 !important;
  color: #fff !important;
}
.listingModal--request {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 420px;
}
.request__logo {
  width: 80px;
  height: 90px;
}
.para__separator {
  display: inline-block;
  width: 100%;
}






/*
  Modal Style
*/






/*
  Terms & Privacy Style
*/



.terms {
  padding: 25px 0;
}
.terms--content {
  margin-top: 58px;
  width: 100%;
  max-width: 970px;
  margin-left: auto;
  margin-right: auto;
}
.terms .dashboard__heading {
  margin-bottom: 40px;
}
/* .terms p.text__gray {
  text-align: justify;
} */
.terms__list li {
  list-style-type: decimal;
  padding-left: 10px;
  font-weight: 600;
  font-size: 18px;
  word-break: break-word;
}
.alpha__list,
.roman__list {
  padding-left: 20px;
}
.alpha__list li,
.roman__list li {
  color: #6A7074;
  padding-left: 10px;
  font-weight: normal;
  margin-bottom: 24px;
  font-size: 16px;
  word-break: break-word;
}
.alpha__list li {
  list-style-type: lower-alpha;
}
.roman__list li {
  list-style-type: lower-roman;
}
.terms__label {
  font-weight: bold;
  color: #1C1F20;
  display: inline-block;
  text-decoration: underline;
}
.terms__list li .text__gray {
  font-weight: normal;
  font-size: 16px;
}
.terms__list li .text__blue {
  font-weight: 600;
  font-size: 16px;
  word-break: break-word;
}
.terms__list li strong {
  font-weight: 600;
  font-size: 16px;
}



/*
  Terms & Privacy Style
*/










/*
  Empty Buildings/Listings Style
*/


.empty--box {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8%;
}
.empty--box .emptyBox__img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.empty--buidlings .emptyBox__img {
  width: 190px;
  height: 210px;
}
.empty--listings .emptyBox__img {
  width: 200px;
  height: 160px;
}
.em__para {
  font-size: 20px;
}






/*
  Empty Buildings/Listings Style
*/















/*
  Broker Landing Style
*/




.pearl--bg {
  background-color: #EAEDF4;
}
.component--top {
  padding-top: 184px;
  min-height: 510px;
}
.landing__heading {
  color: #15235C;
  font-size: 62px;
  font-weight: 700;
}
.landing__para {
  color: #1C1F20;
  font-size: 21px;
}
.schedule__btn:not([href]),
.schedule__btn-outline:not([href]),
.schedule__btn,
.schedule__btn-outline {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 18px;
  line-height: 24px;
  text-align: center;
  border-radius: 0;
  border-color: #15235C;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
}
.schedule__btn:not([href]),
.schedule__btn {
  color: #EAEDF4;
  background-color: #15235C;
}
.schedule__btn-n:not([href]),
.schedule__btn-n {
  color: #fff;
}
.schedule__btn:not([href]):not(:disabled):not(.disabled):active:focus,
.schedule__btn:not([href]):not(:disabled):not(.disabled):active,
.schedule__btn:not([href]):hover,
.schedule__btn:not([href]):focus,
.schedule__btn:not([href]):active,
.schedule__btn:not(:disabled):not(.disabled):active:focus,
.schedule__btn:not(:disabled):not(.disabled):active,
.schedule__btn:hover,
.schedule__btn:focus,
.schedule__btn:active {
  color: #EAEDF4;
  border-color: #08091C;
  background-color: #08091C;
  box-shadow: none;
}
.schedule__btn-outline:not([href]),
.schedule__btn-outline {
  color: #15235C;
  background-color: transparent;
}
.schedule__btn-outline:not([href]):not(:disabled):not(.disabled):active:focus,
.schedule__btn-outline:not([href]):not(:disabled):not(.disabled):active,
.schedule__btn-outline:not([href]):hover,
.schedule__btn-outline:not([href]):focus,
.schedule__btn-outline:not([href]):active,
.schedule__btn-outline:not(:disabled):not(.disabled):active:focus,
.schedule__btn-outline:not(:disabled):not(.disabled):active,
.schedule__btn-outline:hover,
.schedule__btn-outline:focus,
.schedule__btn-outline:active {
  color: #EAEDF4;
  border-color: #15235C;
  background-color: #15235C;
  box-shadow: none;
}
.key__span {
  display: inline-block;
  line-height: 18px;
  margin-left: 8px;
}
.navbar-light .navbar__list-n .nav-link,
.navbar__list-n .nav-link,
.navSignin__btn {
  color: #1C1F20;
  font-size: 16px;
}
.navbar-light .navbar__list-n .nav-link:hover,
.navbar__list-n .nav-link:hover,
.navbar-light .navbar__list-n .nav-link.active,
.navbar__list-n .nav-link.active,
.navSignin__btn:not(:disabled):not(.disabled).active,
.navSignin__btn:not(:disabled):not(.disabled):active,
.navSignin__btn:hover,
.navSignin__btn.active {
  color: #15235c;
}
.vt--demand-content {
  margin-top: 86px;
  min-height: 450px;
}
.broker--card {
  background-color: transparent;
  border: none;
  margin-bottom: 44px;
}
.broker--card .card-header {
  background-color: transparent;
  border: none;
  padding: 0;
}
.broker--card .btn-link {
  color: #15235C;
  font-size: 20px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  padding: 0;
  border: none;
}
.broker--card .btn-link .bc__icon {
  display: inline-block;
  line-height: 20px;
  margin-right: 12px;
  width: 22px;
  height: 22px;
}
.broker--card .btn-link:hover,
.broker--card .btn-link:focus {
  text-decoration: none;
  box-shadow: none;
}
.broker--card .card-body {
  position: relative;
  padding: 0px 0px 0px 34px;
  margin-top: 10px;
}
.broker--card .card-body:before {
  content: '';
  width: 2px;
  background-color: #15235C;
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
}
.bc__paragraph {
  font-size: 16px;
  color: #555555;
}
.text__blue:not([href]),
.text__blue {
  color: #15235C;
}
.broker--card .card-body .bc__paragraph:last-child {
  margin-bottom: 0;
}
.broker--card-demo .card-body {
  display: none;
}
.broker--video {
  width: 100%;
  margin-bottom: -66px;
  position: relative;
  z-index: 5;
  max-width: 810px;
  margin-left: auto;
  margin-right: auto;
}
.video-placement {
  position: relative;
}
.broker--video-placement {
  height: 440px;
  overflow: hidden;
  position: relative;
  border-radius: 0px 0px 15px 15px;
  box-shadow: 0px 5px 15px #4644421F;
}
.broker--video-placement video,
.broker--video-placement iframe {
  height: 440px;
  border-radius: 0px 0px 15px 15px;
  border: none;
}
.broker--video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video--bar {
  pointer-events: none;
}
.vp__box {
  width: 62px;
  height: 62px;
  min-width: 62px;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #5A585829;
  border: 1px solid #E6E6E6;
  background-color: #fff;
  position: absolute;
  right: -14px;
  top: 138px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-indent: -9999px;
}
.vp__box:before {
  content: '';
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #0638E7;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.vp__box:after {
  content: '';
  width: 1px;
  height: 19px;
  display: inline-block;
  background-color: #E6E6E6;
  position: absolute;
  left: 13px;
  bottom: -16px;
  transform: rotate(28deg);
}
.vp__logo {
  width: 182px;
  height: 60px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #5A585829;
  border: 1px solid #E6E6E6;
  position: absolute;
  left: -72px;
  top: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}
.vp__logo img {
  width: 151px;
  height: 39px;
  object-fit: initial;
}
.vp__logo:before {
  content: '';
  width: 1px;
  height: 20px;
  display: inline-block;
  background-color: #E6E6E6;
  position: absolute;
  right: 67px;
  top: -19px;
  transform: rotate(28deg);
}
.vp__secure {
  font-size: 21px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  background-color: #01061A;
  padding: 4px 35px;
  position: absolute;
  top: -76px;
  left: 83px;
}
.vp__secure .vp__secure-icon {
  display: inline-block;
  font-size: 22px;
  margin-right: 12px;
  line-height: 22px;
}
.vp__secure:after {
  content: '';
  width: 1px;
  height: 15px;
  display: inline-block;
  background-color: #E6E6E6;
  position: absolute;
  left: 34px;
  bottom: -14px;
  transform: rotate(28deg);
}
.heading__component-blue,
.heading__medium-blue {
  color: #15235C;
}
.info--component {
  padding-top: 250px;
  padding-bottom: 140px;
  background: url('/images/deals-bg.png') bottom no-repeat;
  position: relative;
  background-size: 100% 124%;
  background-position: left 0px bottom -550px;
}
.info--component:after {
  content: '';
  background: transparent linear-gradient(181deg, #FCFCFC 0%, #FCFCFC00 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
}
.info--content {
  margin-top: 110px;
  text-align: center;
  position: relative;
  z-index: 5;
}
.info--component .heading__component {
  position: relative;
  z-index: 5;
}
.info--block {
  margin-bottom: 20px;
}
.info--block .info--img {
  width: 200px;
  height: 140px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 34px;
  pointer-events: none;
}
.info--block .info--img img {
  width: 100%;
  height: 100%;
}
.info--block .heading__medium {
  margin-bottom: 20px;
}
.info--block .steps__paragraph {
  color: #1C1F20;
}
.opinion--component {
  padding-top: 100px;
  padding-bottom: 170px;
  background-color: #15235C;
}
.opinion--canvas {
  width: 100%;
  max-width: 704px;
  margin-left: auto;
  margin-right: auto;
}
.opinion--content {
  margin-top: 94px;
}
.opinion--block .opinion--img {
  width: 90px;
  height: 90px;
  min-width: 90px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 48px;
}
.opinion--block .opinion--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.opinion__para {
  color: #fff;
  font-size: 24px;
  line-height: 36px;
  position: relative;
  font-weight: normal;
}
.opinion__para:before {
  content: '';
  background: url('/images/quote-left.svg') center center no-repeat;
  background-size: 32px;
  width: 34px;
  height: 22px;
  position: absolute;
  left: -44px;
  top: -10px;
  color: #fff;
  display: inline-block;
}
.opinion__para:after {
  content: '';
  background: url('/images/quote-right.svg') center center no-repeat;
  background-size: 32px;
  width: 34px;
  height: 22px;
  position: absolute;
  right: -32px;
  bottom: 20px;
  color: #fff;
  display: inline-block;
}
.op--info {
  margin-top: 30px;
  margin-bottom: 30px;
}
.op--name {
  color: #EAEDF4;
  font-size: 16px;
  margin-bottom: 4px;
  line-height: 20px;
  font-weight: normal;
}
.op__logo {
  display: inline-flex;
  align-items: center;
  width: 151px;
  height: 39px;
}
.personalize--block {
  background-color: #fff;
  max-width: 640px;
  width: 100%;
  display: inline-block;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 3px 15px #5C5B5A29;
  padding: 44px 48px 54px 48px;
  margin-top: -50px;
  margin-bottom: -128px;
  position: relative;
  z-index: 5;
}
.personalize--block .heading__component {
  color: #1C1F20;
  margin-bottom: 46px;
}
.personalize--block .forms--group {
  margin-bottom: 20px;
}
.personalize--block .forms--group .form-control {
  color: #6A7074;
}
.personalize--component {
  display: inline-block;
  width: 100%;
}
.footer-n {
  background-color: #08091C;
  padding-bottom: 70px;
  padding-top: 270px;
  display: inline-block;
  width: 100%;
}
.footer-n .footer--top {
  padding-bottom: 36px;
  margin-bottom: 40px;
  border-bottom: 1px solid #fff;
}
.footer--copyright {
  color: #EAEDF4;
  font-size: 16px;
}
.f__list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.f__list li a {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: normal;
  display: inline-block;
  margin-right: 66px;
}
.f__list-right li:last-child a {
  margin-right: 0;
}
.f__list li a:hover,
.f__list li a:focus {
  color: #EAEDF4;
}
.social__list-f li a {
  font-size: 22px;
  color: #EAEDF4;
}
.social__list-f li a:hover,
.social__list-f li a:focus {
  color: #fff;
}
.toggler--n span {
  background-color: #15235C;
}






/*
  Broker Landing Style
*/













/*
  Tenant Landing Style
*/




.figures--component {
  padding-top: 100px;
  padding-bottom: 380px;
}
.tenant--video {
  margin-top: -275px;
  margin-bottom: 275px;
  max-width: 990px;
  margin-left: auto;
  margin-right: auto;
}
.tenant--video-placement {
  height: 550px;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 5px 15px #4644421F;
}
.tenant--video-placement video,
.tenant--video-placement iframe {
  height: 550px;
  border: none;
  border-radius: 24px;
}
.tour--component {
  margin-top: -154px;
}
.tour--row {
  display: flex;
  overflow-x: auto;
}
.tour--row::-webkit-scrollbar {
  width: 0px;
}
.tour--col {
  padding-left: 10px;
  padding-right: 10px;
  min-width: 23%;
}
.tour--box {
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0px 3px 15px #5C5B5A29;
}
.tour--box .tourBox--img {
  width: 100%;
  height: 184px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tour--box .tourBox--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tour--box .tourBox--detail {
  padding: 14px 24px;
}
.tour--box .td__name {
  font-size: 18px;
  color: #15235C;
  font-weight: 500;
  margin-bottom: 10px;
}
.td__opt {
  display: inline-flex;
  align-items: center;
  margin-bottom: 8px;
}
.td__opt .td__opt-icon {
  color: #6A7074;
  font-size: 16px;
  display: inline-block;
  margin-right: 10px;
}
.td__opt .td__opt-text {
  color: #1C1F20;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
}
.schedule__btn-v:not([href]),
.schedule__btn-v {
  color: #15235C;
  background-color: #EAEDF4;
}
.schedule__btn-v:not([href]):not(:disabled):not(.disabled):active:focus,
.schedule__btn-v:not([href]):not(:disabled):not(.disabled):active,
.schedule__btn-v:not([href]):hover,
.schedule__btn-v:not([href]):focus,
.schedule__btn-v:not([href]):active,
.schedule__btn-v:not(:disabled):not(.disabled):active:focus,
.schedule__btn-v:not(:disabled):not(.disabled):active,
.schedule__btn-v:hover,
.schedule__btn-v:focus,
.schedule__btn-v:active {
  color: #15235C;
  background-color: #f9e8ba;
}
.figures--component .info--block {
  padding-left: 0;
  padding-right: 0;
}






/*
  Tenant Landing Style
*/
















/*
  About Us Style
*/


.gif--component {
  margin-top: -68px;
}
.gif--box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 138px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 15px #5D5D5D29;
  margin-bottom: 20px;
  overflow: hidden;
}
.gif--box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gif--box .gif__text {
  font-size: 18px;
  color: #15235C;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-block;
  opacity: .4;
}
.strive--component {
  padding-top: 100px;
  padding-bottom: 100px;
}
.strive--content {
  padding-bottom: 100px;
  border-bottom: 1px solid #EAEDF4;
}
.team--component {
  padding-bottom: 200px;
}
.team--content {
  margin-top: 100px;
}
.team--box {
  text-align: center;
  margin-bottom: 74px;
}
.team--box .team--img {
  width: 120px;
  height: 120px;
  min-width: 120px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 18px;
  background-color: #EAEDF4;
  pointer-events: none;
}
.team--box .team__name {
  color: #15235C;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
}
.team--box .team__designation {
  color: #1C1F20;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 0;
}
.personalize--block .forms--group textarea.form-control {
  height: 120px;
}
.personalize--block-about {
  margin-top: -122px;
  margin-bottom: 190px;
}










/*
  About Us Style
*/


















/*
  Virtual Tour Tenant/Broker/StandBy
*/



.virtualTour--combine {
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.vt--container {
  width: 100%;
  max-width: 900px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}
.vt--combine {
  margin: 30px 0;
}
.vt--combine .dashboard__heading {
  margin-bottom: 32px;
  font-size: 24px;
}
.vt--combine .adminSettings--head {
  margin-bottom: 20px;
  align-items: flex-start;
}
.vt--combine .adminSettings--head .adminSettings__icon {
  font-size: 30px;
  color: #0638E7;
  margin-right: 12px;
  margin-top: 4px;
}
.vt--combine .adminSettings--head .adminSettings__text {
  font-size: 34px;
  color: #0638E7;
  font-weight: 500;
  line-height: 38px;
}
.vt--combine .schedule__paragraph {
  font-weight: normal;
  margin-bottom: 40px;
}
.vt__btn {
  min-width: 10px;
}
.vt__btn-icon {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 22px;
}
.video--player {
  margin-top: 70px;
  text-align: center;
}
.video__link {
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  color: #0638E7;
}
.video__link .video__link-text {
  text-decoration: underline;
  display: inline-flex;
}
.video__link .video__link-icon {
  font-size: 24px;
  margin-right: 12px;
  line-height: 24px;
  display: inline-flex;
}
.video__link:hover,
.video__link:focus {
  color: #0c30af;
}
.video__link:hover .video__link-text,
.video__link:focus .video__link-text {
  text-decoration: none;
}
.vt--footer {
  background-color: #0638E7;
  text-align: center;
  padding: 32px 0;
}
.vt--footer .container--large {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.vt--footer .vtFooter__icon {
  width: 44px;
  height: 44px;
  min-width: 44px;
  margin: 4px 14px 4px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vt--footer .vtFooter__text {
  color: #fff;
  font-size: 18px;
  margin: 4px 0;
}
.vt--footer .vtFooter__text a {
  text-decoration: underline;
}
.meeting--block {
  border: 1px solid #BCBAB8;
  border-radius: 10px;
  padding: 18px 24px;
  margin-bottom: 20px;
}
.meeting--block .meetingBlock--top {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.meeting--block .meetingBlock__icon {
  color: #222222;
  font-size: 20px;
  width: 28px;
  height: 22px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.meeting--block .heading__medium {
  line-height: 30px;
  margin-bottom: 0;
}
.meetingBlock__para {
  font-size: 14px;
  color: #6A7074;
  margin-bottom: 0;
  line-height: 22px;
}
.virtualTour--combine-alt {
  justify-content: flex-start;
}
.suite--box {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  border-radius: 10px;
  background-color: #F7F7F7;
  min-height: 54px;
  margin-bottom: 20px;
}
.suite--box .suite--box-f {
  display: flex;
  align-items: center;
  min-width: 30%;
}
.suite--box .suiteBox__icon {
  font-size: 18px;
  color: #6A7074;
  margin-right: 10px;
  display: inline-flex;
  line-height: 18px;
}
.suite--box .suiteBox__label {
  font-size: 18px;
  margin-bottom: 0;
  color: #6A7074;
  line-height: 20px;
}
.suiteBox__sf {
  display: flex;
  align-items: flex-end;
  padding-left: 6px;
  min-width: 70%;
}
.suiteBox__sf .suiteBox__no {
  font-size: 28px;
  margin-bottom: 0;
  color: #222222;
  line-height: 30px;
  display: flex;
  margin-left: auto;
}
.suiteBox__sf .suiteBox__text {
  font-size: 14px;
  margin-left: 6px;
  text-transform: uppercase;
}
.suite--canvas {
  margin-bottom: 18px;
}
.copyUrl__para {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.copyUrl__para .url__link {
  color: #0638E7;
  text-decoration: underline;
  margin-right: 4px;
}
.copyUrl__para .url__link:hover {
  text-decoration: none;
  color: #0c30af;
}
.team--canvas {
  padding-top: 36px;
  border-top: 1px solid rgba(188,186,184,0.4);
}
.team--head {
  display: flex;
  align-items: center;
  justify-content: center;
}
.team--head .teamHead__img {
  width: 30px;
  height: 34px;
  min-width: 30px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.team--head .heading__medium {
  margin-bottom: 0;
  line-height: 26px;
}
.team--block {
  margin-bottom: 32px;
  text-align: center;
}
.team--block .teamBlock--img {
  width: 60px;
  height: 60px;
  min-width: 60px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
}
.team--block .teamBlock__user {
  font-size: 15px;
  color: #222222;
  font-weight: normal;
  margin-bottom: 0px;
  word-break: break-word;
}
.team--block .teamBlock__email {
  font-size: 12px;
  color: #0638E7;
  text-decoration: none;
  word-break: break-word;
}
.team--block .teamBlock__email:hover,
.team--block .teamBlock__email:focus {
  color: #0c30af;
  text-decoration: underline;
}
.team--main {
  margin-top: 50px;
}



/*
  Virtual Tour Tenant/Broker/StandBy
*/















/*
  Record Voice Over
*/





.approve--video {
  color: #222222;
  font-weight: normal;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
}
.av-mute {
  min-width: 24px;
  margin-right: 10px;
  font-size: 24px;
  display: inline-block;
  line-height: 22px;
}
.av__link:not([href]),
.av__link {
  color: #0638E7;
  text-decoration: underline;
}
.av__link:not([href]):hover,
.av__link:not([href]):focus,
.av__link:hover,
.av__link:focus {
  color: #0638E7;
  text-decoration: none;
}
.lb__arrow,
.lb__record {
  position: relative;
}
.arrow__icon {
  display: inline-flex;
  position: absolute;
  right: 20px;
  top: 17px;
}
.arrow__icon .icon-arrow-right {
  font-size: 14px;
}
.recording--overlay {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  text-align: center;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(34, 34, 34, 0.8);
}
.recording__heading {
  font-size: 30px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 16px;
}
.recording__timer {
  font-size: 120px;
  color: #fff;
  font-weight: 700;
  line-height: 120px;
}
.lb__record-icon {
  min-width: 24px;
  font-size: 24px;
  color: #ee1a23;
  display: inline-flex;
  position: absolute;
  left: 12px;
  top: 12px;
  transition: all .2s ease;
}
.admin__button.lb__pause__resume .lb__record-icon {
  color: #ffffff;
}
.lb__record.active {
  background-color: #222222;
  border-color: #222222;
  color: #fff;
}
.lb__record.active .lb__record-icon,
.lb__record:hover .lb__record-icon,
.lb__record:focus .lb__record-icon,
.lb__record:active .lb__record-icon {
  color: #fff;
}
.key--details-record {
  min-height: 10px;
}
.comment--group textarea.form-control {
  min-height: 116px;
  height: auto;
}
.checkbox--set-dark {
  color: #1C1F20;
}
.upload__media {
  font-size: 14px;
}
.upload__media:before {
  width: 98px;
}
.upload__media .upload__button-icon {
  font-size: 14px;
}
.segment__option:not([href]),
.segment__option {
  width: 18px;
  height: 18px;
  color: #6A7074;
  font-size: 8px;
  border: 1px solid #222222;
  display: flex;
  align-items: center;
  justify-content: center;
}
.segment__option:not([href]):hover,
.segment__option:not([href]):focus,
.segment__option:not([href]):active,
.segment__option:hover,
.segment__option:focus,
.segment__option:active {
  color: #fff;
  background-color: #222222;
}
.voiceRecord--block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.voiceRecord--box {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding-right: 16px;
  width: 33.33%;
}
.record__voice:not([href]),
.record__voice {
  color: #0638E7;
  font-size: 20px;
  width: 48px;
  height: 48px;
  min-width: 48px;
  border: 1px solid #0638E7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}
.record__voice .icon-play {
  margin-left: 4px;
}
.record__voice:not([href]):hover,
.record__voice:not([href]):focus,
.record__voice:not([href]):active,
.record__voice:hover,
.record__voice:focus,
.record__voice:active {
  color: #fff;
  background-color: #0638E7;
}
.record__time {
  color: #6A7074;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 16px;
  min-width: 40px;
  display: inline-block;
}
.record__trash {
  color: #6A7074;
  font-size: 16px;
  display: inline-flex;
}
.record__trash:hover,
.record__trash:focus {
  color: #6A7074;
}
.comment--attach {
  padding-top: 28px;
  border-top: 1px solid #BCBAB8;
  display: flex;
}
.voice--info {
  min-width: 100px;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
}
.voice--info .voiceInfo__name {
  color: #1C1F20;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}
.voice--info .voiceInfo__time {
  color: #0638E7;
  font-size: 14px;
}
.ca--group {
  border: 1px solid #BCBAB8;
  padding: 13px;
  min-height: 84px;
}
.ca--group .ca__para {
  color: #222222;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}
.ca--group-main .comment--group textarea.form-control {
  min-height: 84px;
  height: auto;
}
.ca--cover {
  padding: 4px;
  height: 48px;
  border: 1px solid #BCBAB8;
  display: flex;
  align-items: center;
}
.ca--cover .ca--cover-img {
  height: 38px;
  min-width: 50%;
  width: 50%;
  margin-right: 10px;
}
.ca--cover .ca--cover-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ca--cover .ca--cover-name {
  color: #6A7074;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-word;
}
.ca--cover-sm {
  height: 40px;
}
.ca--cover-sm .ca--cover-img {
  height: 30px;
}
.ca--cover-sm .ca--cover-name {
  font-size: 11px;
  line-height: 14px;
}
.ca__btn-sm:not([href]),
.ca__btn-sm {
  min-height: 40px;
  min-width: 50px;
  padding: 8px;
  line-height: 22px;
  width: 100%;
}
.ca__btn.disabled {
  background-color: transparent;
  border: 1px solid #BCBAB8;
  color: #BCBAB8;
}
.ca--cover,
.ca__btn-canvas {
  width: 50%;
  position: relative;
}
.row--space-5 {
  margin-left: -5px;
  margin-right: -5px;
}
.row--space-5 .col-12,
.row--space-5 .col {
  padding-left: 5px;
  padding-right: 5px;
}
.ca--cover .cover__cross {
  position: absolute;
  top: -7px;
  right: -8px;
  font-size: 15px;
  cursor: pointer;
  line-height: 16px;
  z-index: 5;
}





/*
  Record Voice Over
*/













/*
  Live Tour Style
*/


.liveTour--page {
  display: flex;
  flex-direction: column;
  background-color: #1C1F20;
}
.liveTour--page .container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}
.liveTour--wide {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.liveTour--top {
  box-shadow: 0px 4px 16px rgba(1, 2, 19, 0.18);
  border-radius: 0px 0px 20px 20px;
  overflow: hidden;
  background-color: #1C1F20;
  position: relative;
  z-index: 555;
  flex: 1 1;
  width: 100%;
}
.liveTour--video video {
  border: none;
  border-radius: 0px 0px 20px 20px;
}
.img--cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.liveTour--content {
  background-color: #1C1F20;
}
.liveTour--content-broker {
  position: relative;
  min-height: 212px;
}
.users--content {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 10px;
}
.users--row {
  display: flex;
  align-items: center;
  position: relative;
  min-width: 114px;
}
.users--row::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.users--row::-webkit-scrollbar-thumb {
  background-color: #888;
}
.users--row::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
}
.video__control:not([href]),
.video__control {
  width: 20px;
  height: 20px;
  min-width: 20px;
  font-size: 10px;
  color: #1C1F20;
  background-color: #BCBAB8;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video__control:not([href]):hover,
.video__control:not([href]):focus,
.video__control:hover,
.video__control:focus {
  color: #1C1F20;
  background-color: #fff;
}
.users--row .users--row-inner {
  display: flex;
  align-items: center;
}
.users--block-md {
  max-width: 104px;
}
.users--block {
  text-align: center;
  margin-top: 4px;
  margin-bottom: 2px;
  padding: 6px;
  margin-left: 8px;
  margin-right: 8px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  direction: ltr;
}
.users--block .users--box {
  border: 2px solid transparent;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 11px rgba(1, 2, 19, 0.5);
  margin-left: auto;
  margin-right: auto;
}
.users--block-md .users--box {
  width: 94px;
  height: 94px;
  min-width: 94px;
}
.users--block .users--box.active {
  border-color: #FFFBEE;
  box-shadow: 0px 0px 5px #FFF9E9;
}
.users--row-left .users--block-sm {
  margin-right: 14px;
}
.users--row-right .users--block-sm {
  margin-left: 14px;
}
.users--block-sm .users--box {
  width: 76px;
  height: 76px;
  min-width: 76px;
}
.users--block .users--alt {
  background-color: #4C5052;
}
.users--alt-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2px 4px;
}
.users--alt .usersAlt__heading {
  font-size: 24px;
  color: #1C1F20;
  text-transform: uppercase;
  line-height: 28px;
  margin-bottom: 4px;
  font-weight: normal;
}
.users--alt .usersAlt__subHeading {
  font-size: 11px;
  color: #1C1F20;
  line-height: 13px;
  margin-bottom: 0px;
  white-space: normal;
  word-break: break-word;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.users--ov {
  border-radius: 10px;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(58,57,55,0.8);
  position: absolute;
  left: 0;
  right: 0;
  top: -8px;
  z-index: 5;
  margin-left: auto;
  margin-right: auto;
}
.users--ov .users--ov-name {
  color: #fff;
  font-size: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.users--ov .users--ov-icon {
  color: #fff;
  font-size: 11px;
  line-height: 12px;
  margin-right: 4px;
  min-width: 14px;
}
.users--block .users__name {
  color: #fff;
  font-size: 11px;
  line-height: 16px;
  display: inline-block;
  margin-top: 10px;
  white-space: normal;
}
.users--block .users__voice {
  color: #fff;
  font-size: 11px;
  border-radius: 18px;
  width: 24px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(58,57,55,0.8);
  position: absolute;
  top: -27px;
  left: 0;
  right: 0;
  margin: auto;
}
.users--block .users__voice:hover,
.users--block .users__voice:focus {
  color: #fff;
  background-color: rgba(58,57,55,1);
}
.liveTour__list {
  display: flex;
  align-items: center;
}
.liveTour__list::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.liveTour__list li a {
  margin-left: 15px;
  margin-right: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 58px;
  min-height: 55px;
  padding: 12px 0;
  border-bottom: 4px solid transparent;
}
.liveTour__list li:first-child a {
  margin-left: 0;
}
.liveTour__list li:last-child a {
  margin-right: 0;
}
.lt__list-box .lt__list-text {
  color: #BCBAB8;
  font-size: 14px;
  display: inline-flex;
  line-height: 20px;
  margin-left: 10px;
  white-space: nowrap;
}
.lt__list-box .lt__list-icon {
  color: #BCBAB8;
  font-size: 16px;
  display: inline-flex;
  line-height: 20px;
}
.lt__list-box.link__active,
.lt__list-box.active {
  border-bottom-color: #fff;
}
.lt__list-box.link__active .lt__list-icon,
.lt__list-box:hover .lt__list-text,
.lt__list-box:hover .lt__list-icon,
.lt__list-box:focus .lt__list-text,
.lt__list-box:focus .lt__list-icon,
.lt__list-box.active .lt__list-text,
.lt__list-box.active .lt__list-icon {
  color: #fff;
}
.tour__btn {
  font-size: 12px;
  line-height: 16px;
  padding: 4px 10px;
  min-width: 90px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: rgba(255,255,255,0.5);
  border: 1px solid rgba(255,255,255,0.5);
  background-color: transparent;
  margin-left: 6px;
  white-space: nowrap;
}
.tour__btn:not(:disabled):not(.disabled).active,
.tour__btn:not(:disabled):not(.disabled):active:focus,
.tour__btn:not(:disabled):not(.disabled):active,
.tour__btn:hover,
.tour__btn:focus,
.tour__btn.active {
  color: #fff;
  border-color: #fff;
  background-color: transparent;
  outline: none;
  box-shadow: none;
}
.tour--btn-canvas {
  max-width: 384px;
  overflow-x: auto;
  align-items: center;
  display: none;
}
.tour__btn .tour__btn-desktop {
  display: none;
}
.lt__list-box .active--content {
  display: none;
}
.lt__list-box.active .active--content {
  display: flex;
}
.tour--btn-canvas::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.lt__list-box.link__active .tour--btn-canvas {
  display: flex;
}
.liveTour--content-tenant .liveTour--options {
  justify-content: center;
  min-width: 200px;
}
.liveTour--options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;
  position: relative;
}
.liveTour--options li a {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 14px;
  margin-right: 14px;
}
.liveTour--options li:first-child a {
  margin-left: 0;
}
.liveTour--options li:last-child a {
  margin-right: 0;
}
.liveTourOpt__box .liveTourOpt__icon {
  font-size: 16px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.liveTourOpt__box .liveTourOpt__icon .icon-share-screen {
  font-size: 18px;
  font-weight: 600;
}
.liveTourOpt__box-gray .liveTourOpt__icon {
  color: #161D28;
  background-color: #D5D9E0;
}
.liveTourOpt__box-red .liveTourOpt__icon {
  color: #fff;
  background-color: #E7062E;
}
.liveTourOpt__box-blue .liveTourOpt__icon {
  color: #fff;
  background-color: #0638E7;
}
.liveTourOpt__box-gray:hover .liveTourOpt__icon,
.liveTourOpt__box-gray:focus .liveTourOpt__icon {
  background-color: #aaabad;
}
.liveTourOpt__box-red:hover .liveTourOpt__icon,
.liveTourOpt__box-red:focus .liveTourOpt__icon {
  background-color: #bd0727;
}
.liveTourOpt__box-blue:hover .liveTourOpt__icon,
.liveTourOpt__box-blue:focus .liveTourOpt__icon {
  background-color: #0f36bf;
}
.liveTourOpt__box .liveTourOpt__text {
  font-size: 10px;
  color: #fff;
  margin-top: 8px;
  white-space: nowrap;
}
.liveTour--mobile {
  background-color: #fff;
  padding-bottom: 24px;
  margin-bottom: 0;
}
.liveTour--mobile .tourMobile-canvas {
  width: 100%;
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.tourMobile--block {
  border-bottom: 1px solid #BCBAB8;
  padding: 40px 0;
  margin-bottom: 0;
}
.tourMobile--main .tourMobile--block:last-child {
  border-bottom: none;
}
.tourMobile--block .tf__icon {
  min-width: 38px;
  font-size: 34px;
  line-height: 34px;
}
.tourMobile--block .form__para {
  font-size: 14px;
}
.liveTour--placement {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
}
.placement--centered {
  display: flex;
  align-items: center;
}
.placement--centered-h {
  justify-content: center;
}
.placement--block {
  padding: 34px 44px;
  border-radius: 28px;
  background-color: #fff;
  display: inline-block;
  width: 100%;
  max-width: 420px;
}
.placement__logo {
  margin-bottom: 24px;
}
.placement__heading {
  color: #1C1F20;
  font-size: 50px;
  line-height: 64px;
  font-weight: normal;
  margin-bottom: 26px;
}
.placement--location {
  display: flex;
  align-items: center;
}
.placement--location .placementLocation__icon {
  font-size: 22px;
  color: #6A7074;
  display: inline-flex;
  margin-right: 8px;
}
.placement--location .placementLocation__text {
  font-size: 16px;
  color: #6A7074;
  display: inline-block;
  word-break: break-word;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.users--content-tenant {
  padding-bottom: 0;
}
.liveTour--card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  text-align: center;
  z-index: 5;
  border: none;
  transition: height .35s ease;
}
.liveTour--card .card-header,
.liveTour--card .card-body {
  padding: 0;
  background-color: transparent;
  border: none;
}
.liveTour--card .card-body {
  margin-top: 10px;
}
.liveTour--card .card-header .btn-link {
  padding: 4px;
  line-height: 10px;
  color: rgba(255,255,2550,.3);
  font-size: 9px;
  border: none;
}
.liveTour--card .card-header .btn-link:hover,
.liveTour--card .card-header .btn-link:focus {
  color: rgba(255,255,2550,.3);
  text-decoration: none;
}
.users--content-tenant .users--row {
  padding-top: 26px;
}
.users--broker {
  position: absolute;
  right: 40px;
  top: 40px;
  margin: 0;
  padding: 0;
}
.users--block-lg {
  max-width: 202px;
  width: 100%;
}
.users--block-lg .users--box {
  width: 190px;
  height: 190px;
  min-width: 190px;
}
.users--broker .users--ov {
  top: auto;
  top: initial;
  bottom: -34px;
  max-width: 170px;
}
.row--feature {
  margin-right: -8px;
  margin-left: -8px;
  display: flex;
  justify-content: center;
}
.row--feature .col--feature {
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  max-width: 50%;
}
.feature--block {
  padding: 32px 15px;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
  min-height: 230px;
}
.feature--block .featureBlock__icon {
  font-size: 34px;
  color: #0638E7;
  display: inline-block;
  margin-bottom: 18px;
}
.feature--block .featureBlock__heading {
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 0;
  word-break: break-word;
}
.feature--block-address h4.featureBlock__heading {
  margin-top: 24px;
  width: 100%;
}
.featureBlock--sf {
  margin-top: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.featureBlock--sf .sf__digit {
  font-size: 36px;
  color: #222222;
  font-weight: normal;
  white-space: nowrap;
}
.featureBlock--sf .sf__word {
  font-size: 20px;
  margin-left: 4px;
}
.liveTour--custom.liveTour--gallery {
  background-color: #050505;
}
.liveTour--custom,
.liveTour--placement {
  padding-top: 25px;
  padding-bottom: 25px;
}
.liveTour--custom .liveTour--container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.liveTour--head {
  align-items: center;
  justify-content: space-between;
  display: flex;
}
.liveTour__heading {
  font-size: 12px;
  margin-bottom: 0;
  font-weight: normal;
  text-align: right;
}
.liveTour--gallery .liveTour--head {
  margin-bottom: 32px;
  display: none;
}
.liveTour--gallery-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 4px;
}
.liveTour--gallery-content::-webkit-scrollbar {
  width: 4px;
  border-radius: 10px;
}
.liveTour--gallery-content::-webkit-scrollbar-thumb {
  background: rgba(196,196,196,0.4);
}
.liveTour--gallery-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1 1;
  max-height: 100%;
  margin-left: -9px;
  margin-right: -9px;
}
.liveTour--gallery-row .liveTour--gallery-col {
  padding-left: 9px;
  padding-right: 9px;
  width: 25%;
}
.liveTour--gallery-img {
  width: 100%;
  height: 128px;
  margin-bottom: 18px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.gallery__btn-prev,
.gallery__btn-next {
  justify-content: space-between;
}
.gallery__btn .gallery__btn-icon {
  font-size: 9px;
  line-height: 8px;
}
.liveTour--gallery .gallery--large-canvas {
  position: absolute;
  left: 0;
  right: 0;
  top: -25px;
  bottom: -25px;
  height: auto;
}
.liveTour--gallery-large,
.liveTour--gallery-expanded {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background-color: #050505;
  display: flex;
  align-items: center;
  justify-content: center;
}
.liveTour--gallery-large img {
  object-fit: contain;
}
.liveTour--gallery-expanded img {
  object-fit: cover;
}
.gallery__cross {
  font-size: 16px;
  color: #fff;
  position: absolute;
  right: -70px;
  top: 10px;
  z-index: 5;
  display: inline-flex;
  cursor: pointer;
  padding: 4px;
}
.liveTour--building-top .dashboard__heading {
  color: #0638E7;
  font-size: 14px;
  margin-bottom: 0;
}
.liveTour__logo {
  min-width: 88px;
  width: 88px;
  height: 23px;
}
.specs__list li {
  color: #BCBAB8;
  font-size: 12px;
  position: relative;
  padding-left: 10px;
  margin-left: 10px;
  line-height: 14px;
}
.specs__list li:before {
  content: '';
  width: 1px;
  background-color: #BCBAB8;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
.building--widget {
  padding: 6px 9px 7px 9px;
  min-height: 36px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #F7F7F7;
}
.building--widget .bw__icon {
  color: #222222;
  font-size: 13px;
  margin-right: 5px;
  min-width: 14px;
  display: inline-block;
  margin-top: 4px;
}
.building--widget .bw__heading {
  color: #222222;
  font-size: 9px;
  margin-bottom: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.building--widget .bw__para {
  color: #6A7074;
  font-size: 7px;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: initial;
  word-break: break-word;
}
.building--widget .bw__arrow {
  font-size: 4px;
  color: #6A7074;
  padding: 3px 2px;
  line-height: 10px;
  display: inline-block;
  margin-top: 4px;
}
.building--widget .bw__detail {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.liveTour--building-top,
.liveTour--building-main {
  margin-top: 16px;
}
.liveTour--building {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.liveTour--building::-webkit-scrollbar {
  width: 0;
}
.certification--widget .bw__heading {
  line-height: 11px;
  margin-bottom: 0;
}
.certification--widget .bw__icon {
  width: 22px;
  height: 22px;
  min-width: 22px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}
/* .liveTour--details .liveTour--container {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 980px;
} */
.amenities--widget {
  justify-content: center;
  text-align: center;
  align-items: center;
}
.amenities--widget .bw__heading {
  white-space: normal;
  overflow: visible;
  overflow: initial;
  text-align: center;
  line-height: 12px;
  margin-bottom: 0;
  font-size: 9px;
}
.liveTour__logo {
  margin-right: 14px;
}
/* .liveTour--listing .container--fixed {
  height: 100%;
  overflow: hidden;
} */
.liveTour--listing {
  background-color: #FCFCFC;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 55;
}
.liveTour--listing .container-fluid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}
.ls--right {
  width: 100%;
}
.ls--top {
  margin: 10px 0px 11px 0px;
  min-height: 42px;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 24px;
}
.ls--head {
  display: flex;
  align-items: center;
}
.ls--head .ls__icon {
  font-size: 24px;
  margin-right: 12px;
  display: inline-flex;
}
.ls--head .ls__heading {
  font-size: 24px;
  color: #1C1F20;
  font-weight: normal ;
  margin-bottom: 0;
}
.ls--sort {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.ls__label {
  font-size: 16px;
  color: #222222;
  font-weight: 500;
  margin-bottom: 0;
  margin-right: 10px;
  min-width: 70px;
}
.ls__list {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.ls__list li a {
  font-size: 14px;
  color: #BCBAB8;
  margin-right: 14px;
  display: inline-block;
  white-space: nowrap;
}
.ls__list li a:hover,
.ls__list li a:focus,
.ls__list li a.active {
  color: #0638E7;
  text-decoration: underline;
}
.ls__list::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.ls__list::-webkit-scrollbar-thumb {
  background: #BCBAB8;
  border-radius: 4px;
}
.ls--bottom {
  display: flex;
  align-items: center;
  min-height: 140px;
  position: relative;
}
/* .ls--bottom:after {
  content: '';
  background: linear-gradient(95.75deg, #1C1F20 2.38%, rgba(28, 31, 32, 0) 86.57%);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
  width: 50px;
  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
} */
.ls--opt {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 10px;
}
.ls--opt .search__cross {
  position: absolute;
  right: -8px;
  top: 0;
  bottom: 0;
  font-size: 18px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}
.ls__search-icon {
  font-size: 30px;
  color: #0638E7;
  min-width: 30px;
  display: inline-flex;
  margin-right: 32px;
  cursor: pointer;
}
.ls--opt .ls__search-result {
  font-size: 16px;
  color: #6A7074;
  font-weight: 500;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.ls--listings {
  display: flex;
  padding-bottom: 8px;
  overflow-x: auto;
  overflow-y: hidden;
  cursor: move;
}
.ls--listings::-webkit-scrollbar {
  width: 10px;
  height: 26px;
}
.ls--listings::-webkit-scrollbar-thumb {
  background: rgba(196,196,196,0.3);
  border-radius: 20px;
}
.ls--listings::-webkit-scrollbar-thumb:hover {
  background: #C4C4C4;
}
.ls--row {
  margin-left: -9px;
  margin-right: -9px;
  flex-wrap: nowrap;
}
.ls--row .ls--col {
  padding-left: 9px;
  padding-right: 9px;
}
.ls--box {
  border: 1px solid rgba(188, 186, 184, 0.5);
  box-sizing: border-box;
  border-radius: 10px;
  min-height: 106px;
  background-color: #fff;
  display: flex;
  cursor: pointer;
}
.ls--box .ls--box-img {
  width: 78px;
  min-width: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  margin-top: -1px;
  margin-left: -1px;
  margin-bottom: -1px;
}
.ls--box .ls--box-detail {
  padding: 12px;
  overflow: hidden;
  max-width: 236px;
  min-width: 236px;
}
.ls--box .ls--box-detail .ls__listing-heading {
  font-size: 14px;
  color: #222222;
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 12px;
}
.ls--box .al--listing .al__icon {
  font-size: 14px;
  display: inline-flex;
  margin-right: 8px;
}
.ls--box .al--listing .al__text {
  font-size: 12px;
  min-width: 10px;
  margin-right: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ls--box .al--listing-combine .al--listing .al__text {
  font-size: 16px;
}
.ls--box .al--listing {
  margin-bottom: 10px;
}
.ls--box .al--listing-combine .al--listing {
  margin-bottom: 0;
  width: 50%;
}
.ls__cross:not([href]),
.ls__cross {
  font-size: 16px;
  color: #222222;
  display: inline-flex;
  cursor: pointer;
}
.ls--top .ls__cross {
  position: absolute;
  right: 0;
  top: 14px;
}
.search__block {
  display: flex;
  margin-bottom: 14px;
}
.listing--group {
  position: relative;
  margin-bottom: 10px;
}
.listing--group .form-control {
  border: 1px solid #6A7074;
  border-radius: 11px;
  opacity: 0.5;
  color: #6A7074;
  font-size: 18px;
  font-weight: normal;
  padding: 8px 80px 8px 20px;
  height: calc(1.89em + .75rem + 2px);
  min-height: 48px;
}
.listing--group .enter__btn {
  position: absolute;
  right: 10px;
  top: 11px;
  background-color: #6A7074;
  border-radius: 4px;
  opacity: 0.2;
  font-size: 14px;
  color: #FCFCFC;
  padding: 3px 8px;
  min-width: 66px;
  min-height: 26px;
  border: none;
}
.listing--group .form-control:focus ~ .enter__btn {
  opacity: 1;
  background-color: #0638E7;
  color: #fff;
}
.listing--group .form-control:focus {
  opacity: 1;
}
.cancel__link:not([href]),
.cancel__link {
  color: #0638E7;
  font-size: 14px;
  font-weight: normal;
  text-decoration: underline;
}
.cancel__link:not([href]):hover,
.cancel__link:not([href]):hover,
.cancel__link:hover,
.cancel__link:focus {
  color: #0638E7;
  text-decoration: none;
}
.search__block .ls__search-icon {
  margin-top: 9px;
}



/*
  Live Tour Style
*/









/*
  Virtual Tour Share Style
*/




.vt--share {
  display: flex;
  justify-content: center;
}
.vt--share.share--realistic {
  align-items: flex-start;
}
.share--content {
  padding: 70px 0;
}
.share--middle {
  margin-left: auto;
  margin-right: auto;
}
.share__img {
  margin-left: auto;
  margin-right: auto;
  background: url('/images/share.svg') bottom center no-repeat;
  background-size: cover;
  pointer-events: none;
  text-indent: -999px;
  display: inline-block;
  width: 148px;
  height: 173px;
  margin-bottom: 40px;
  overflow: hidden;
}
.share--options {
  position: relative;
  width: 100%;
  max-width: 238px;
  display: flex;
  flex-direction: column;
  margin-top: 34px;
}
.share--options:before {
  content: '';
  background: url('/images/lines.svg') left top no-repeat;
  position: absolute;
  left: -16px;
  top: -26px;
  display: inline-block;
  width: 40px;
  height: 330px;
  pointer-events: none;
}
.share--options .share--row:nth-of-type(1) {
  min-height: 144px;
}
.share--options .share--row:nth-of-type(2) {
  min-height: 164px;
}
.share--row {
  display: flex;
  position: relative;
}
.share--row .share--detail {
  padding-left: 12px;
  padding-top: 6px;
}
.share--row .share__heading {
  font-size: 16px;
  color: #222222;
  font-weight: normal;
  line-height: 22px;
  margin-bottom: 4px;
}
.share--row .share__paragraph {
  font-size: 12px;
  color: #BCBAB8;
}
.digit__box {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0638E7;
}



/*
  Virtual Tour Share Style
*/













/*
  Notfound Link Expired Style
*/


.sec--vertical {
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.notfound--content {
  padding: 40px 0;
}
.notfound__img {
  margin-left: auto;
  margin-right: auto;
  background: url('/images/404.svg') bottom center no-repeat;
  background-size: cover;
  pointer-events: none;
  text-indent: -999px;
  display: inline-block;
  width: 368px;
  height: 260px;
  margin-bottom: 60px;
  overflow: hidden;
}
.linkEx__img {
  margin-left: auto;
  margin-right: auto;
  background: url('/images/link-expired.svg') bottom center no-repeat;
  background-size: cover;
  pointer-events: none;
  text-indent: -999px;
  display: inline-block;
  width: 168px;
  height: 175px;
  margin-bottom: 40px;
  overflow: hidden;
}
.linkEx--content {
  padding-top: 40px;
  padding-bottom: 40px;
}
.linkEx--content-tenant {
  padding-bottom: 20px;
}
.md--canvas {
  width: 100%;
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
}
.sec--vertical-permanent {
  min-height: 100vh;
}
.sec--vertical-permanent .sec--vertical-middle {
  min-height: calc(100vh - 65px);
  display: flex;
  align-items: center;
}




/*
  Notfound Link Expired Style
*/















/*
  Right Sidebar Style
*/






.drawer--right {
  width: 428px;
  padding: 90px 68px 20px 68px;
  background: linear-gradient(89.65deg, #173CDF 18.24%, #3273E9 91.95%);
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 555;
  min-height: 100vh;
  overflow-y: auto;
  margin-right: -428px;
  transition: all .2s ease;
}
.drawer--right-show {
  margin-right: 0;
}
.drawer--right::-webkit-scrollbar {
  width: 6px;
}
.drawer--right::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.2);
  border-radius: 20px;
}
.drawer__cross {
  color: #fff;
  font-size: 18px;
  padding: 0;
  border: none;
  background-color: transparent;
  position: absolute;
  right: 30px;
  top: 30px;
}
.drawer__cross:not(:disabled):not(.disabled):active,
.drawer__cross:hover,
.drawer__cross:focus,
.drawer__cross:active {
  color: #fff;
  background-color: transparent;
  border: none;
}
.drawer--card {
  border: none;
  background-color: transparent;
}
.accordian--first {
  border-top: 1px solid #fff;
}
.drawer--card .card-header {
  padding: 0;
  border-bottom: 1px solid #fff;
  background-color: transparent;
  position: relative;
  margin-bottom: 0;
}
.accordion>.drawer--card>.card-header {
  margin-bottom: 0;
}
.card--icon>.card-header:after {
  content: '';
  background: url('/images/checvon-bottom.svg') right center no-repeat;
  display: inline-block;
  background-size: 14px;
  width: 14px;
  height: 14px;
  position: absolute;
  right: 0;
  top: 14px;
  transition: transform .3s ease;
  pointer-events: none;
}
.card--active-first>.card-header:after,
.card--active-second>.card-header:after,
.card--active-third>.card-header:after {
  background: url('/images/checvon-top.svg') right center no-repeat;
  display: inline-block;
  background-size: 14px;
  width: 14px;
  height: 14px;
  transition: transform .3s ease;
  pointer-events: none;
}
.drawer--card .card-header .btn-link {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 18px 8px 0px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 24px;
  text-align: left;
}
.drawer--card .card-body {
  padding: 0;
}
.drawer--inner {
  padding-left: 15px;
}




/*
  Right Sidebar Style
*/





/*
  Buildings New Style
*/



.dashboard--content-new {
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 100vh;
}
.dashboard--content-new .dashboard--off {
  min-height: calc(100vh - 40px);
}
.dashboard--content-new .dashboard--canvas {
  min-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
}
.dashboard--off {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  max-width: 1420px;
  margin-left: auto;
  margin-right: auto;
}
.bg--gray {
  background-color: #f5f5f5;
}
.white--rounded-box {
  background-color: #fff;
  border-radius: 20px;
  padding: 32px 44px;
}
.dashboard--bar {
  min-height: 88px;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-shrink: 0;
}
.dashbaord--opt {
  display: flex;
  align-items: center;
}
.form--group-alt {
  position: relative;
}
.form--group-alt .form-control {
  color: #1C1F20;
  font-size: 16px;
  background-color: #F5F5F5;
  border-color: #F5F5F5;
  border-radius: 10px;
  padding: 10px 13px;
  height: auto;
  min-height: 48px;
  line-height: 19px;
}
.form--group-alt .search__icon {
  color: #6A7074;
  font-size: 16px;
  position: absolute;
  left: 16px;
  top: 16px;
  pointer-events: none;
  display: inline-flex;
}
.form--group-alt .form-control:focus {
  border-color: #0638E7;
}
.form--group-alt .form-control::-webkit-input-placeholder {
  color: #1C1F20;
}
.form--group-alt .form-control::-ms-input-placeholder {
  color: #1C1F20;
}
.form--group-alt .form-control::placeholder {
  color: #1C1F20;
}
.search--group-alt .form-control {
  padding-left: 44px;
}
.button__radius:not([href]),
.button__radius {
  border-radius: 10px;
}
.dashbaord--opt .admin__button {
  margin-left: 20px;
}
.dropdown--radius .dropdown-toggle,
.select-radius .form-control {
  border-radius: 10px;
}
.dropdown--radius .dropdown-toggle .dropdown-menu {
  border-radius: 10px;
}
.dashbord__heading-md {
  color: #222222;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}
.al--sm-alt,
.al--lg-alt,
.al--block-alt {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #BCBAB8;
  cursor: pointer;
  position: relative;
}
.al--sm-alt:hover,
.al--lg-alt:hover,
.al--block-alt:hover {
  border-color: #0638E7;
}
.al--sm-alt .al--block-img,
.al--lg-alt .al--block-img,
.al--block-alt .al--block-img {
  width: 226px;
  height: 128px;
  min-width: 226px;
  border-radius: 10px;
}
.al--sm-alt .al--block-detail,
.al--lg-alt .al--block-detail,
.al--block-alt .al--block-detail {
  border: none;
  padding: 0px 0px 0px 20px;
  margin-left: 0;
}
.al--sm-alt .al__address,
.al--lg-alt .al__address,
.al--block-alt .al__address {
  cursor: pointer;
}
.al--sm-alt .al--adress-component,
.al--lg-alt .al--adress-component,
.al--block-alt .al--adress-component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.al--sm-alt .al__address,
.al--lg-alt .al__address,
.al--block-alt .al__address {
  line-height: 26px;
}
.al--block-alt .buildings__next {
  font-size: 18px;
  color: #6A7074;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 20px;
  margin-left: 15px;
  margin-right: 8px;
}
.building__btn:not([href]),
.building__btn {
  font-size: 14px;
  font-weight: normal;
  padding: 2px 4px;
  min-width: 90px;
  min-height: 24px;
  border-radius: 12px;
  text-align: center;
  margin: 5px 0px;
}
.building__btn-draft:not([href]),
.building__btn-draft {
  color: #6A7074;
  background-color: rgba(188, 186, 184, 0.2);
}
.building__btn-review:not([href]),
.building__btn-review {
  color: #FF8D39;
  background-color: rgba(255, 141, 57, 0.2);
}
.building__btn-published:not([href]),
.building__btn-published {
  color: #fff;
  background-color: rgba(34, 34, 34, 0.8);
}
.building__btn-draft:not([href]):hover,
.building__btn-draft:hover {
  color: #fff;
  background-color: #BCBAB8;
}
.building__btn-review:not([href]):hover,
.building__btn-review:hover {
  color: #fff;
  background-color: #FF8D39;
}
.building__btn-published:not([href]):hover,
.building__btn-published:hover {
  color: #fff;
  background-color: #222222;
}
.al--block-alt .al--btn-component {
  flex-direction: column;
  justify-content: center;
}
.al--sm-alt .al--listings-component,
.al--lg-alt .al--listings-component,
.al--block-alt .al--listings-component {
  display: flex;
  align-items: center;
}




/*
  Buildings New Style
*/

















/*
  Dashbaord New Style
*/



.mb-20 {
  margin-bottom: 20px;
}
.para__lg {
  font-size: 18px;
  color: #6A7074;
  font-weight: normal;
}
.para__lg-dark {
  color: #222222;
}
.dashboard__btn:not(:disabled),
.dashboard__btn {
  color: #0638E7;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #0638E7;
  min-height: 70px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  padding: 12px;
}
.dashboard__btn .dashboard__btn-icon {
  display: inline-flex;
  margin-left: 10px;
  font-size: 20px;
}
.dashboard__btn .dashboard__btn-icon .icon-file {
  font-size: 22px;
}
.dashboard__btn:not([href]):not(:disabled):not(.disabled).active,
.dashboard__btn:not([href]):not(:disabled):not(.disabled):active:focus,
.dashboard__btn:not([href]):not(:disabled):not(.disabled):active,
.dashboard__btn:not([href]):hover,
.dashboard__btn:not([href]):focus,
.dashboard__btn:not([href]).active
.dashboard__btn:not(:disabled):not(.disabled).active,
.dashboard__btn:not(:disabled):not(.disabled):active:focus,
.dashboard__btn:not(:disabled):not(.disabled):active,
.dashboard__btn:hover,
.dashboard__btn:focus,
.dashboard__btn.active {
  color: #fff;
  border-color: #0638E7;
  background-color: #0638E7;
  outline: none;
  box-shadow: none;
}
.dashboard--card {
  padding: 18px 44px;
  border-radius: 20px;
  background-color: #fff;
  border: none;
  margin-bottom: 20px;
}
.accordion>.dashboard--card:not(:last-of-type) {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.accordion>.dashboard--card:not(:first-of-type) {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.dashboard--card>.card-header {
  background-color: transparent;
  border: none;
  padding: 10px 0px;
}
.dashboard--card>.card-header>.btn-link {
  width: 100%;
  padding: 0;
  text-align: left;
  color: #222222;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard--card>.card-header>.btn-link .icon-chevron-down-solid {
  color: #BCBAB8;
  font-size: 9px;
  margin-left: 10px;
}
.dashboard--card>.card-header>.btn-link .icon-chevron-right {
  color: #BCBAB8;
  font-size: 12px;
  margin-left: 10px;
}
.dashboard--card>.card-header>.btn-link:hover,
.dashboard--card>.card-header>.btn-link:focus {
  text-decoration: none;
  box-shadow: none;
}
.dashboard--card .card-body {
  padding: 0;
}
.upcomingTour--block {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #BCBAB8;
  display: flex;
  align-items: center;
  min-height: 140px;
  cursor: pointer;
}
.upcomingTour--block:hover {
  border-color: #0638E7;
}
.upcomingTour--block .upcomingTour--left {
  display: flex;
  flex-direction: column;
  min-width: 190px;
  max-width: 190px;
  padding-right: 10px;
  border-right: 1px solid #BCBAB8;
  margin-right: 30px;
}
.upcomingTour--block .upComTour__icon {
  font-size: 24px;
  color: #1C1F20;
  display: inline-flex;
  margin-bottom: 10px;
}
.upcomingTour--block .upComTour__name {
  font-size: 16px;
  color: #1C1F20;
  font-weight: normal;
  display: inline-flex;
  line-height: 28px;
}
.upcomingTour--block .upcomingTour--main {
  display: flex;
  align-items: center;
  flex: 1 1;
}
.upcomingTour--block .upcomingTour--right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
  margin-bottom: auto;
}
.upComTour__list {
  padding: 10px 10px 4px 0px;
}
.upComTour__list li {
  color: #6A7074;
  font-size: 14px;
  font-weight: normal;
  position: relative;
  padding-left: 26px;
  margin-bottom: 6px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
}
.upComTour__list li .upComTour__list-text {
  display: inline-block;
  width: 100%;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.upComTour__list li .upComTour__list-icon {
  position: absolute;
  left: 0;
  top: 1px;
  color: #6A7074;
  font-size: 16px;
  display: inline-flex;
}
.upComTour__list li:last-child {
  margin-bottom: 0;
}
.link__dark:not([href]),
.link__dark {
  font-size: 14px;
  font-weight: 600;
  color: #222222;
}
.link__dark:not([href]):hover,
.link__dark:not([href]):focus,
.link__dark:hover,
.link__dark:focus {
  color: #222222;
  text-decoration: underline;
}
.link__blue:not([href]),
.link__blue {
  font-size: 16px;
  color: #0638E7;
  font-weight: normal;
}
.link__blue:not([href]):hover,
.link__blue:not([href]):focus,
.link__blue:hover,
.link__blue:focus {
  color: #0638E7;
  text-decoration: underline;
}
.link__blue-sm:not([href]),
.link__blue-sm {
  font-size: 14px;
  color: #0638E7;
  font-weight: normal;
}
.link__blue-sm:not([href]):hover,
.link__blue-sm:not([href]):focus,
.link__blue-sm:hover,
.link__blue-sm:focus {
  color: #0638E7;
  text-decoration: underline;
}
.upComTour--btn-canvas {
  display: inline-flex;
  flex-direction: column;
}
.link__btn {
  font-size: 16px;
  font-weight: normal;
  min-height: 34px;
  border: 1px solid transparent;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  min-width: 200px;
  line-height: 24px;
}
.link__btn i {
  margin-left: 10px;
}
.link__btn-sm {
  min-width: 180px;
}
.link__btn-blue {
  color: #fff;
  background-color: #0638E7;
  border-color: #0638E7;
}
.link__btn-blue:not(:disabled):not(.disabled).active,
.link__btn-blue:not(:disabled):not(.disabled):active:focus,
.link__btn-blue:not(:disabled):not(.disabled):active,
.link__btn-blue:hover,
.link__btn-blue:focus,
.link__btn-blue.active {
  color: #fff;
  background-color: #0c30af;
  border-color: #0c30af;
  outline: none;
  box-shadow: none;
}
.link__btn-gray {
  color: #1c1f20;
  background-color: #fff;
  border-color: #1C1F20;
}
.link__btn-gray:not(:disabled):not(.disabled).active,
.link__btn-gray:not(:disabled):not(.disabled):active:focus,
.link__btn-gray:not(:disabled):not(.disabled):active,
.link__btn-gray:hover,
.link__btn-gray:focus,
.link__btn-gray.active {
  color: #fff;
  background-color: #1C1F20;
  border-color: #1C1F20;
  outline: none;
  box-shadow: none;
}
.link__btn-yellow {
  color: #fff;
  background-color: #FF8D39;
  border-color: #FF8D39;
}
.link__btn-yellow:not(:disabled):not(.disabled).active,
.link__btn-yellow:not(:disabled):not(.disabled):active:focus,
.link__btn-yellow:not(:disabled):not(.disabled):active,
.link__btn-yellow:hover,
.link__btn-yellow:focus,
.link__btn-yellow.active {
  color: #fff;
  background-color: #f37c25;
  border-color: #f37c25;
  outline: none;
  box-shadow: none;
}
.upComTour--btn-canvas .link__btn-blue {
  margin-bottom: 10px;
}
.upComTour--btn-canvas .link__btn-gray {
  margin-top: 10px;
}
.para__md-dark {
  font-size: 16px;
  color: #1C1F20;
}
.para__md-gray {
  font-size: 16px;
  color: #6A7074;
}
.para__sm-dark {
  font-size: 14px;
  color: #1C1F20;
}
.para__sm-gray {
  font-size: 14px;
  color: #6A7074;
}
.dashboard--notfound {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}
.dashboard--notfound .notfound__result-img {
  margin-left: auto;
  margin-right: auto;
  background: url(/images/upcoming-tour.svg) bottom center no-repeat;
  background-size: cover;
  pointer-events: none;
  text-indent: -999px;
  display: inline-block;
  width: 204px;
  height: 170px;
  margin-bottom: 40px;
  overflow: hidden;
}
.dashboard--notfound .notfound__listing-img {
  margin-left: auto;
  margin-right: auto;
  background: url(/images/listings-tour.svg) bottom center no-repeat;
  background-size: cover;
  pointer-events: none;
  text-indent: -999px;
  display: inline-block;
  width: 200px;
  height: 161px;
  margin-bottom: 40px;
  overflow: hidden;
}
.dashboard--newAccount {
  text-align: center;
  margin-top: 32px;
  margin-bottom: 16px;
}
.dashboard--sticky {
  overflow-y: hidden;
}
.dashboard--sticky .dashboard--canvas {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px);
}
.dashboard--sticky .dashbaord--sec {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
}
.dashboard--sticky .dashbaord--sec::-webkit-scrollbar {
  width: 4px;
}
.dashboard--sticky .dashbaord--sec::-webkit-scrollbar-thumb {
  background: #6A7074;
  border-radius: 10px;
}







/*
  Dashbaord New Style
*/








/*
  Create Tour Style
*/




.tour--canvas {
  width: 100%;
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}
.ct--head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.steps__text {
  color: #6A7074;
  font-size: 16px;
  font-weight: normal;
  white-space: nowrap;
}
.ct--radio-btn {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  padding: 12px 16px;
  margin-bottom: 20px;
  cursor: pointer;
  border: 1px solid #BCBAB8;
  border-radius: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 150px;
}
.ct--radio-btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.ct--radio-btn .checkmark {
  position: relative;
  display: inline-flex;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  min-width: 16px;
  border: 1px solid #6A7074;
  margin-right: 22px;
  border-radius: 30px;
}
.ct--radio-btn input:checked ~ .checkmark {
  background-color: #0638E7;
  border-color: #0638E7;
}
.ct--radio-btn .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.ct--radio-btn input:checked ~ .checkmark:after {
  display: block;
}
.ct--radio-btn .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}
.ct--radio-btn.active {
  border-color: #0638E7;
}
.ctRadio--content .ctRadio--head {
  display: flex;
  align-items: center;
}
.ctRadio--content .ctRadio__icon {
  color: #1C1F20;
  font-size: 17px;
  margin-right: 10px;
  display: inline-flex;
}
.ctRadio--content .para__md-dark,
.ctRadio--content .small__paragraph {
  word-break: break-word;
  white-space: normal;
}
.forms--group-radius .form-control {
  border-radius: 10px;
}
.outline__btn-gray:not([href]),
.outline__btn-gray {
  border: 1px solid #222222;
  color: #222222;
  font-size: 14px;
  font-weight: normal;
  background-color: transparent;
  padding: 10px 20px;
  min-height: 48px;
  border-radius: 0px;
  min-width: 200px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.outline__btn-gray-sm:not([href]),
.outline__btn-gray-sm {
  min-width: 160px;
}
.outline__btn-gray-md:not([href]),
.outline__btn-gray-md {
  min-width: 188px;
}
.outline__btn-gray:not([href]):not(:disabled):not(.disabled):active,
.outline__btn-gray:not([href]):not(:disabled):not(.disabled):active:focus,
.outline__btn-gray:not([href]):hover,
.outline__btn-gray:not([href]):focus,
.outline__btn-gray:not(:disabled):not(.disabled):active,
.outline__btn-gray:not(:disabled):not(.disabled):active:focus,
.outline__btn-gray:hover,
.outline__btn-gray:focus {
  background-color: #222222;
  color: #fff;
  border-color: #222222;
  box-shadow: none;
}
.outline__btn-gray.disabled {
  color: #BCBAB8 !important;
  border-color: #BCBAB8 !important;
}
.outline__btn-radius:not([href]),
.outline__btn-radius {
  border-radius: 10px;
}
.ct__btn-canvas {
  padding-top: 120px;
}
.listing--dropdown-update .dropdown-toggle {
  background-color: #fff;
  position: relative;
  z-index: 5;
}
.listing--dropdown-update .dropdown-menu {
  margin-top: -12px;
  padding-top: 22px;
  z-index: 4;
  border-radius: 0px 0px 10px 10px;
}
.listing--dropdown-update.show>.dropdown-toggle,
.listing--dropdown-update.show>.dropdown-toggle:focus,
.listing--dropdown-update .dropdown-toggle:not(:disabled):not(.disabled):active,
.listing--dropdown-update .dropdown-toggle:focus,
.listing--dropdown-update .dropdown-toggle:active {
  background-color: #fff;
}
.listing--dropdown-update .dropdown-item {
  border-radius: 0;
  border-bottom: 1px solid #BCBAB8;
  padding: 10px 4px;
}
.listing--dropdown-update .dropdown-item:hover,
.listing--dropdown-update .dropdown-item:focus,
.listing--dropdown-update .dropdown-item.active {
  border-radius: 4px;
}
.listing--dropdown-update .dropdown-menu .dropdown-item:last-child {
  border: none;
}



/*
  Create Tour Style
*/













/*
  Create Tour Listing Style
*/




.badge--row {
  margin-left: -10px;
  margin-right: -20px;
}
.badge--row .badge--col {
  padding-left: 10px;
  padding-right: 10px;
}
.tour__badge {
  background-color: #F5F5F5;
  border-radius: 12px;
  min-height: 26px;
  padding: 4px 10px;
  color: #1C1F20;
  font-size: 14px;
  line-height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.tour__badge .tour__badge-cross {
  cursor: pointer;
  color: #1C1F20;
  font-size: 10px;
  display: inline-flex;
  justify-content: flex-end;
  margin-left: 10px;
  padding: 3px 4px;
}
.tourListing--content {
  border-top: 1px solid rgba(188, 186, 184, 0.5);
  padding-top: 30px;
  margin-top: 10px;
}
.add__btn:not([href]),
.add__btn {
  font-size: 16px;
  line-height: 18px;
  padding: 5px;
  border-radius: 10px;
  min-width: 94px;
  min-height: 32px;
}
.add__btn-blue:not([href]),
.add__btn-blue {
  color: #fff;
  background-color: #0638E7;
  border: 1px solid #0638E7;
}
.add__btn-blue:not([href]):not(:disabled):not(.disabled):active:focus,
.add__btn-blue:not([href]):not(:disabled):not(.disabled):active,
.add__btn-blue:not([href]):hover,
.add__btn-blue:not([href]):focus,
.add__btn-blue:not(:disabled):not(.disabled):active:focus,
.add__btn-blue:not(:disabled):not(.disabled):active,
.add__btn-blue:hover,
.add__btn-blue:focus {
  color: #fff;
  background-color: #0c30af;
  box-shadow: none;
  outline: none;
  text-decoration: none;
}
.tourListing--block {
  display: flex;
  align-items: center;
  border: 1px solid #BCBAB8;
  border-radius: 10px;
  margin-bottom: 20px;
}
.tourListing--block .tourListing__img {
  width: 52px;
  height: 52px;
  min-width: 52px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tourListing--block .tourListing--row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
  padding: 8px 20px;
  overflow: hidden;
}
.tourListing--block .tourListing--row .para__md-dark {
  margin-right: auto;
  margin-bottom: 0px;
}
.tourListing--scroll .tourListing--block:last-child {
  margin-bottom: 0;
}
.tourListing--canvas {
  padding-top: 10px;
  position: relative;
}






/*
  Create Tour Listing Style
*/









/*
  Tour Edit Home & Gallery Style
*/




.ml-10 {
  margin-left: 10px;
}
.subDashboard--bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.breadcrumb--custom .breadcrumb {
  padding: 0;
  margin-bottom: 10px;
  background-color: transparent;
}
.breadcrumb--custom .breadcrumb-item {
  font-size: 14px;
  color: #BCBAB8;
}
.breadcrumb--custom .breadcrumb-item a {
  font-size: 14px;
  color: #6A7074;
}
.breadcrumb--custom .breadcrumb-item+.breadcrumb-item::before {
  color: #BCBAB8;
}
.preview__link i {
  font-size: 20px;
}
.tourEdit--top {
  height: 490px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}
.listing--dropdown-n .dropdown-toggle {
  padding-left: 44px;
}
.listing--dropdown-n .select__integrate {
  position: absolute;
  left: 16px;
  top: 14px;
  color: #1C1F20;
  font-size: 16px;
  pointer-events: none;
  display: inline-flex;
}
.tourEdit--listing-off {
  position: relative;
}
.tourEdit--listing-off.tourEdit--listing-shadow-effect:after {
  content: '';
  background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 78px;
  pointer-events: none;
  margin-bottom: 8px;
}
.tourEdit--listing-canvas {
  margin-top: 34px;
  display: flex;
  overflow-x: auto;
  padding-bottom: 12px;
  background-color: #fff;
}
.tourEdit--listing-canvas::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.tourEdit--listing-canvas::-webkit-scrollbar-thumb {
  background: #C4C4C4;
  border-radius: 5px;
}
.tourEdit--listing {
  border: 1px solid #BCBAB8;
  border-radius: 10px;
  display: inline-flex;
  margin-right: 20px;
  min-height: 104px;
}
.tourEdit--listing .tel--cursor {
  border-right: 1px solid #BCBAB8;
  min-width: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.tourEdit--listing .tel--cursor i {
  color: #BCBAB8;
  font-size: 16px;
}
.tourEdit--listing .tel--control {
  border-left: 1px solid #BCBAB8;
  min-width: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tourEdit--listing .tel--control i {
  color: #BCBAB8;
  font-size: 14px;
}
.tourEdit--listing .tel--inner {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-width: 244px;
}
.tourEdit--listing .tel--top {
  display: flex;
  align-items: center;
  padding: 4px 24px;
  border-bottom: 1px solid #BCBAB8;
  min-height: 30px;
  flex: 1 1;
}
.tourEdit--listing .tel--top .small__paragraph {
  line-height: 20px;
  padding-left: 22px;
  position: relative;
  display: flex;
  align-items: center;
}
.tourEdit--listing .tel--top .small__paragraph i {
  position: absolute;
  left: 0;
  top: 3px;
}
.tourEdit--listing .tel--bottom {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  transition: width 2s ease;
}
.tel--btns-row {
  display: flex;
  align-items: center;
  margin-left: -10px;
  margin-right: -10px;
}
.tel--btns-row .tel--btns-col {
  padding-left: 10px;
  padding-right: 10px;
}
.tel__btn:not([href]),
.tel__btn {
  color: #6A7074;
  font-size: 14px;
  min-height: 48px;
  min-width: 76px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid #BCBAB8;
  border-radius: 10px;
  white-space: nowrap;
}
.tel__btn .tel__btn-icon {
  font-size: 16px;
  display: inline-flex;
}
.tel__btn:not([href]):not(:disabled):not(.disabled):active:focus,
.tel__btn:not([href]):not(:disabled):not(.disabled):active,
.tel__btn:not([href]):hover,
.tel__btn:not([href]):focus,
.tel__btn:not([href]):active,
.tel__btn:not([href]).active,
.tel__btn:not(:disabled):not(.disabled).active,
.tel__btn:not(:disabled):not(.disabled):active:focus,
.tel__btn:not(:disabled):not(.disabled):active,
.tel__btn:hover,
.tel__btn:focus,
.tel__btn:active,
.tel__btn.active {
  color: #0638E7;
  border-color: #0638E7;
  background-color: transparent;
  box-shadow: none;
  outline: none;
}
.tourEdit--listing-minimize .tel--top {
  border-bottom: 0;
  padding-left: 14px;
  padding-right: 14px;
}
.tourEdit--listing-minimize .tel--bottom,
.tourEdit--listing-minimize .tel--top .small__paragraph .telTop__span em {
  display: none;
}
.tourEdit--listing-minimize .tel--top .small__paragraph {
  flex-direction: column;
  align-items: flex-start;
}
.tourEdit--listing-minimize .tel--control i {
  transform: rotate(180deg);
}
.team--block-alt {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.team--block-alt .teamBlock--img {
  margin: 0px 16px 0px 0px;
}
.team--block-alt .teamBlock__user {
  margin-bottom: 2px;
}
.preview__link:not([href]),
.preview__link {
  color: #0638E7;
  font-size: 16px;
}
.preview__link:not([href]):hover,
.preview__link:not([href]):focus,
.preview__link:hover,
.preview__link:focus {
  color: #0c30af;
  text-decoration: none;
}
.upload--gallery-img {
  color: #BCBAB8;
  font-size: 14px;
  border: 1px dashed #BCBAB8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2px;
}
.upload--gallery-img i {
  font-size: 16px;
  margin-bottom: 14px;
}
.upload--gallery-img input[type="file"] {
  display: none;
}
.tourEdit--top .liveTour--container {
  max-width: 800px;
}
.feature--block-alt {
  padding: 18px 15px;
  min-height: 236px;
}
.feature--block-alt .featureBlock__icon {
  font-size: 34px;
}
.feature--block-alt .featureBlock__heading {
  font-size: 20px;
}
.feature--block-alt .featureBlock--sf {
  margin-top: 22px;
  flex-wrap: wrap;
}
.feature--block-alt .featureBlock--sf .sf__digit {
  font-size: 36px;
  white-space: normal;
}
.feature--block-alt .featureBlock--sf .sf__word {
  font-size: 20px;
}
.tourEdit--specs .liveTour--building-main .row--space-5 {
  margin-left: -5px;
  margin-right: -5px;
}
.tourEdit--specs .liveTour--building-main .row--space-5 .col {
  padding-left: 5px;
  padding-right: 5px;
}
.component--edit-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 555;
  background-color: rgba(34, 34, 34, 0.7);
}
.component--edit {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  max-width: 570px;
  margin-left: auto;
  border-radius: 20px 0px 0px 20px;
  height: 100vh;
}
.component--edit .componentEdit--head {
  padding: 40px 70px 0px 70px;
  display: flex;
  flex-direction: column;
}
.component--edit .componentEdit--scrollable {
  padding: 24px 70px 40px 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.component--edit .componentEdit--scrollable::-webkit-scrollbar {
  width: 0px;
}
.badge--row-alt {
  margin-left: -5px;
  margin-right: -5px;
}
.badge--row-alt .badge--col {
  padding-left: 5px;
  padding-bottom: 5px;
}
.tour__badge-alt {
  margin-bottom: 10px;
}
.tourListing--canvas-alt {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.tourListing--canvas-alt .tourListing--scroll {
  max-height: none;
  max-height: initial;
}
.plus__btn {
  color: #fff;
  font-size: 12px;
  width: 32px;
  height: 32px;
  min-width: 32px;
  background-color: #0638E7;
  border: 1px solid #0638E7;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.plus__btn:not([href]):not(:disabled):not(.disabled):active:focus,
.plus__btn:not([href]):not(:disabled):not(.disabled):active,
.plus__btn:not([href]):hover,
.plus__btn:not([href]):focus,
.plus__btn:not(:disabled):not(.disabled):active:focus,
.plus__btn:not(:disabled):not(.disabled):active,
.plus__btn:hover,
.plus__btn:focus {
  background-color: #0c30af;
  border-color: #0c30af;
  color: #fff;
  box-shadow: none;
}
.tourListing--block-plus {
  margin-bottom: 15px;
  min-height: 52px;
}
.tourListing--block-plus .tourListing--row {
  padding: 8px 12px;
}
.tourListing--block-alt {
  padding: 6px 12px 6px 8px;
}
.tourListing--block-alt .tourListing__img {
  width: 38px;
  height: 38px;
  min-width: 38px;
  border-radius: 50%;
  margin: 0;
}
.tourListing--block-alt .tourListing--row {
  padding: 0px 0px 0px 16px;
}




/*
  Tour Edit Home & Gallery Style
*/















/*
  Tour Presentaion Style
*/


.tourPre--page {
  min-height: 100vh;
}
.tourPre--content {
  min-height: 84px;
  display: flex;
  align-items: flex-end;
  position: relative;
}
.tourPre--content .liveTour--options {
  min-width: 150px;
}
.tourPre--top .liveTour--container {
  max-width: 1140px;
}
.tourPre--top .liveTour--head {
  display: flex;
}
.tourPre--top .liveTour--gallery-img {
  height: 140px;
  margin-bottom: 18px;
}
.tourPre--top .liveTour--gallery-row {
  margin-left: -9px;
  margin-right: -9px;
}
.tourPre--top .liveTour--gallery-row .liveTour--gallery-col {
  padding-left: 9px;
  padding-right: 9px;
}
.tourPre--content-listing {
  min-height: 212px;
}
.tourPre--content .liveTour--listing {
  margin-top: -20px;
  padding-top: 20px;
}






/*
  Tour Presentaion Style
*/














/*
  Admin Home Search & Results Style
*/




.search--group-lg .form-control {
  height: calc(3.1112em + .75rem + 2px);
  font-size: 18px;
  line-height: 22px;
  padding: 16px 24px 16px 64px;
}
.search--group-lg .search__icon {
  font-size: 24px;
  left: 24px;
  top: 24px;
}
.search--group-lg .search__icon-cross {
  color: #6A7074;
  font-size: 22px;
  position: absolute;
  right: 16px;
  top: 24px;
  display: inline-flex;
  cursor: pointer;
}
.search--group-lg .form-control::-webkit-input-placeholder {
  color: #BCBAB8;
}
.search--group-lg .form-control::-ms-input-placeholder {
  color: #BCBAB8;
}
.search--group-lg .form-control::placeholder {
  color: #BCBAB8;
}
.heading__sm {
  font-size: 18px;
  word-break: break-word;
}
.heading__sm-black {
  color: #222222;
}
.heading__sm-dark {
  color: #1C1F20;
}
.bs__heading {
  font-size: 20px;
  word-break: break-word;
}
.bs__heading-black {
  color: #222222;
}
.bs__heading-dark {
  color: #1C1F20;
}
.searchResults--block {
  margin-bottom: 32px;
}
.search--bs {
  display: flex;
  border: 1px solid #BCBAB8;
  border-radius: 16px;
  padding: 14px 16px;
  cursor: pointer;
  margin-bottom: 20px;
  min-height: 94px;
}
.search--bs .search--bs-img {
  width: 224px;
  min-width: 224px;
  height: 64px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 20px;
}
.search--bs .search--bs-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
}
.search--bs .search--bs-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
}
.search--bs .bs__next {
  margin: 0px 8px 0px 16px;
}
.bs__next {
  font-size: 16px;
  color: #6A7074;
}
.search--bs-alt {
  min-height: 55px;
}
.search--bs-alt .bs__heading {
  margin-bottom: 0;
  margin-right: 30px;
}
.search--bs-alt .search--bs-inner {
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}
.search--bs-alt .listing__badge {
  margin-left: 8px;
  margin-right: 8px;
}
.listing__badge {
  font-size: 14px;
  min-width: 90px;
  min-height: 24px;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
}
.listing__badge-draft {
  color: #6A7074;
  background-color: rgba(188,186,184,0.2);
}
.listing__badge-draft:hover {
  color: #fff;
  background-color: #BCBAB8;
}
.listing__badge-review {
  color: #FF8D39;
  background-color: rgba(255,141,57,0.2);
}
.listing__badge-review:hover {
  color: #fff;
  background-color: #FF8D39;
}
.listing__badge-published {
  color: #fff;
  background-color: rgba(34,34,34,0.8);
}
.listing__badge-published:hover {
  color: #fff;
  background-color: #222222;
}
.block-flex {
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 1 1;
}
.block--scroll {
  overflow-x: auto;
}
.block--scroll::-webkit-scrollbar {
  height: 4px;
  width: 10px;
}
.block--scroll::-webkit-scrollbar-thumb {
  background: rgba(196,196,196,0.3);
  border-radius: 20px;
}
.photos--box {
  width: 160px;
  height: 160px;
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  margin-bottom: 14px;
}
.block--search-row {
  display: flex;
  flex: 1 1;
  margin-left: -7px;
  margin-right: -7px;
}
.photos--search-row .block--search-col {
  flex: 0 0 16.66%;
  max-width: 16.66%;
}
.block--search-row .block--search-col {
  padding-left: 7px;
  padding-right: 7px;
}
.block--search-row .photos--box {
  width: 100%;
  min-width: 10px;
  height: 180px;
}
.videos--search-row .block--search-col {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}
.video--box,
.video--iframe {
  width: 330px;
  min-width: 330px;
  height: 185px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  margin-bottom: 14px;
}
.videos--search-row .video--box,
.videos--search-row .video--iframe {
  width: 100%;
  min-width: 10px;
}
.video--box .video--box-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 102, 178, 0.21);
}
.pdf__btn:not([href]),
.pdf__btn {
  color: #1C1F20;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  background-color: #F6F6F6;
  border: 1px solid #F6F6F6;
  border-radius: 10px;
  padding: 11px 16px;
  word-break: break-word;
  width: 100%;
  text-align: left;
  min-height: 72px;
  margin-bottom: 14px;
}
.pdf__btn .pdf__btn-icon {
  color: #6A7074;
  font-size: 38px;
  display: inline-flex;
  margin-right: 18px;
  min-width: 32px;
}
.pdf__btn:not([href]):not(:disabled):not(.disabled):active:focus,
.pdf__btn:not([href]):not(:disabled):not(.disabled):active,
.pdf__btn:not([href]):hover,
.pdf__btn:not([href]):focus,
.pdf__btn:not(:disabled):not(.disabled):active:focus,
.pdf__btn:not(:disabled):not(.disabled):active,
.pdf__btn:hover,
.pdf__btn:focus {
  background-color: #dcdbdb;
  border-color: #dcdbdb;
  color: #1C1F20;
  box-shadow: none;
}
.pdf__btn .pdf__btn-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.psf--search-row {
  margin-left: -7px;
  margin-right: -7px;
}
.psf--search-row .col {
  padding-left: 7px;
  padding-right: 7px;
}
.dashbaord--search .searchResults--block:last-child {
  margin-bottom: 0;
}






/*
  Admin Home Search & Results Style
*/




















/*
  Subdomain Listing Style
*/




.subdomain--listing-content {
  margin-top: 80px;
}
.key--box-alt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: #F7F7F7;
  border-radius: 20px;
  margin-bottom: 20px;
  min-height: 118px;
}
.key--box-alt .keyBox--head {
  margin-bottom: 10px;
}
.key--box-alt .keyBox--head .small__heading {
  font-size: 16px;
}
.key--box-alt .keyBox--head i {
  font-size: 16px;
  margin-right: 10px;
}
.key--box-alt .keyBox--head i.icon-size {
  font-size: 15px;
}
.key--box-alt .heading__medium {
  line-height: 24px;
  margin-bottom: 8px;
}
.key--box-alt .small__paragraph {
  font-size: 16px;
  display: inline-flex;
  line-height: 20px;
}
.subListing--video {
  height: 467px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 20px;
}
.subListing--video-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.play__btn-blue:not([href]),
.play__btn-blue {
  color: #fff;
  font-size: 28px;
  width: 60px;
  height: 60px;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  background-color: #0638E7;
  border: none;
}
.play__btn-blue:not([href]):not(:disabled):not(.disabled):active:focus,
.play__btn-blue:not([href]):not(:disabled):not(.disabled):active,
.play__btn-blue:not([href]):hover,
.play__btn-blue:not([href]):focus,
.play__btn-blue:not(:disabled):not(.disabled):active:focus,
.play__btn-blue:not(:disabled):not(.disabled):active,
.play__btn-blue:hover,
.play__btn-blue:focus {
  background-color: #0c30af;
  color: #fff;
  box-shadow: none;
  border: none;
}
.photos--off {
  margin-top: 110px;
  margin-bottom: 110px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 36px;
  padding-right: 36px;
}
.photos--row-lg .block--search-col {
  flex: 0 0 16.66%;
  max-width: 16.66%;
}
.photos--box-lg {
  width: 200px;
  height: 200px;
  min-width: 200px;
}
.photos--box-md {
  width: 180px;
  height: 180px;
  min-width: 180px;
}
.photos__control {
  color: #BCBAB8;
  font-size: 12px;
  width: 24px;
  height: 24px;
  border: 1px solid #BCBAB8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  cursor: pointer;
  transition: all .2s ease;
}
.photos__control:hover,
.photos__control:focus {
  color: #fff;
  background-color: #BCBAB8;
}
.photos__control-sm {
  font-size: 8px;
  width: 18px;
  height: 18px;
}
.photos--off .photos__control {
  position: absolute;
  top: 0;
  bottom: 14px;
  margin-top: auto;
  margin-bottom: auto;
}
.photos--off .photos__control-prev {
  left: 0;
}
.photos--off .photos__control-next {
  right: 0;
}
.sdl--combine {
  display: inline-block;
  width: 100%;
  padding: 100px 80px;
}
.descrip--combine .para__md-gray {
  line-height: 28px;
}
.floorplan--img {
  pointer-events: none;
}
.sapces--block {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.spaces--box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 12px;
}
.spaces--box .spacesBox__line {
  width: 15px;
  height: 3px;
  border-radius: 10px;
  display: inline-flex;
  margin-right: 16px;
}
.spaces--box-available .spacesBox__line {
  background-color: #0638E7;
}
.spaces--box-common .spacesBox__line {
  background-color: #1C1F20;
}
.map--block {
  padding-top: 100px;
  padding-bottom: 100px;
}
.map--box {
  position: relative;
  width: 100%;
  height: 484px;
  border-radius: 20px;
  overflow: hidden;
}
.specs--box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  min-height: 104px;
  background-color: #F6F6F6;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
}
.specs--box .specsBox__head {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}
.specs--box .specsBox__head-icon {
  font-size: 14px;
  color: #1C1F20;
  margin-right: 10px;
  display: inline-flex;
  flex-shrink: 0;
}
.specs--box .specsBox__head-text {
  font-size: 14px;
  color: #1C1F20;
  font-weight: 500;
  display: inline-flex;
  text-align: left;
}
.specs--box .para__sm-gray {
  line-height: 22px;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.specs--box .specsBox__head-img {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.specs--box-center {
  justify-content: center;
}
.specs--box .specs__arrow {
  color: #6A7074;
  font-size: 6px;
  cursor: pointer;
  display: inline-flex;
  margin-top: 2px;
  margin-bottom: -4px;
}
.heading--usual {
  display: flex;
  align-items: center;
}
.heading--usual .headingUsual__logo {
  width: 34px;
  height: 38px;
  display: flex;
  flex-shrink: 0;
  margin-right: 24px;
}
.heading--usual .headingUsual__text {
  color: #1C1F20;
  font-size: 18px;
  font-weight: 500;
}
.subdomain--btns-fixed {
  padding: 12px 0;
  box-shadow: 0px 1px 4px rgba(87, 86, 86, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 5;
}
.tour__btn-gray {
  color: #6A7074;
  border-color: #BCBAB8;
}
.tour__btn-gray:not(:disabled):not(.disabled).active,
.tour__btn-gray:not(:disabled):not(.disabled):active:focus,
.tour__btn-gray:not(:disabled):not(.disabled):active,
.tour__btn-gray:hover,
.tour__btn-gray:focus,
.tour__btn-gray.active {
  color: #0638E7;
  border-color: #0638E7;
  background-color: transparent;
  outline: none;
  box-shadow: none;
}
.tel__btn.link__active .tour--btn-canvas {
  display: flex;
}
.video--modal .modal-dialog {
  max-width: 100%;
  margin: 0;
}
.video--modal .modal-content {
  background-color: transparent;
}
.video--modal .modal-body {
  padding: 0;
}
.video--canvas-expand {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
  margin: 60px 200px;
}
.video--box-expand {
  width: 100%;
  max-width: 1220px;
  height: 100%;
  max-height: 680px;
  border-radius: 20px;
  overflow: hidden;
  background-color: #f4f4f4;
}
.video--box-expand video {
  width: 100%;
  height: 100%;
}
.video--modal .cross__circle-modal {
  width: auto;
  height: auto;
  position: static;
  font-size: 16px;
  line-height: 16px;
  min-width: 20px;
  padding: 4px;
  margin-left: 24px;
  margin-bottom: auto;
  display: flex;
}
.subTours--block {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.subTours--video {
  width: 100%;
  height: 112px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  background-color: #F6F6F6;
  margin-bottom: 16px;
}
.subTours--video iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.subTours--video .subToursVideo--overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.24);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
.subTours--video .subToursVideo__play {
  color: #fff;
  font-size: 16px;
  display: inline-flex;
}











/*
  Subdomain Listing Style
*/


















/*
  Buildings Style
*/




.tabBuilding--nav {
  border-bottom: 1px solid rgba(188,186,184,0.5);
  flex-wrap: nowrap;
  overflow-x: auto;
}
.tabBuilding--nav::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.tabBuilding--nav .nav-item {
  text-align: center;
  margin-bottom: -1px;
}
.tabBuilding--nav-fill .nav-item {
  flex: 1 1 auto;
}
.tabBuilding--nav .nav-link {
  color: #6A7074;
  font-size: 16px;
  padding: 5px 20px 20px 20px;
  border-bottom: 3px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  min-width: 60px;
}
.tabBuilding--nav .nav-link .tb__link-icon {
  color: #6A7074;
  font-size: 16px;
  display: inline-flex;
}
.tabBuilding--nav .nav-link .tb__link-text {
  margin-left: 10px;
}
.tabBuilding--nav .nav-link.active {
  color: #0638E7;
  border-bottom-color: #0638E7;
}
.tabBuilding--nav .nav-link.active .tb__link-icon {
  color: #0638E7;
}
.shadowScroll--horizontal {
  position: relative;
}
.shadowScroll--horizontal:after {
  content: '';
  background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 78px;
  pointer-events: none;
  margin-bottom: 8px;
}
.tabBuilding--content {
  margin-top: 40px;
}
.custom__radioBtn-alt {
  font-size: 14px;
  color: #6A7074;
  display: inline-block;
  line-height: 18px;
}
.buildings--map {
  height: 430px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.specs--box-alt .specsBox__head-icon {
  color: #6A7074;
  font-size: 16px;
}
.specs--box-alt .specsBox__head-text {
  color: #6A7074;
  font-size: 16px;
  font-weight: normal;
}
.specs--box-alt .specs__label {
  color: #1C1F20;
  font-size: 24px;
  display: inline-flex;
  line-height: 24px;
  text-decoration: underline;
}
.specs--box-alt .specs__input {
  color: #1C1F20;
  font-size: 24px;
  line-height: 24px;
  padding: 0;
  height: calc(0.51em + .75rem + 2px);
  border: none;
  background-color: transparent;
  text-align: center;
}
.specs--box-alt .specs__input::-webkit-input-placeholder {
  color: #1C1F20;
}
.specs--box-alt .specs__input::-ms-input-placeholder {
  color: #1C1F20;
}
.specs--box-alt .specs__input::placeholder {
  color: #1C1F20;
}
.specs--box-alt .para__md-gray {
  color: #1C1F20;
  margin-left: 6px;
}
.specs--box-alt .specsBox__link {
  color: #1C1F20;
  font-size: 14px;
  text-decoration: underline;
  word-break: break-word;
}
.specs--box-alt .specsBox__head-img {
  width: 50px;
  height: 50px;
  min-width: 50px;
}
.specs--box-alt .specs--box-base {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.amenities--box,
.specs--box-alt {
  padding: 15px 18px;
}
.amenities--box {
  justify-content: center;
}
.amenities--box .specs__label {
  color: #1C1F20;
  font-size: 16px;
  display: inline-flex;
  line-height: 24px;
  text-decoration: underline;
}
.specsCertificate--alt .specsBox__head {
  margin-bottom: 0;
}
.specsCertificate--alt .specsBox__head-text {
  color: #1C1F20;
}
.specsCertificate--alt {
  justify-content: center;
}
.specs--box .specsBox__caret {
  color: #222222;
  font-size: 12px;
  display: inline-flex;
  cursor: pointer;
  margin-left: 4px;
}
.specs--box-alt .specsBox__line {
  height: 1px;
  width: 70px;
  background-color: #1C1F20;
  display: inline-flex;
}
.specs--box-options .ct--radio-alt,
.ct--radio-alt {
  padding: 0;
  min-height: 10px;
  margin-bottom: 0;
  border: 0;
}
.ct--radio-alt .checkmark {
  margin-right: 8px;
}
.specs--box-options {
  display: flex;
  align-items: center;
  width: 100%;
}
.specs--box-options .ct--radio-alt {
  width: 50%;
}
.specs--box-options .specsBox__head {
  margin-right: auto;
}
.specs--box-alt .specsBox__ec-img {
  width: 18px;
  height: 18px;
  min-width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.specs--box-mb {
  margin-bottom: 12px;
}
.addAmenities__btn {
  padding: 15px 18px;
  color: #1C1F20;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  text-decoration: underline;
  border: 1px solid #000000;
  border-radius: 10px;
  background-color: transparent;
  min-height: 104px;
}
.addAmenities__btn:not(:disabled):not(.disabled).active,
.addAmenities__btn:not(:disabled):not(.disabled):active:focus,
.addAmenities__btn:not(:disabled):not(.disabled):active,
.addAmenities__btn:hover,
.addAmenities__btn:focus,
.addAmenities__btn.active {
  color: #fff;
  border-color: #1C1F20;
  background-color: #1C1F20;
  outline: none;
  box-shadow: none;
  text-decoration: underline;
}
.epecs__edit {
  color: #fff;
  font-size: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6A7074;
  cursor: pointer;
  transition: all .2s ease;
}
.epecs__edit:hover {
  background-color: #1C1F20;
}
.specs--box .epecs__edit {
  position: absolute;
  right: 5px;
  top: 5px;
  display: none;
}
.specs--box:hover .epecs__edit {
  display: flex;
}
.time--box .specsBox__head-text {
  color: #6A7074;
  font-size: 16px;
  font-weight: normal;
}
.time--bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.time--bar .timeBar__line {
  width: 8px;
  height: 2px;
  background-color: #1C1F20;
  display: inline-flex;
  margin: 0 2px;
}
.time--dropdown .dropdown-toggle {
  color: #1C1F20;
  font-size: 16px;
  padding: 0;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  display: inline-flex;
  align-items: center;
}
.time--dropdown .dropdown-toggle::after {
  margin-left: 6px;
}
.time--dropdown.show>.dropdown-toggle,
.time--dropdown .dropdown-toggle:not(:disabled):not(.disabled):active,
.time--dropdown .dropdown-toggle:not(:disabled):not(.disabled):active:focus,
.time--dropdown .dropdown-toggle:not(:disabled):not(.disabled):active:focus {
  border: none;
  background-color: transparent;
  box-shadow: none;
  color: #1C1F20;
}
.time--dropdown .dropdown-menu {
  right: 0 !important;
  left: -3px !important;
  min-width: 10px;
  padding: 0;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #f6f6f6;
  margin: 0;
  color: #1C1F20;
  font-size: 14px;
  max-height: 100px;
  overflow-y: auto;
}
.time--dropdown .dropdown-menu::-webkit-scrollbar {
  width: 4px;
}
.time--dropdown .dropdown-menu::-webkit-scrollbar-thumb {
  background: #F6F6F6;
}
.time--dropdown .dropdown-menu .dropdown-item {
  padding: 4px 5px;
}
.time--dropdown .dropdown-menu .dropdown-item:hover,
.time--dropdown .dropdown-menu .dropdown-item:focus,
.time--dropdown .dropdown-menu .dropdown-item:active {
  background-color: #f8f9fa;
  color: #1C1F20;
}









/*
  Buildings Style
*/












/*
  Listings Pages Style
*/



.off--canvas,
.availabelListing--sticky {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.off--canvas .off--canvas-content,
.availabelListing--sticky .off--canvas-content {
  flex: 1 0 auto;
}
.listing__btn {
  border: 1px solid #0638E7;
  background-color: #fff;
  border-radius: 10px;
  min-height: 130px;
  padding: 22px 16px;
  display: flex;
  flex-direction: column;
}
.listing__btn .listing__btn-bar {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}
.listing__btn .listing__btn-icon {
  color: #0638E7;
  font-size: 24px;
  display: inline-flex;
  margin-right: 16px;
}
.listing__btn .listing__btn-heading {
  color: #0638E7;
  font-size: 18px;
  display: inline-flex;
}
.listing__btn .para__sm-gray {
  text-align: left;
  font-size: 13px;
  margin-top: auto;
}
.listing__btn:not(:disabled):not(.disabled).active,
.listing__btn:not(:disabled):not(.disabled):active:focus,
.listing__btn:not(:disabled):not(.disabled):active,
.listing__btn:hover,
.listing__btn:focus,
.listing__btn.active {
  color: #fff;
  border-color: #0638E7;
  background-color: #0638E7;
  outline: none;
  box-shadow: none;
}
.listing__btn:hover .listing__btn-icon,
.listing__btn:focus .listing__btn-icon,
.listing__btn:hover .listing__btn-heading,
.listing__btn:focus .listing__btn-heading,
.listing__btn:hover .para__sm-gray,
.listing__btn:focus .para__sm-gray {
  color: #fff;
}
.specs--box-alt .specs__smText {
  color: #1C1F20;
  font-size: 14px;
  text-decoration: underline;
  display: inline-flex;
}
.listingMedia--block {
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #BCBAB8;
}
.ls--block-head {
  margin-bottom: 40px;
}
.photos--listings {
  display: flex;
  padding-left: 28px;
  padding-right: 28px;
  position: relative;
}
.photos--listings .photos__control-prev {
  left: 0;
}
.photos--listings .photos__control-next {
  right: 0;
}
.photos--listings .photos__control {
  position: absolute;
  top: 80px;
}
.photos--listing-row {
  display: flex;
  flex: 1 1;
  margin-left: -7px;
  margin-right: -7px;
}
.photos--listing-row .block--search-col {
  flex: 0 0 16.66%;
  max-width: 16.66%;
}
.photos--listings .block--scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.render--detail {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.render--detail .link__gray-sm {
  margin-top: 14px;
}






/* .row--space-10 .col-12:nth-of-type(5n) .text-center.mb-4 {
  margin-bottom: 0;
} */





/*
  Listings Page Style
*/



/*
  Media Queries
 */

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .listing--schedule-col {
    flex-direction: column;
  }
  .week--time {
    padding-right: 0;
    margin-bottom: 16px;
  }
  .hours--time,
  .listing--schedule-col .select--group,
  .ca--cover,
  .ca__btn-canvas {
    width: 100%;
  }
  .ca--cover-large {
    width: 55%;
  }
  .group-input .outline__button {
    min-width: 150px;
  }
}
@media screen and (min-width: 576px) {
  .flex-row-sm-reverse {
    flex-direction: row-reverse;
  }
  .modal--uploadPhotos .modal-dialog {
    max-width: 860px;
  }
  .modal-settings .modal-dialog {
    max-width: 700px;
  }
  .listing--modal .modal-dialog,
  .office-modal .modal-dialog {
    max-width: 640px;
  }
  .footer-n .footer__logo {
    margin-right: 20px;
  }
  .modal--video .modal-dialog {
    max-width: 100%;
  }
  .users--content-tenant {
    margin-bottom: -23px;
  }
  .liveTour--amenities-main {
    margin-top: 32px;
  }
  .tourListing--block .tourListing--row .para__md-dark {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ml-sm-20 {
    margin-left: 20px;
  }
  .dashboard--content-new .box--expand {
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }
  .tourListing--block .tourListing__img {
    margin-left: -1px;
    margin-top: -1px;
    margin-bottom: -1px;
  }
}
@media screen and (min-width: 768px) {
  .steps--content .row .col-12 {
    border-right: 1px solid rgba(188, 186, 184, 0.5);
  }
  .steps--content .row .col-12:last-child {
    border-right: 0;
  }
  .modal--video-set {
    max-width: 640px;
    height: 360px !important;
  }
  .users--content-tenant {
    margin-top: -90px;
  }
  .liveTour--mobile {
    display: none;
  }
  .sec--vertical-md {
    min-height: 100vh;
  }
  .sec--vertical-md .sec--vertical-middle {
    min-height: calc(100vh - 65px);
    display: flex;
    align-items: center;
  }
  .vt--share {
    min-height: 100vh;
    align-items: center;
  }
  .liveTour--options {
    min-width: 360px;
  }
  .liveTour--content-broker .liveTour--options:before {
    content: '';
    width: 21px;
    background: linear-gradient(95.75deg, #1C1F20 2.38%, rgba(28, 31, 32, 0) 86.57%);
    position: absolute;
    left: -20px;
    top: 0;
    bottom: 0;
    transform: rotate(180deg);
    pointer-events: none;
  }
  .liveTour--options li:last-child .liveTourOpt__box {
    padding-left: 20px;
    border-left: 1px solid rgba(213,217,224,0.3);
  }
  .tourListing--canvas {
    overflow: hidden;
  }
  .tourListing--scroll {
    max-height: 324px;
    overflow-y: auto;
    padding-right: 3px;
  }
  .tourListing--canvas.tourListing--canvas-shadow-effect:after {
    content: '';
    box-shadow: 0px -54px 10px rgba(255, 255, 255, 0.5);
    position: absolute;
    bottom: -58px;
    left: 0;
    right: 0;
    height: 27px;
  }
  .tourListing--scroll::-webkit-scrollbar {
    width: 3px;
  }
  .tourListing--scroll::-webkit-scrollbar-thumb {
    background: #C4C4C4;
    border-radius: 5px;
  }
  .dashboard--newAccount .admin__button {
    min-width: 230px;
  }
  .tourEdit--nl {
    margin-top: 140px;
  }
  .tourPre--content .liveTour--options {
    min-width: 150px;
  }
}
@media screen and (min-width: 992px) {
  .navbar__list {
    margin-right: 50px;
  }
  .navbar-light .navbar__list .nav-link,
  .navbar__list .nav-link {
    margin: 4px 25px;
  }
  .row--space-wide {
    margin-left: -25px;
    margin-right: -25px;
  }
  .row--space-wide>.col-12 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .form--content .navbar__header .navbar-brand,
  .menu__toggle {
    display: none;
  }
  .vc--mobile {
    display: none;
  }
  .about--block:nth-child(odd) .about--detail,
  .about--block:nth-child(even) .about--img {
    padding-right: 120px;
  }
  .about--block:nth-child(even) .about--detail,
  .about--block:nth-child(odd) .about--img {
    padding-left: 120px;
  }
  .form--content .navbar__list {
    margin-left: 0;
  }
  .form--banner .form__brand {
    display: block;
  }
  .form--page,
  .dashboard--page {
    overflow: hidden;
  }
  .form--banner,
  .form--content,
  .dashboard--content {
    height: 100vh;
    overflow-y: auto;
  }
  .dashboard--container {
    padding-left: 70px;
    padding-right: 70px;
  }
  .al--block .al--listings-component {
    padding: 0px 10px;
  }
  .listingFinal--content {
    display: flex;
    align-items: center;
  }
  .virtual--video-block {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin-right: 0;
  }
  .empty--box {
    height: calc(100vh - 107px);
    margin-top: 0;
  }
  .empty--listings {
    height: calc(100vh - 152px);
  }
  .standby--content {
    margin-top: 0;
    height: calc(100vh - 72px);
    display: flex;
    align-items: center;
  }
  .info--block {
    padding-left: 24px;
    padding-right: 24px;
  }
  .row--gif .col-lg-2 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .al__address {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .available--listing-cols .scroll--listing-cols {
    max-height: 890px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .liveTour--amenities-main {
    margin-top: 44px;
  }
  .voiceRecord--block {
    max-height: 180px;
    margin-bottom: 16px;
    overflow-y: auto;
  }
  .voiceRecord--block::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
  }
  .voiceRecord--block::-webkit-scrollbar-thumb {
    background-color: #bcbab8;
  }
  .sec--vertical-lg,
  .virtualTour--combine {
    min-height: 100vh;
  }
  .sec--vertical-lg .sec--vertical-middle,
  .vt--combine-alt {
    min-height: calc(100vh - 65px);
    display: flex;
    align-items: center;
  }
  .share--realistic .share--content {
    padding-left: 240px;
  }
  .al--block-lg .al--adress-component {
    min-width: 34%;
    max-width: 34%;
  }
  .al--block-lg .al--listings-component {
    min-width: 42%;
    max-width: 42%;
  }
  .al--block-sm .al--adress-component {
    min-width: 30%;
    max-width: 30%;
  }
  .al--block-sm .al--listings-component {
    min-width: 48%;
    max-width: 48%;
  }
  .al--block-md .al--adress-component {
    min-width: 40%;
    max-width: 40%;
  }
  .al--block-md .al--listings-component {
    min-width: 44%;
    max-width: 44%;
  }
  .al--block .al--btn-component {
    width: auto;
    min-width: 10px;
    max-width: 100%;
  }
  .al--block.buildings--block .bb-sf {
    min-width: 60%;
    max-width: 60%;
  }
  .available--listing-map {
    height: 890px;
    overflow: hidden;
  }
  .unpublished--col {
    margin-top: -60px;
  }
  .toggle__btn {
    display: none;
  }
  .dashboard--sticky {
    overflow-y: hidden;
  }
  .dashboard--bar .dashboard__heading {
    line-height: 48px;
  }
  .al--lg-alt .al--btn-component {
    min-width: 24%;
    max-width: 24%;
  }
  .show--col-list .admin__button {
    min-width: 94px;
  }
}
@media screen and (min-width: 1200px) {
  .container--expand {
    max-width: 1170px;
  }
  .row--space-price,
  .row--space-price .row {
    margin-left: -30px;
    margin-right: -30px;
  }
  .row--space-price .col-12 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .row-availableListings>.col-lg-4 {
    flex: 0 0 34.5%;
    max-width: 34.5%;
  }
  .row-availableListings>.col-lg-8 {
    flex: 0 0 65.5%;
    max-width: 65.5%;
  }
  .broker--accordian {
    min-height: 420px;
  }
  .al--block-md .al--adress-component {
    min-width: 34%;
    max-width: 34%;
  }
  .al--block-md .al--listings-component {
    min-width: 48%;
    max-width: 48%;
  }
  .al--block-md .al__address {
    -webkit-line-clamp: 1;
  }
  .ls--left {
    min-width: 204px;
    max-width: 204px;
    margin-right: 20px;
  }
  .ls--bottom .ls--left {
    margin-bottom: 20px;
  }
  .al--block-alt .al__address {
    -webkit-line-clamp: 2;
  }
  .al--block-alt .al--adress-component {
    min-width: 34%;
    max-width: 34%;
  }
  .al--block-md .al--listings-component {
    min-width: 36%;
    max-width: 36%;
  }
  .al--block-alt .al--btn-component {
    min-width: 12%;
    max-width: 12%;
  }
  .photos--listings {
    margin-left: -32px;
    margin-right: -32px;
  }
  .media--video-block .media--video {
    width: 220px;
  }
}
@media screen and (min-width: 1280px) {
  .team--main-sbl {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (min-width: 1440px) {
  .container--expand {
    max-width: 1270px;
  }
  .fluid--expand {
    max-width: 1440px;
  }
  .container--xs {
    max-width: 800px;
  }
  .md--canvas {
    max-width: 960px;
  }
  .vp__box {
    right: -11px;
    top: 132px;
  }
  .vp__box:after {
    height: 31px;
    left: 2px;
    bottom: -22px;
  }
  .vp__logo:before {
    height: 26px;
    right: 45px;
    top: -25px;
  }
  .vt--demand-content {
    min-height: 450px;
  }
  .vt--combine {
    margin: 50px 0;
  }
  .vt--combine .adminSettings--head {
    margin-bottom: 20px;
  }
  .broker--video-placement,
  .broker--video-placement video,
  .broker--video-placement iframe {
    height: 480px;
  }
  .dashboard__btn:not(:disabled),
  .dashboard__btn {
    font-size: 18px;
    padding: 12px 16px;
  }
  .al--block-alt .al--adress-component {
    min-width: 32%;
    max-width: 32%;
  }
  .al--block-alt .al--listings-component {
    min-width: 30%;
    max-width: 30%;
  }
  .al--block-alt .al--btn-component {
    min-width: 15%;
    max-width: 15%;
  }
  .tourPre--top .liveTour--placement,
  .tourPre--top .liveTour--gallery,
  .tourPre--top .liveTour--custom {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .photos--off {
    margin-left: -36px;
    margin-right: -36px;
  }
  .photos--off .photos__control-prev {
    margin-right: 22px;
  }
  .photos--off .photos__control-next {
    margin-left: 22px;
  }
  .media--video-block .media--video {
    width: 330px;
  }
  .media--video-block .media--detail {
    min-width: 190px;
  }
}
@media screen and (min-width: 1620px) {
  .container--expand {
    max-width: 1400px;
  }
  .fluid--expand {
    max-width: 1600px;
  }
  .container--xs {
    max-width: 960px;
  }
  .tour--col {
    min-width: 20%;
  }
  .liveTour--building-top {
    margin-top: 60px;
  }
  .liveTour--specs-main {
    margin-top: 44px;
  }
  .building--widget .bw__arrow {
    font-size: 11px;
    line-height: 12px;
  }
  .vt--container {
    max-width: 960px;
  }
  .listing__btn .para__sm-gray {
    font-size: 14px;
  }
  .time--dropdown .dropdown-toggle {
    font-size: 18px;
  }
  .time--dropdown .dropdown-menu {
    font-size: 16px;
  }
}
@media screen and (max-width: 1440px) {
  .fluid--expand {
    padding-left: 60px;
    padding-right: 60px;
  }
  .container--large,
  .vt--container {
    padding-left: 32px;
    padding-right: 32px;
  }
  .pdf__btn:not([href]), .pdf__btn {
    font-size: 15px;
    padding: 10px 12px;
  }
  .pdf__btn .pdf__btn-icon {
    font-size: 28px;
    margin-right: 12px;
    min-width: 24px;
  }
  .block--search-row .photos--box {
    height: 160px;
  }
  .photos--listings .photos__control {
    top: 70px;
  }
}
@media screen and (max-width: 1300px) {
  .vp__logo {
    left: -20px;
  }
  .vp__logo:before {
    height: 22px;
    left: 80px;
    right: inherit;
    top: -21px;
  }
  .vp__box:after {
    height: 28px;
    left: 0;
    bottom: -18px;
  }
}
@media screen and (max-width: 1280px) {
  .tourEdit--top {
    height: 380px;
  }
  .video--canvas-expand {
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media screen and (max-width: 1200px) {
  .form--banner {
    width: 400px;
    min-width: 400px;
  }
  .form--content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .modal--listing .modal-dialog {
    max-width: 100%;
  }
  .al--block {
    flex-direction: column;
  }
  .al--block .al--block-img {
    width: 100%;
    height: auto;
  }
  .al--block .al--block-detail {
    margin-left: 0;
  }
  .landing__heading {
    font-size: 52px;
  }
  .navbar__list {
    margin-right: 28px;
  }
  .navbar-light .navbar__list-n .nav-link,
  .navbar__list-n .nav-link {
    margin-left: 14px;
    margin-right: 14px;
  }
  .tour--col {
    min-width: 34%;
  }
  .broker--video {
    margin-bottom: 48px;
  }
  .ls--top,
  .ls--bottom {
    flex-direction: column;
    align-items: flex-start;
    min-height: 10px;
  }
  .ls--top {
    min-height: 90px;
    padding-right: 0;
    margin-top: 20px;
  }
  .liveTour--listing-search .ls--top {
    min-height: 10px;
  }
  .ls--sort {
    margin-top: 20px;
    margin-bottom: 16px;
  }
  .ls--opt {
    width: 100%;
    margin-bottom: 20px;
  }
  .ls--top .ls__cross {
    top: 6px;
  }
  .liveTour--listing {
    overflow-y: auto;
  }
  .liveTour--listing::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .search__block {
    margin-bottom: 32px;
  }
  .white--rounded-box {
    padding: 24px;
  }
  .dashboard--card {
    padding-left: 24px;
    padding-right: 24px;
  }
  .al--sm-alt .al--block-img,
  .al--lg-alt .al--block-img,
  .al--block-alt .al--block-img {
    margin-bottom: 20px;
    height: 400px;
    min-width: 10px;
  }
  .dashboard__btn {
    margin-top: 20px;
  }
  .upcomingTour--block .upcomingTour--left {
    min-width: 160px;
    max-width: 160px;
  }
  .al--sm-alt .al--block-detail,
  .al--lg-alt .al--block-detail,
  .al--block-alt .al--block-detail {
    padding-left: 0;
  }
  .subDashboard--bar {
    flex-direction: column;
    align-items: flex-start;
  }
  .subDashboard--bar .subDashboard--top-btns {
    width: 100%;
  }
  .psf--search-row .block--search-col {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .photos--search-row .block--search-col {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .photos--listing-row .block--search-col,
  .photos--row-lg .block--search-col {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media screen and (max-width: 991px) {
  .container--expand,
  .fluid--expand,
  .navbar-expand-lg>.fluid--expand {
    padding-left: 32px;
    padding-right: 32px;
  }
  .container--large,
  .container--expand {
    max-width: 100%;
  }
  .navbar__list {
    padding: 15px;
  }
  .navbar--btns {
    padding: 0px 15px 15px 15px;
  }
  .navbar__header .navbar-collapse {
    background-color: #28313c;
    margin-top: 14px;
    box-shadow: 2px 0px 11px rgba(0,0,0,0.15);
  }
  .nav__signin-button {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar__header .outline__button {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
  .lists__heading {
    font-size: 26px;
  }
  .lists__subHeading {
    font-size: 22px;
  }
  .lists-info li {
    font-size: 20px;
  }
  .fields__icon {
    right: 20px;
    top: 13px;
    font-size: 22px;
  }
  .fields--group {
    margin-bottom: 16px;
  }
  .tour--status {
    font-size: 16px;
    right: 15px;
    top: 15px;
  }
  .heading__landing {
    font-size: 42px;
  }
  .heading__component,
  .form__heading,
  .dashboard__heading {
    font-size: 26px;
  }
  .steps__paragraph {
    font-size: 16px;
  }
  .about--block .about--detail {
    margin-bottom: 40px;
    text-align: center;
  }
  .about--block .about--img {
    text-align: center;
  }
  .about__heading {
    margin-bottom: 16px;
  }
  .footer {
    margin-top: 40px;
  }
  .company__about {
    font-size: 16px;
    line-height: 28px;
  }
  .listing--map {
    height: auto;
    min-height: 320px;
  }
  .form--page {
    flex-direction: column-reverse;
  }
  .form--banner {
    display: none;
  }
  .app__button-sm {
    min-height: 48px;
  }
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    margin-left: -70px;
  }
  .sidebar .sidebar--canvas {
    min-width: 70px;
    width: 70px;
  }
  .sidebar.sidebar--hide {
    margin-left: 0;
    margin-right: 0;
  }
  .sidebar__toggle {
    display: none;
  }
  .dashboard--select-n {
    width: 100%;
  }
  .broker__table {
    min-width: 880px;
  }
  .container--xs {
    max-width: 100%;
  }
  .listing--schedule {
    flex-direction: column;
  }
  .listing--schedule-col {
    padding-left: 0;
    padding-right: 0;
  }
  .hours__change {
    margin: 10px 0;
  }
  .al--block .al--block-detail {
    flex-direction: column;
  }
  .al--listing-box .al--listing,
  .al--listing-box .al--listing:last-child {
    margin-bottom: 16px;
  }
  .customer--block {
    margin: 0px 0px 16px 0px;
  }
  .buildings--block .bb-sf {
    width: 100%;
    justify-content: space-between;
    margin-top: 6px;
  }
  .dashboard--opt {
    min-height: 44px;
  }
  .heading--group .heading__edit {
    margin-top: 4px;
  }
  .vb--positioning {
    margin-right: 0;
  }
  .hours__del {
    margin: 20px 0px 20px auto;
  }
  .opinion__para {
    font-size: 20px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .opinion__para:before {
    background-size: 18px;
    left: 0;
    top: -10px;
  }
  .opinion__para:after {
    background-size: 18px;
    right: 0;
    bottom: -14px;
  }
  .landing__header-n .navbar-collapse {
    background-color: #15235c;
  }
  .navbar-light .navbar__list-n .nav-link,
  .navbar__list-n .nav-link, .navSignin__btn {
    color: #fff;
  }
  .landing__header-n .schedule__btn-outline {
    border-color: #fff;
    color: #fff;
  }
  .landing__heading {
    font-size: 42px;
  }
  .landing__para {
    font-size: 18px;
  }
  .info--block {
    margin-bottom: 48px;
  }
  .info--content .row .col-12:last-child .info--block {
    margin-bottom: 0;
  }
  .info--component {
    padding-top: 140px;
  }
  .navbar-light .navbar__list-n .nav-link,
  .navbar__list-n .nav-link {
    margin-left: 0;
    margin-right: 0;
  }
  .tour--col {
    min-width: 43%;
  }
  .vp__box:after,
  .vp__logo:before,
  .vp__secure:after {
    content: none;
  }
  .approve--video {
    margin-bottom: 32px;
  }
  .vt--combine .adminSettings--head .adminSettings__icon {
    font-size: 20px;
  }
  .vt--combine .adminSettings--head .adminSettings__text {
    font-size: 24px;
    line-height: 30px;
  }
  .share--options {
    display: none;
  }
  .buildings--block .bb--inner {
    width: auto;
  }
  .available--listing-map {
    height: 400px;
    overflow: hidden;
    margin-top: 32px;
  }
  .al--listing-combine {
    justify-content: space-between;
  }
  .ls--box .ls--box-detail {
    max-width: 260px;
    min-width: 260px;
  }
  .dashbaord--opt {
    margin-top: 16px;
  }
  .al--block-alt .al--block-detail {
    padding-left: 0;
  }
  .al--block-alt .buildings__next {
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
  }
  .upcomingTour--block {
    flex-direction: column;
  }
  .upComTour--btn-canvas {
    width: 100%;
  }
  .upComTour__list {
    padding-right: 0;
  }
  .upcomingTour--block .upcomingTour--main {
    width: 100%;
    margin-bottom: 24px;
  }
  .al--block-alt .al--btn-component {
    flex-direction: row;
  }
  .building__btn:not([href]),
  .building__btn {
    margin-left: 6px;
    margin-right: 6px;
  }
  .sidebar .sidebar--detail {
    display: none;
  }
  .sidebar--user-canvas {
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar--hide .sidebar--user-canvas {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sidebar--popover {
    left: -8px !important;
    margin-top: -20px !important;
  }
  .tabBuilding--nav .nav-link .tb__link-text,
  .tel__btn .tel__btn-text {
    display: none;
  }
  .tel__btn:not([href]),
  .tel__btn {
    min-width: 52px;
  }
  .tel--btns-row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .tel--btns-row .tel--btns-col {
    padding-left: 5px;
    padding-right: 5px;
  }
  .tourPre--top .liveTour--gallery-row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .tourPre--top .liveTour--gallery-row .liveTour--gallery-col {
    padding-left: 5px;
    padding-right: 5px;
  }
  .tourPre--top .liveTour--gallery-img {
    margin-bottom: 10px;
  }
  .map--block,
  .sdl--combine {
    padding: 60px 0px;
  }
  .video--canvas-expand {
    height: calc(100vh - 20px);
    margin: 10px;
  }
  .video--box-expand {
    max-height: 500px;
  }
  .video--modal .cross__circle-modal {
    line-height: 20px;
    min-width: 28px;
    height: 28px;
    background-color: #fff;
    border-radius: 50%;
    color: #6A7074;
    position: absolute;
    top: 15px;
    right: 15px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  .listing--btns-top .listing__btn {
    margin-bottom: 20px;
  }
  .listing--btns-top .row .col-12:last-child .listing__btn {
    margin-bottom: 0;
  }
  .lsBlock--btns-single .link__btn {
    margin-left: auto;
  }
  .navbar-light .navbar__list-n .nav-link:hover,
  .navbar__list-n .nav-link:hover,
  .navbar-light .navbar__list-n .nav-link.active,
  .navbar__list-n .nav-link.active,
  .navSignin__btn:not(:disabled):not(.disabled).active,
  .navSignin__btn:not(:disabled):not(.disabled):active,
  .navSignin__btn:hover,
  .navSignin__btn.active {
    color: #EAEDF4;
  }
}
@media screen and (max-width: 767px) {
  .vt--container,
  .container--expand,
  .container--large,
  .fluid--expand,
  .navbar-expand-lg>.fluid--expand {
    padding-left: 20px;
    padding-right: 20px;
  }
  .schedule__button {
    margin-top: 40px;
  }
  .social__list {
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .social__list li a {
    margin-left: 10px;
    margin-right: 10px;
  }
  .gallery--box {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .schedule--content {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .landing--video iframe {
    padding: 9.5% 13.5% 16.8% 14%;
  }
  .chat__app {
    bottom: 15px;
    right: 15px;
  }
  .dashboard--head,
  .listing--head {
    flex-direction: column;
    align-items: flex-start;
  }
  .dashboard__heading,
  .heading--group {
    margin-bottom: 16px;
  }
  .editListing--head-btn {
    width: 100%;
  }
  .editListing--head-btn .outline__button {
    width: 100%;
    margin: 0px 5px 0px 0px;
  }
  .editListing--head-btn .admin__button {
    width: 100%;
    margin: 0px 0px 0px 5px;
  }
  .steps--content,
  .about--content {
    margin-top: 70px;
  }
  .empty--box {
    margin-top: 80px;
  }
  .f__list {
    justify-content: center;
  }
  .f__list li a {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .vp__box {
    right: -10px;
    top: 78px;
    width: 52px;
    height: 52px;
    min-width: 52px;
  }
  .vp__box:before {
    width: 36px;
    height: 36px;
  }
  .vp__secure {
    font-size: 18px;
    padding: 4px 20px;
    top: -58px;
    left: 50px;
  }
  .vp__secure .vp__secure-icon {
    font-size: 18px;
    line-height: 18px;
  }
  .vp__logo {
    left: -10px;
    width: 154px;
    height: 48px;
  }
  .vp__logo img {
    width: 126px;
    height: 33px;
  }
  .tour--row {
    justify-content: flex-start;
  }
  .tour--col {
    min-width: 100%;
  }
  .broker--video-placement,
  .broker--video-placement video,
  .broker--video-placement iframe {
    height: 300px;
  }
  .tenant--video-placement,
  .tenant--video-placement video,
  .tenant--video-placement iframe {
    height: 420px;
  }
  .tenant--video {
    margin-top: -210px;
    margin-bottom: 175px;
  }
  .vt--combine-right {
    padding-top: 40px;
  }
  .suite--box {
    justify-content: space-between;
    padding: 12px 15px;
  }
  .team--main .row .col-12:last-child .team--block {
    margin-bottom: 0;
  }
  .vt--options:after {
    right: auto;
    left: auto;
    top: auto;
    top: initial;
    bottom: -18px;
    width: 200px;
    background-size: 200px;
    height: 74px;
  }
  .liveTour--options {
    justify-content: center;
    flex-wrap: wrap;
  }
  .ct--radio-btn {
    min-height: 130px;
  }
  .ct--radio-alt {
    min-height: 10px;
  }
  .ct--radio-btn .checkmark {
    margin-right: 14px;
  }
  .render--component .ct--radio-btn .checkmark {
    margin-right: 5px;
  }
  .component--edit-overlay {
    padding-left: 30px;
  }
  .component--edit .componentEdit--head {
    padding: 20px 24px 10px 24px;
  }
  .component--edit .componentEdit--scrollable {
    padding: 20px 24px 20px 24px;
  }
  .componentEdit--scrollable {
    overflow: visible;
    overflow: initial;
  }
  .search--bs {
    flex-direction: column;
  }
  .search--bs .search--bs-img {
    margin: 0px auto 20px auto;
    height: 80px;
  }
  .search--bs .search--bs-detail {
    flex-direction: column;
  }
  .search--bs .search--bs-inner {
    align-items: center;
    margin-bottom: 20px;
  }
  .search--bs .bs__next {
    margin-left: auto;
  }
  .search--bs-alt .search--bs-inner {
    width: 100%;
    flex-direction: column;
  }
  .search--bs-alt .bs__heading {
    margin: 0px 0px 20px 0px;
  }
  .photos--box {
    width: 120px;
    height: 120px;
    min-width: 120px;
  }
  .video--box,
  .video--iframe {
    width: 180px;
    min-width: 180px;
    height: 130px;
  }
  .psf--search-row .block--search-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .search--group-lg .form-control {
    height: calc(2.8em + .75rem + 2px);
    font-size: 15px;
    line-height: 20px;
    padding: 10px 16px 10px 40px;
  }
  .search--group-lg .search__icon {
    font-size: 16px;
    left: 15px;
    top: 22px;
  }
  .search--group-lg .search__icon-cross {
    font-size: 12px;
    right: 15px;
    top: 23px;
  }
  .al--sm-alt .al--block-img,
  .al--lg-alt .al--block-img,
  .al--block-alt .al--block-img {
    height: 300px;
  }
  .team--block-alt {
    flex-direction: column;
  }
  .team--block-alt .teamBlock--img {
    margin: 0px 0px 20px 0px;
  }
  .team--block-alt .teamBlock__user {
    margin-bottom: 6px;
  }
  .buildings--map,
  .subListing--video,
  .map--box {
    height: 320px;
  }
  .map--block,
  .sdl--combine {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .photos--off {
    margin-top: 80px;
    margin-bottom: 60px;
  }
  .viewBy {
    margin-top: 16px;
    margin-bottom: 10px;
  }
  .video--box-expand {
    max-height: 320px;
  }
  .video--box-expand {
    max-height: 320px;
  }
  .videos--search-row .block--search-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .photos--search-row .block--search-col {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .photos--listing-row .block--search-col,
  .photos--row-lg .block--search-col {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .media--video-block {
    flex-direction: column;
  }
  .media--video-block .media--video {
    width: 100%;
    height: 320px;
    margin: 0px 0px 20px 0px;
  }
  .mediaContent--combine {
    margin-bottom: 10px;
  }
  .mediaContent--combine .mc--box {
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 575px) {
  .navbar--btns {
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar--btns .app__button {
    width: 100%;
    margin-top: 2px;
  }
  .office--component {
    margin-top: 50px;
  }
  .detail--text {
    margin-top: 36px;
  }
  .gradient--component {
    padding-top: 120px;
  }
  .heading__landing {
    font-size: 32px;
  }
  .heading--group .form-control,
  .heading__component,
  .form__heading,
  .dashboard__heading {
    font-size: 24px;
    line-height: 34px;
  }
  .heading__medium {
    font-size: 22px;
    line-height: inherit;
  }
  .lists__heading {
    font-size: 24px;
  }
  .lists__subHeading {
    font-size: 20px;
  }
  .lists-info li {
    font-size: 18px;
  }
  .form--option {
    flex-direction: column;
    align-items: flex-start;
  }
  .form--opt-r {
    width: 100%;
    justify-content: space-between;
    margin-top: 24px;
  }
  .form__link {
    margin-left: auto;
  }
  .signin--canvas {
    max-width: 100%;
  }
  .signin--btn-canvas,
  .btn-top {
    margin-top: 40px;
  }
  .btn-top {
    width: 100%;
  }
  .forms--btn-canvas .app__button,
  .forms--btn-canvas .admin__button,
  .adminSettings-btn .outline__button {
    width: 100%;
  }
  .form--content {
    padding-top: 40px;
    padding-bottom: 48px;
  }
  .signup--inner {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .stepBtn-canvas-1 {
    padding-top: 0px;
    margin-top: 24px;
  }
  .stepBtn-canvas .admin__button,
  .al--btn-component .admin__button,
  .annex--card-btn,
  .modal--btn-canvas .admin__button {
    width: 100%;
  }
  .al--btn-component {
    margin-top: 10px;
  }
  .al--block .al--block-img {
    width: 100%;
    height: auto;
    box-shadow: none;
  }
  .al__heading-medium {
    font-size: 20px;
  }
  .annex--card {
    flex-direction: column;
    align-items: flex-start;
  }
  .annex--card {
    padding-left: 20px;
    padding-right: 20px;
  }
  .listing--video,
  .virtualTour--video {
    height: 320px;
  }
  .listing--video video,
  .virtualTour--video video {
    height: 320px;
  }
  .listing__button {
    margin-top: 10px;
  }
  .bl--head-btn {
    width: 100%;
  }
  .b--sf-heading {
    font-size: 32px;
    line-height: 30px;
  }
  .al--block .al--block-detail {
    padding: 15px;
    margin-top: -1px;
  }
  .heading--group .heading__edit {
    margin-top: 6px;
  }
  .virtual--carousel .carousel-item {
    height: 400px;
  }
  .notfound__heading {
    font-size: 136px;
    line-height: 124px;
  }
  .nav__signin-button {
    margin-bottom: 10px;
  }
  .empty--box .admin__button {
    width: 100%;
  }
  .office-modal .modal-header,
  .office-modal .modal-body {
    padding-left: 28px;
    padding-right: 28px;
  }
  .personalize--block {
    padding: 32px 20px 20px 20px;
  }
  .landing__header-n .schedule__btn-outline {
    width: 100%;
  }
  .landing__header-n .schedule__btn-outline:hover,
  .landing__header-n .schedule__btn-outline:focus,
  .landing__header-n .schedule__btn-outline:active {
    border-color: #fff;
  }
  .broker--video-placement,
  .broker--video-placement video,
  .broker--video-placement iframe {
    height: 250px;
  }
  .tenant--video-placement,
  .tenant--video-placement video,
  .tenant--video-placement iframe {
    height: 320px;
  }
  .footer-n {
    padding-top: 240px;
  }
  .vp__secure {
    font-size: 10px;
    padding: 3px 12px;
    top: -42px;
    left: 37px;
  }
  .vp__secure .vp__secure-icon {
    font-size: 10px;
    line-height: 12px;
  }
  .personalize--block-about {
    margin-bottom: 112px;
  }
  .tenant--video {
    margin-top: -160px;
    margin-bottom: 112px;
  }
  .vp__box {
    width: 30px;
    height: 30px;
    min-width: 30px;
    top: 54px;
    right: -5px;
  }
  .vp__box:before {
    width: 20px;
    height: 20px;
  }
  .vp__logo {
    width: 106px;
    height: 36px;
    top: 16px;
  }
  .vp__logo img {
    width: 78px;
    height: 22px;
  }
  .vt--btns,
  .group-input {
    margin-bottom: 40px;
  }
  .group-input {
    flex-direction: column;
  }
  .group-input .outline__button {
    margin-left: 0;
    margin-top: 16px;
    width: 100%;
  }
  .vt--combine .dashboard__heading {
    padding-bottom: 20px;
  }
  .recording__heading {
    font-size: 24px;
  }
  .recording__timer {
    font-size: 100px;
    line-height: 100px;
  }
  .voiceRecord--block .voiceRecord--box:nth-child(odd) {
    justify-content: flex-start;
    padding-right: 10px;
  }
  .voiceRecord--block .voiceRecord--box:nth-child(even) {
    justify-content: flex-end;
    padding-left: 10px;
    padding-right: 0;
  }
  .voiceRecord--box {
    width: 50%;
  }
  .record__voice {
    width: 42px;
    height: 42px;
    min-width: 42px;
  }
  .modal--video-set {
    height: 250px !important;
  }
  .mv__cross {
    right: 4px;
  }
  .c--post-canvas .admin__button,
  .ca--cover,
  .ca__btn-canvas {
    width: 100%;
  }
  .liveTour--top-custom {
    height: auto;
  }
  .users--content .row {
    flex-direction: column-reverse;
    align-items: center;
  }
  .users--row-right {
    border-bottom: 1px solid rgba(213,217,224,0.3);
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .liveTour--options {
    margin: 0;
  }
  .liveTour--options li a {
    margin-bottom: 16px;
  }
  .tour--btn-canvas {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .tourMobile--block {
    padding: 24px 0;
  }
  .users--content-tenant .users--row-left {
    padding-top: 0px;
  }
  .notfound__img {
    width: 268px;
    height: 189px;
    margin-bottom: 40px;
  }
  .available--listing-map {
    height: 250px;
    margin-top: 20px;
  }
  .search__block .ls__search-icon {
    display: none;
  }
  .listing--group .form-control {
    font-size: 13px;
    padding: 6px 56px 6px 10px;
    height: calc(1.7em + .75rem + 2px);
    min-height: 40px;
  }
  .listing--group .enter__btn {
    font-size: 10px;
    padding: 3px 6px;
    min-width: 44px;
    min-height: 20px;
    top: 10px;
  }
  .cancel__link {
    font-size: 12px;
  }
  .liveTour--listing .container-fluid {
    justify-content: start;
  }
  .ls--sort {
    flex-direction: column;
    align-items: flex-start;
  }
  .ls__label {
    margin: 10px 0px 12px 0px;
  }
  .ls__list {
    width: 100%;
  }
  .ls--listings::-webkit-scrollbar {
    height: 14px;
    width: 10px;
  }
  .ls__search-icon {
    margin-right: 10px;
    font-size: 24px;
  }
  .sec--vertical .sec--vertical-middle .heading__medium {
    margin-bottom: 18px;
  }
  .dashbaord--opt {
    flex-direction: column;
  }
  .dashbaord--opt .admin__button {
    margin-left: 0;
    width: 100%;
    margin-top: 16px;
  }
  .white--rounded-box,
  .dashboard--card {
    padding-left: 16px;
    padding-right: 16px;
  }
  .upcomingTour--block .upcomingTour--main {
    flex-direction: column;
    margin-bottom: 16px;
  }
  .upcomingTour--block .upcomingTour--left {
    padding-right: 0;
    max-width: 100%;
    width: 100%;
    margin-right: 0;
    border-right: 0;
  }
  .upcomingTour--block .upcomingTour--right {
    border-left: 0;
    padding-left: 0;
    width: 100%;
    margin-top: 8px;
  }
  .dashboard--card .card-header {
    padding: 5px 0px;
  }
  .dashboard--card .card-header .btn-link {
    font-size: 20px;
  }
  .al--sm-alt .al--block-detail,
  .al--lg-alt .al--block-detail,
  .al--block-alt .al--block-detail {
    padding: 0;
  }
  .ct__btn-canvas {
    padding-top: 0px;
  }
  .tour__btn-canvas .btn,
  .btns-mobile-expand .btn {
    width: 100%;
  }
  .tourListing--block {
    flex-direction: column;
    padding: 16px;
  }
  .tourListing--block .tourListing--row {
    flex-direction: column;
    align-items: center;
    padding: 0;
    width: 100%;
  }
  .tourListing--block .tourListing--row .para__md-dark {
    margin-right: 0;
    margin-right: initial;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
  }
  .dashboard__btn:not(:disabled),
  .dashboard__btn {
    min-height: 60px;
  }
  .subDashboard--bar .heading__medium {
    width: 100%;
  }
  .tourEdit--top {
    height: 250px;
  }
  .tourEdit--top-gallery .liveTour--gallery-img {
    height: 58px;
  }
  .upload--gallery-img {
    font-size: 9px;
  }
  .upload--gallery-img i {
    font-size: 10px;
    margin-bottom: 6px;
  }
  .tourEdit__btn-canvas .btn {
    width: 100%;
  }
  .component--edit .form--group-alt .form-control {
    font-size: 12px;
  }
  .component--edit .search--group-alt .form-control {
    padding-left: 38px;
  }
  .component--edit .search--group-alt .search__icon {
    font-size: 14px;
    top: 18px;
  }
  .psf--search-row .block--search-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .al--sm-alt .al--block-img,
  .al--lg-alt .al--block-img,
  .al--block-alt .al--block-img {
    height: 180px;
  }
  .dashboard__heading-alt {
    font-size: 26px;
  }
  .subdomain__footer {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 34px;
  }
  .subdomain__footer .sd__footer-list {
    justify-content: center;
  }
  .subdomain__footer .sd__footer-l {
    align-items: center;
  }
  .photos--box-lg {
    width: 100%;
    min-width: 10px;
  }
  .listingModal--request {
    min-height: 350px;
  }
  .tour--btn-nowrap {
    flex-wrap: nowrap;
  }
  .photos--search-row .block--search-col {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .photos--listing-row .block--search-col,
  .photos--row-lg .block--search-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .lsBlock--btns .link__btn {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .key--details .row .col-6,
  .listings .row .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .gallery--box {
    height: auto;
  }
  .landing--video iframe {
    padding: 10.5% 13.5% 17.8% 14%;
  }
  .listing--schedule-col {
    flex-direction: column;
  }
  .listing--schedule-col .select--group {
    margin: 0px 0px 16px 0px;
    width: 100%;
  }
  .hours__change {
    margin: 6px 0px 16px 0px;
  }
  .week--time {
    padding-right: 0;
  }
  .hours--time {
    width: 100%;
  }
  .editListing--head-btn {
    flex-direction: column;
  }
  .editListing--head-btn .sm__button {
    margin: 0px 0px 20px 0px;
  }
  .virtual--carousel .carousel-item {
    height: 300px;
  }
  .hours__del {
    margin-top: 0;
  }
  .broker--video-placement,
  .broker--video-placement video,
  .broker--video-placement iframe {
    height: 180px;
  }
  .media--video-block .media--video,
  .buildings--map,
  .map--box,
  .subListing--video,
  .tenant--video-placement,
  .tenant--video-placement video,
  .tenant--video-placement iframe {
    height: 220px;
  }
  .tenant--video {
    margin-top: -110px;
  }
  .vp__secure {
    top: -34px;
    left: 32px;
  }
  .form-label {
    font-size: 11px;
  }
  .modal--video-set {
    height: 200px !important;
  }
  .drawer--right {
    width: 90%;
    padding-left: 40px;
    padding-right: 40px;
    margin-right: -100%;
  }
  .drawer--right-show {
    margin-right: 0;
  }
  .photos--search-row .block--search-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .photos--listing-row .block--search-col,
  .photos--row-lg .block--search-col,
  .videos--search-row .block--search-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .video--box,
  .video--iframe {
    width: 100%;
    min-width: 10px;
  }
}

/*
  Media Queries
 */



@media screen and (min-width: 576px) {
  .liveTour--building-top,
  .liveTour--building-main {
    margin-top: 30px;
  }
  .users--row {
    overflow-x: auto;
  }
  .users--row-left {
    direction: ltr;
  }
  .users--row-right {
    direction: rtl;
  }
  .users--row-left {
    margin-right: 30px;
  }
  .users--row-right {
    margin-left: 30px;
  }
  .liveTour--content-tenant .users--block {
    z-index: 999;
  }
  .users--block {
    z-index: 5;
  }
  .users--content-tenant .users--box {
    margin-bottom: auto;
  }
  .tourEdit--top-gallery .liveTour--gallery-img {
    height: 104px;
  }
  .placement--block.placement--block-alt {
    padding: 32px 38px;
    max-width: 360px;
  }
  .placement--block-alt .placement__heading {
    font-size: 42px;
    line-height: 54px;
    margin-bottom: 22px;
  }
  .placement--block-alt .placement--location .placementLocation__text {
    font-size: 16px;
  }
  .placement--block-alt .placement--location .placementLocation__icon {
    font-size: 22px;
  }
  .tourPre--top .liveTour__logo {
    width: 151px;
    min-width: 151px;
    height: 39px;
  }
  .tourPre--top .liveTour__heading {
    font-size: 16px;
  }
  .tourPre--top .dashboard__heading {
    font-size: 24px;
  }
  .tourPre--top .specs__list li {
    font-size: 24px;
    line-height: 24px;
  }
  .tourPre--top .building--widget {
    padding: 10px 15px;
    min-height: 66px;
  }
  .tourPre--top .building--widget .bw__icon {
    font-size: 24px;
    margin-right: 10px;
    min-width: 24px;
  }
  .tourPre--top .building--widget .bw__heading {
    font-size: 14px;
    margin-bottom: 5px;
    line-height: 16px;
  }
  .tourPre--top .amenities--widget .bw__heading {
    margin-bottom: 0;
    line-height: 20px;
  }
  .tourPre--top .building--widget .bw__para {
    font-size: 14px;
  }
  .tourPre--top .building--widget .bw__arrow {
    font-size: 5px;
    margin-top: 14px;
  }
  .tourPre--top .certification--widget .bw__icon {
    width: 44px;
    height: 44px;
    min-width: 44px;
  }
  .tourPre--top .certification--widget .bw__heading {
    line-height: 22px;
  }
}
@media screen and (min-width: 768px) {
  .liveTour__list {
    width: 100%;
    overflow-x: auto;
  }
  .users--ov-lg .users--ov-name,
  .users--ov-lg .users--ov-icon {
    font-size: 12px;
  }
  .row--feature .col--feature {
    min-width: 21%;
  }
  .row--feature .col--feature-address {
    min-width: 37%;
  }
  .users--content-tenant .users--row-left {
    margin-bottom: auto;
  }
  .tourEdit--specs .liveTour--building-top .dashboard__heading {
    font-size: 24px;
  }
  .tourEdit--specs .specs__list li {
    font-size: 24px;
    line-height: 24px;
  }
  .tourEdit--specs .specs--widget-alt {
    padding: 10px 14px;
    min-height: 62px;
    margin-bottom: 10px;
  }
  .tourEdit--specs .specs--widget-alt .bw__icon {
    font-size: 14px;
    margin-right: 7px;
    min-width: 15px;
  }
  .tourEdit--specs .specs--widget-alt .bw__heading {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .tourEdit--specs .specs--widget-alt .bw__para {
    font-size: 11px;
  }
  .tourEdit--specs .building--widget .bw__arrow {
    font-size: 5px;
    margin-top: 12px;
  }
  .tourEdit--specs .specs--widget-alt.certification--widget .bw__icon {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }
  .tourEdit--specs .specs--widget-alt.certification--widget .bw__heading {
    font-size: 12px;
    margin-bottom: 0px;
    line-height: 18px;
  }
}
@media screen and (min-width: 992px) {
  .liveTour--page .container-fluid {
    padding-left: 70px;
    padding-right: 70px;
  }
  .tour__btn .tour__btn-desktop {
    display: block;
  }
  .tour__btn .tour__btn-mobile {
    display: none;
  }
  .users--row {
    padding-top: 8px;
  }
  .users--row-left {
    margin-right: 60px;
  }
  .users--row-right {
    margin-left: 60px;
  }
  .tourPre--top-home .placement--block {
    padding: 40px 54px;
    max-width: 460px;
    border-radius: 30px;
  }
  .tourPre--top-home .placement__logo {
    width: 151px;
    height: 39px;
    margin-bottom: 24px;
  }
  .tourPre--top-home .placement__heading {
    font-size: 56px;
    line-height: 72px;
    margin-bottom: 26px;
  }
  .tourPre--top-home .placement--location .placementLocation__icon {
    font-size: 24px;
  }
  .tourPre--top-home .placement--location .placementLocation__text {
    font-size: 18px;
  }
  .tourPre--top .building--widget {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 1280px) {
  .liveTour--page {
    height: 100vh;
  }
}
@media screen and (min-width: 1440px) {
  .placement--block {
    padding: 40px 54px;
    max-width: 460px;
    border-radius: 30px;
  }
  .placement__heading {
    font-size: 56px;
    line-height: 72px;
  }
  .placement--location .placementLocation__icon {
    font-size: 24px;
  }
  .placement--location .placementLocation__text {
    font-size: 18px;
  }
}
@media screen and (min-width: 1440px) and (min-height: 800px) {
  .liveTour--placement,
  .liveTour--gallery,
  .liveTour--custom {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .liveTour--gallery .liveTour--head {
    display: flex;
  }
  .liveTour--gallery-large {
    padding-left: 70px;
    padding-right: 70px;
  }
  .liveTour--gallery .gallery--large-canvas {
    top: 0;
    bottom: 0;
  }
  .gallery__cross {
    top: 0;
  }
  .liveTour--container {
    max-width: 980px;
  }
}
@media screen and (min-width: 1440px) and (min-height: 730px) {
  .liveTour--container {
    max-width: 980px;
  }
  .liveTour--building-top .dashboard__heading {
    font-size: 24px;
  }
  .specs__list li {
    font-size: 24px;
    line-height: 24px;
  }
  .liveTour__logo {
    width: 151px;
    min-width: 151px;
    height: 39px;
  }
  .liveTour__heading {
    font-size: 12px;
  }
  .building--widget {
    padding: 10px 15px;
    min-height: 66px;
  }
  .building--widget .bw__icon {
    font-size: 24px;
    margin-right: 10px;
    min-width: 24px;
  }
  .building--widget .bw__para {
    font-size: 14px;
  }
  .specs--widget .bw__heading {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .certification--widget .bw__icon {
    width: 44px;
    height: 44px;
    min-width: 44px;
  }
  .amenities--widget .bw__heading {
    font-size: 14px;
    line-height: 16px;
  }
  .building--widget .bw__arrow {
    font-size: 5px;
    margin-top: 14px;
  }
  .certification--widget .bw__heading {
    line-height: 22px;
  }
  .liveTour--container {
    max-width: 980px;
  }
}
@media screen and (min-width: 1620px), (min-height: 730px) {
  .building--widget {
    margin-bottom: 20px;
  }
  .liveTour--placement,
  .liveTour--gallery,
  .liveTour--custom {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .liveTour--gallery-large {
    padding-left: 70px;
    padding-right: 70px;
  }
  .liveTour--gallery .liveTour--head {
    display: flex;
  }
  .liveTour--gallery .gallery--large-canvas {
    top: 0;
    bottom: 0;
  }
  .gallery__cross {
    top: 0;
  }
  .liveTour--building-top .dashboard__heading {
    font-size: 24px;
  }
  .specs__list li {
    font-size: 24px;
    line-height: 24px;
  }
  .liveTour__logo {
    width: 151px;
    min-width: 151px;
    height: 39px;
  }
  .liveTour__heading {
    font-size: 12px;
  }
  .building--widget {
    padding: 10px 15px;
    min-height: 66px;
  }
  .building--widget .bw__icon {
    font-size: 24px;
    margin-right: 10px;
    min-width: 24px;
  }
  .building--widget .bw__para {
    font-size: 14px;
  }
  .specs--widget .bw__heading {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .certification--widget .bw__icon {
    width: 44px;
    height: 44px;
    min-width: 44px;
  }
  .amenities--widget .bw__heading {
    font-size: 14px;
    line-height: 16px;
  }
  .building--widget .bw__arrow {
    font-size: 5px;
    margin-top: 14px;
  }
  .certification--widget .bw__heading {
    line-height: 22px;
  }
}
@media screen and (max-width: 1440px), (max-height: 700px) {
  .liveTour--container {
    max-width: 548px;
  }
  .placement--block {
    padding: 18px 25px;
    max-width: 260px;
    border-radius: 20px;
  }
  .placement__logo {
    width: 102px;
    height: 27px;
    margin-bottom: 18px;
  }
  .placement__heading {
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 10px;
  }
  .placement--location .placementLocation__icon {
    font-size: 16px;
  }
  .placement--location .placementLocation__text {
    font-size: 12px;
  }
  .feature--block {
    padding: 20px 15px;
    min-height: 194px;
  }
  .feature--block .featureBlock__icon {
    font-size: 22px;
  }
  .feature--block .featureBlock__heading {
    font-size: 16px;
  }
  .featureBlock--sf .sf__digit {
    font-size: 20px;
  }
  .featureBlock--sf .sf__word {
    font-size: 16px;
  }
  .featureBlock--sf {
    margin-top: 16px;
    flex-direction: column;
  }
  .feature--block-alt {
    padding: 18px 15px;
    min-height: 236px;
  }
  .feature--block-alt .featureBlock__icon {
    font-size: 34px;
  }
  .feature--block-alt .featureBlock__heading {
    font-size: 20px;
  }
  .feature--block-alt .featureBlock--sf {
    margin-top: 22px;
    flex-wrap: wrap;
  }
  .feature--block-alt .featureBlock--sf .sf__digit {
    font-size: 36px;
    white-space: normal;
  }
  .feature--block-alt .featureBlock--sf .sf__word {
    font-size: 20px;
  }
  .liveTour--building-main .row--space-5 {
    margin-left: -3px;
    margin-right: -3px;
  }
  .liveTour--building-main .row--space-5 .col-md-3 {
    padding-left: 3px;
    padding-right: 3px;
  }
  .liveTour--gallery-row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .liveTour--gallery-row .liveTour--gallery-col {
    padding-left: 5px;
    padding-right: 5px;
  }
  .liveTour--gallery-img {
    height: 78px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 1366px) {
  .liveTour--top-broker {
    max-width: 890px;
    margin-left: auto;
    margin-right: auto;
  }
  .liveTour--content-broker {
    margin-top: 5px;
  }
}
@media screen and (max-width: 1280px) {
  .liveTour--top,
  .liveTour--video video {
    height: 380px;
  }
}
@media screen and (max-width: 991px) {
  .liveTour--content {
    border-radius: 0px 0px 20px 20px;
  }
  .users--row {
    padding-bottom: 30px;
  }
  .users--ov {
    top: inherit;
    bottom: -30px;
    right: inherit;
    left: -28px;
  }
  .liveTour__list li a {
    margin-left: 5px;
    margin-right: 5px;
    min-width: 55px;
  }
  .lt__list-box .lt__list-text {
    display: none;
  }
  .feature--block {
    padding: 18px 10px;
    min-height: 170px;
  }
  .feature--block .featureBlock__icon {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .featureBlock--sf {
    margin-top: 10px;
  }
  .feature--block-address h4.featureBlock__heading {
    margin-top: 16px;
  }
  .users--row .users--block:first-child .users--ov {
    left: 0;
  }
  .users--block-lg .users--box {
    width: 94px;
    height: 94px;
    min-width: 94px;
  }
  .users--broker .users--ov {
    left: 0;
  }
  .users--broker {
    right: 0px;
  }
  .users--block-lg {
    max-width: 180px;
  }
  .row--feature {
    margin-left: -4px;
    margin-right: -4px;
  }
  .row--feature .col--feature {
    padding-left: 4px;
    padding-right: 4px;
  }
  .tour__btn {
    padding-left: 6px;
    padding-right: 6px;
    min-width: 38px;
    text-transform: uppercase;
  }
  .gallery__btn {
    padding: 4px 10px;
    min-width: 90px;
    text-transform: inherit;
    margin-top: 4px;
  }
  .feature--block-alt {
    min-height: 210px;
  }
  .tourPre--top .liveTour--gallery-img {
    height: 110px;
  }
}
@media screen and (max-width: 767px) {
  .users--block-md .users--box,
  .users--block-lg .users--box {
    width: 64px;
    height: 64px;
    min-width: 64px;
  }
  .users--row .users--block {
    margin-left: 4px;
    margin-right: 4px;
  }
  .liveTour__list {
    flex-wrap: wrap;
    justify-content: center;
  }
  .users--block-lg {
    max-width: 124px;
  }
  .liveTour--content-broker {
    margin-top: 6px;
  }
  .users--alt .usersAlt__heading {
    font-size: 24px;
    line-height: 22px;
    margin-bottom: 2px;
  }
  .users--alt .usersAlt__subHeading {
    font-size: 11px;
  }
  .liveTour--card .card-header {
    display: none;
  }
  .liveTour--card .collapse {
    display: block;
  }
  .feature--block {
    padding: 12px 10px;
    min-height: 154px;
    margin-bottom: 8px;
  }
  .row--feature {
    flex-wrap: wrap;
  }
  .row--feature .col--feature {
    width: 33.33%;
  }
  .row--feature .col--feature-address {
    width: 100%;
    max-width: 100%;
  }
  .row--feature .col--feature:last-child .feature--block {
    margin-bottom: 0;
  }
  .liveTour--container {
    max-width: 100%;
  }
  .gallery__cross {
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    font-size: 14px;
    color: #050505;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .liveTour--building-main .row--space-5 .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .feature--block-alt .featureBlock__heading {
    font-size: 18px;
  }
  .feature--block-alt .featureBlock--sf {
    margin-top: 16px;
  }
  .feature--block-alt .featureBlock--sf .sf__digit {
    font-size: 24px;
  }
  .feature--block-alt .featureBlock--sf .sf__word {
    font-size: 13px;
  }
  .tourPre--top .liveTour--gallery-img {
    height: 78px;
  }
}
@media screen and (max-width: 575px) {
  .liveTour--top,
  .liveTour--video video {
    height: 250px;
  }
  .placement--block {
    padding: 12px 16px;
    max-width: 172px;
    border-radius: 12px;
  }
  .placement__logo,
  .placement--block-alt .listing__logo {
    width: 76px;
    height: 28px;
    margin-bottom: 8px;
  }
  .placement__heading {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 6px;
  }
  .placement--location .placementLocation__icon {
    font-size: 12px;
    margin-right: 6px;
  }
  .placement--location .placementLocation__text {
    font-size: 10px;
  }
  .users--content {
    padding-bottom: 12px;
  }
  .liveTour__list li a {
    min-height: 40px;
    min-width: 40px;
    padding: 8px 0;
    margin-left: 5px;
    margin-right: 5px;
  }
  .feature--block {
    padding: 10px 8px;
    min-height: 106px;
  }
  .feature--block .featureBlock__icon {
    font-size: 14px;
    margin-bottom: 4px;
  }
  .feature--block .featureBlock__heading {
    font-size: 12px;
  }
  .featureBlock--sf {
    margin-top: 5px;
    line-height: 16px;
  }
  .featureBlock--sf .sf__digit {
    font-size: 13px;
  }
  .featureBlock--sf .sf__word {
    font-size: 11px;
  }
  .feature--block-address h4.featureBlock__heading {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .users--broker {
    top: auto;
    top: initial;
    bottom: 0;
  }
  .users--broker .users--ov {
    bottom: auto;
    bottom: initial;
    top: -34px;
    right: 0;
  }
  .liveTour--custom {
    padding: 10px 0;
  }
  .liveTour--content {
    margin-top: 0;
  }
  .amenities--widget .bw__heading {
    font-size: 12px;
    line-height: 18px;
  }
  .users--content {
    flex-direction: column;
  }
  .users--row {
    justify-content: center;
    flex-wrap: wrap;
  }
  .liveTour--card .card-body {
    margin-top: 0;
  }
  .liveTour--building-main .row--space-5 .col-md-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .liveTour--custom, .liveTour--placement {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .liveTour--gallery .gallery--large-canvas {
    top: -15px;
    bottom: -15px;
  }
  .feature--block-alt .featureBlock--sf .sf__digit {
    font-size: 13px;
  }
  .feature--block-alt .featureBlock--sf .sf__word {
    font-size: 11px;
  }
  .feature--block-alt .featureBlock--sf {
    margin-top: 5px;
    line-height: 16px;
  }
  .tourEdit--specs .liveTour--building-main .row--space-5 {
    margin-left: -3px;
    margin-right: -3px;
  }
  .tourEdit--specs .liveTour--building-main .row--space-5 .col {
    padding-left: 3px;
    padding-right: 3px;
  }
  .tourEdit--top-specs .liveTour--container {
    padding-left: 0;
    padding-right: 0;
  }
  .tourEdit--top-specs .liveTour--building-top .dashboard__heading {
    font-size: 14px;
  }
  .tourEdit--top-specs .specs__list li {
    font-size: 11px;
    line-height: 18px;
  }
  .tourPre--top-home .placement--block {
    padding: 18px 25px;
    max-width: 260px;
    border-radius: 20px;
  }
  .tourPre--top-home .placement__logo {
    width: 102px;
    height: 27px;
    margin-bottom: 18px;
  }
  .tourPre--top-home .placement__heading {
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 10px;
  }
  .tourPre--top-home .placement--location .placementLocation__icon {
    font-size: 16px;
  }
  .tourPre--top-home .placement--location .placementLocation__text {
    font-size: 12px;
  }
  .liveTour--gallery-img,
  .tourPre--top .liveTour--gallery-img {
    height: 60px;
  }
}



.bb--alt-shimmer .al--block-img,
.alb--alt-shimmer .al--block-img {
  background-color: #F5F5F5;
  border-radius: 10px;
}
.bb--alt-shimmer .al__address,
.alb--alt-shimmer .al__address {
  width: 100%;
  max-width: 230px;
  height: 24px;
  background-color: #F5F5F5;
  border-radius: 4px;
  margin-bottom: 8px;
}
.bb--alt-shimmer .al__address:hover,
.alb--alt-shimmer .al__address:hover {
  cursor: auto;
  cursor: initial;
}
.al--listing-box-sh .al--listing .al__icon {
  width: 20px;
  height: 20px;
  min-width: 20px;
  background-color: #F5F5F5;
  border-radius: 4px;
}
.al--listing-box-sh .al--listing .al__text {
  width: 120px;
  height: 20px;
  background-color: #F5F5F5;
  border-radius: 4px;
  margin-right: 0;
}
.alb--alt-shimmer .buildingBtn__shimmer {
  margin: 5px 0;
}
.badge--off {
  display: inline-flex;
  align-items: center;
}
.buildingBtn__shimmer {
  width: 90px;
  min-width: 90px;
  height: 24px;
  background-color: #D2D2D2;
  border-radius: 12px;
  display: inline-flex;
}
.alb--alt-shimmer .buildings__next {
  color: #F5F5F5;
}
.bb--alt-shimmer,
.alb--alt-shimmer,
.upcomingTour--shimmer {
  cursor: auto;
  cursor: initial;
}
.bb--alt-shimmer:hover,
.alb--alt-shimmer:hover,
.upcomingTour--shimmer:hover {
  border-color: #BCBAB8;
}
.upcomingTour--shimmer .upComTour__icon {
  background-color: #EDEDED;
  border-radius: 4px;
  height: 24px;
  width: 28px;
  margin-bottom: 4px;
}
.upcomingTour--shimmer .upComTour__name {
  background-color: #EDEDED;
  border-radius: 4px;
  width: 126px;
  height: 18px;
  margin-top: 8px;
}
.upcomingTour--shimmer .upComTour__list li .upComTour__list-icon {
  background-color: #EDEDED;
  border-radius: 4px;
  width: 18px;
  height: 18px;
}
.upcomingTour--shimmer .upComTour__list li .upComTour__list-text {
  background-color: #EDEDED;
  border-radius: 4px;
  height: 18px;
}
.upcomingTour--shimmer .upComTour__list li:nth-of-type(1) .upComTour__list-text {
  width: 80%;
}
.upcomingTour--shimmer .upComTour__list li:nth-of-type(2) .upComTour__list-text {
  width: 60%;
}
.upcomingTour--shimmer .upComTour__list li:nth-of-type(3) .upComTour__list-text {
  width: 50%;
}
.upcomingTour--shimmer .btn__span {
  background-color: #EDEDED;
  border-radius: 10px;
  height: 32px;
  width: 100%;
  min-width: 200px;
  display: inline-flex;
}
.tl--shimmer .tourListing__img,
.tl--plus-shimmer .tourListing__img {
  background-color: #F5F5F5;
  border-radius: 10px;
}
.tl--shimmer .tourListing--row .para__md-dark,
.tl--plus-shimmer .tourListing--row .para__md-dark,
.tl--alt-shimmer .tourListing--row .para__md-dark {
  background-color: #EDEDED;
  border-radius: 4px;
  height: 20px;
  width: 80%;
}
.tl--plus-shimmer .plus__shimmer,
.tl--alt-shimmer .plus__shimmer {
  color: #fff;
  font-size: 12px;
  width: 32px;
  height: 32px;
  min-width: 32px;
  background-color: #EDEDED;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.tl--alt-shimmer .tourListing__img {
  background-color: #EDEDED;
}
.addBtn__shimmer {
  padding: 5px;
  border-radius: 10px;
  min-width: 94px;
  min-height: 32px;
  display: inline-flex;
  background-color: #EDEDED;
}
.breadcrumb--shimmer {
  margin-bottom: 10px;
  display: flex;
}
.breadcrumb--shimmer .breadcrumbShimmer__span {
  background-color: #EDEDED;
  border-radius: 4px;
  height: 16px;
  display: inline-flex;
  margin-right: 8px;
}
.breadcrumb--shimmer .breadcrumbShimmer__span:first-child {
  width: 40px;
}
.breadcrumb--shimmer .breadcrumbShimmer__span:last-child {
  width: 158px;
}
.dashboard--bar-shimmer {
  min-height: 107px;
}
.dashboard--bar-shimmer .db--preview {
  background-color: #EDEDED;
  border-radius: 4px;
  height: 20px;
  width: 100%;
  max-width: 300px;
  margin-top: 10px;
  margin-bottom: 8px;
  display: flex;
}
.db__btns {
  width: 188px;
  height: 48px;
  background-color: #EDEDED;
  border-radius: 10px;
  display: inline-flex;
}
.tourEditTop--shimmer {
  background-color: #F5F5F5;
  border-radius: 15px;
}
.placement--shimmer-alt {
  background-color: #EBEBEB;
  border-radius: 15px;
  min-height: 327px;
}
.dropdown--shimmer {
  border: 1px solid #BCBAB8;
  border-radius: 10px;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 14px;
}
.dropdown--shimmer .dropdownShimmer__icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
  background-color: #EDEDED;
  border-radius: 4px;
  margin-right: 12px;
  display: inline-flex;
}
.dropdown--shimmer .dropdownShimmer__text {
  background-color: #EDEDED;
  border-radius: 4px;
  height: 18px;
  width: 100%;
  max-width: 200px;
  display: inline-flex;
  margin-right: auto;
}
.dropdown--shimmer .dropdownShimmer__arrow {
  display: inline-flex;
  color: #222222;
  font-size: 10px;
  line-height: 10px;
  margin-right: 2px;
  margin-left: 8px;
}
.lt__shimmer {
  display: inline-flex;
  min-width: 74px;
  height: 20px;
  background-color: #EDEDED;
  margin-left: 12px;
  border-radius: 4px;
}
.tourEdit--listing-shimmer .tel--top .telTop__icon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  margin-right: 8px;
  display: inline-flex;
  background-color: #EDEDED;
  border-radius: 4px;
}
.tourEdit--listing-shimmer .tel--top .telTop__text {
  height: 18px;
  min-width: 18px;
  display: inline-flex;
  background-color: #EDEDED;
  border-radius: 4px;
  width: 100%;
  max-width: 270px;
}
.telBtn__shimmer {
  min-height: 48px;
  min-width: 76px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #BCBAB8;
  border-radius: 10px;
  padding: .375rem .65rem;
}
.telBtn__shimmer .telBtn__shimmer-icon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  background-color: #EDEDED;
  border-radius: 4px;
}
.telBtn__shimmer .telBtn__shimmer-text {
  height: 18px;
  min-width: 50px;
  background-color: #EDEDED;
  border-radius: 4px;
  flex: 1 1;
}
.teamBlock--shimmer .teamBlock--img {
  background-color: #EDEDED;
}
.teamBlock--shimmer .teamBlock__user {
  height: 16px;
  width: 110px;
  background-color: #EDEDED;
  border-radius: 4px;
  display: inline-flex;
  margin-bottom: 6px;
}
.teamBlock--shimmer .teamBlock__email {
  height: 16px;
  width: 150px;
  background-color: #EDEDED;
  border-radius: 4px;
  display: inline-flex;
}
.search--shimmer-lg {
  display: flex;
  align-items: center;
  min-height: 70px;
  padding: 12px 24px;
  background-color: #F5F5F5;
  border-radius: 10px;
}
.search--shimmer-lg .search__icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-color: #EDEDED;
  border-radius: 4px;
  margin-right: 16px;
  display: inline-flex;
}
.search--shimmer-lg .search__text {
  width: 63%;
  height: 24px;
  background-color: #EDEDED;
  border-radius: 4px;
  display: inline-flex;
}
.searchBs--shimmer {
  display: flex;
  border: 1px solid #BCBAB8;
  border-radius: 16px;
  padding: 14px 16px;
  margin-bottom: 20px;
  min-height: 94px;
}
.searchBs--shimmer .sb__shimmer-img {
  width: 224px;
  min-width: 224px;
  height: 64px;
  border-radius: 10px;
  display: flex;
  margin-right: 20px;
  background-color: #EDEDED;
}
.searchBs--shimmer .search--bs-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
}
.searchBs--shimmer .search--bs-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
}
.searchBs--shimmer .sb__shimmer-heading {
  height: 20px;
  width: 50%;
  background-color: #EDEDED;
  border-radius: 4px;
  margin-bottom: 12px;
}
.searchBs--shimmer .bs__next {
  color: #EDEDED;
  font-size: 16px;
  margin: 0px 8px 0px 16px;
}
.searchBs--shimmer-alt {
  min-height: 55px;
}
.searchBs--shimmer-alt .search--bs-inner {
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}
.searchBs--shimmer-alt .sb__shimmer-heading {
  margin-bottom: 0;
  margin-right: 30px;
  width: 100%;
  max-width: 232px;
}
.searchBs--shimmer-alt .buildingBtn__shimmer {
  margin-left: 8px;
  margin-right: 8px;
}
.photos--box-shimmer {
  width: 160px;
  height: 160px;
  min-width: 160px;
  display: flex;
  border-radius: 16px;
  position: relative;
  margin-bottom: 14px;
  background-color: #EDEDED;
}
.block--search-row .photos--box-shimmer {
  width: 100%;
  min-width: 10px;
  height: 180px;
}
.video--box-shimmer {
  width: 330px;
  min-width: 330px;
  height: 185px;
  border-radius: 16px;
  display: flex;
  position: relative;
  margin-bottom: 14px;
  background-color: #EDEDED;
}
.block--search-row .video--box-shimmer {
  width: 100%;
  min-width: 10px;
}
.pdf__btn-shimmer {
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  padding: 11px 16px;
  width: 100%;
  min-height: 72px;
  margin-bottom: 14px;
  background-color: #F6F6F6;
}
.pdf__btn-shimmer .pdf__btn-icon {
  min-width: 32px;
  height: 38px;
  display: inline-flex;
  margin-right: 18px;
  background-color: #EDEDED;
  border-radius: 4px;
}
.pdf__btn-shimmer .pdf__btn-text {
  width: 100%;
  height: 18px;
  display: inline-flex;
  background-color: #EDEDED;
  border-radius: 4px;
  margin-bottom: 8px;
}
.pdf__btn-shimmer .pdf__btn-off .pdf__btn-text:last-child {
  margin-bottom: 0;
}
.pdf__btn-shimmer .pdf__btn-off {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.sidebar--shimmer {
  background-color: #757879;
}
.dh--shimmer .dhShimmer__heading {
  background-color: #EDEDED;
  width: 100%;
  max-width: 200px;
  height: 28px;
  border-radius: 4px;
  display: inline-flex;
}
.dh--shimmer .dhShimmer__para {
  background-color: #EDEDED;
  width: 100%;
  max-width: 90%;
  height: 20px;
  border-radius: 4px;
  display: inline-flex;
}
.db--shimmer {
  background-color: #F5F5F5;
  min-height: 70px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 12px;
}
.db--shimmer .dbShimmer__text {
  height: 22px;
  background-color: #EDEDED;
  border-radius: 4px;
  width: 100%;
  max-width: 152px;
}
.db--shimmer .dbShimmer__icon {
  width: 22px;
  height: 22px;
  min-width: 22px;
  background-color: #EDEDED;
  border-radius: 4px;
  margin-left: 10px;
}
.search--shimmer {
  display: flex;
  align-items: center;
  min-height: 48px;
  padding: 10px 16px;
  background-color: #F5F5F5;
  border-radius: 10px;
  width: 100%;
}
.search--shimmer .searchShimmer__icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
  background-color: #EDEDED;
  border-radius: 4px;
  margin-right: 12px;
  display: inline-flex;
}
.search--shimmer .searchShimmer__text {
  width: 70%;
  height: 16px;
  background-color: #EDEDED;
  border-radius: 4px;
  display: inline-flex;
}
.button__shimmer {
  border: none;
  text-align: center;
  padding: 10px 20px;
  min-height: 48px;
  color: #fff;
  background-color: #F5F5F5;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.button__shimmer .button__shimmer-text {
  width: 100%;
  max-width: 100px;
  height: 16px;
  background-color: #EDEDED;
  border-radius: 4px;
}
.button__shimmer .button__shimmer-icon {
  width: 14px;
  min-width: 14px;
  height: 14px;
  background-color: #EDEDED;
  border-radius: 4px;
  margin-left: 8px;
}
.button__shimmer-lg {
  min-width: 200px;
}
.button__shimmer-sm {
  padding: 10px 16px;
  min-width: 188px;
}
.dashbaord--opt .button__shimmer-sm {
  margin-left: 20px;
}
.shimmer__style {
  background-color: #EDEDED;
  border-radius: 4px;
  display: inline-flex;
}
.header__logo-shimmer {
  width: 151px;
  height: 39px;
}
.header__bars-shimmer {
  width: 24px;
  height: 18px;
}
.viewBy--shimmer {
  min-height: 26px;
}
.viewBy--shimmer .viewBy__text {
  height: 18px;
  min-width: 61px;
}
.viewBy--shimmer .viewBy__marker {
  height: 22px;
  min-width: 15px;
}
.grid--shimmer .grid__view {
  width: 20px;
  height: 22px;
}
.shimmer__heading-al {
  width: 50%;
  height: 28px;
}
.alb--lg-shimmer:hover {
  cursor: auto;
  cursor: initial;
  border-color: #BCBAB8;
}
.alb--lg-shimmer .al__address {
  width: 100%;
  max-width: 230px;
  height: 24px;
  margin-bottom: 12px;
}
.sdf__para-shimmer {
  width: 100%;
  max-width: 210px;
  height: 18px;
  margin-bottom: 16px;
}
.fl--shimmer {
  min-height: 24px;
}
.fl--shimmer .fl--shimmer-span {
  height: 18px;
  margin-right: 24px;
}
.fl--shimmer .fl--shimmer-span:last-child {
  margin-right: 0;
}
.fl--shimmer .fl--shimmer-span:nth-of-type(1) {
  min-width: 55px;
}
.fl--shimmer .fl--shimmer-span:nth-of-type(2) {
  min-width: 95px;
}
.fl--shimmer .fl--shimmer-span:nth-of-type(3) {
  min-width: 136px;
}
.fp__box {
  min-height: 88px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}
.fp__box .fp__box-span {
  height: 18px;
  width: 100%;
  max-width: 152px;
  margin-bottom: 4px;
}
.fp__box .fp__box-span:nth-of-type(2) {
  max-width: 235px;
}
.shimmer__back {
  display: inline-flex;
  align-items: center;
  min-height: 24px;
}
.shimmer__back .back__btn-icon {
  color: #EDEDED;
}
.shimmer__back .shimmer__back-text {
  height: 20px;
  width: 40px;
}
.shimmer__heading-lg {
  height: 40px;
  width: 100%;
  max-width: 50%;
}
.shimmer__heading-md {
  height: 24px;
  width: 100%;
  max-width: 25%;
}
.shimmer--keybox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: #F7F7F7;
  border-radius: 20px;
  margin-bottom: 20px;
  min-height: 118px;
}
.shimmer--keybox .keyBox--head {
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
}
.shimmer--keybox .keyBox--head .keyBoxHead__icon {
  height: 16px;
  min-width: 16px;
  margin-right: 10px;
}
.shimmer--keybox .keyBox--head .keyBoxHead__text {
  height: 16px;
  width: 100%;
  max-width: 104px;
}
.shimmer--keybox .shimmer__heading-md {
  height: 18px;
  max-width: 90px;
  margin-bottom: 10px;
}
.shimmer--keybox .small__paragraph {
  height: 16px;
  width: 100%;
  max-width: 90px;
}
.shimmer--keybox-expand .small__paragraph {
  max-width: 275px;
}
.shimmer--video {
  height: 467px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 20px;
  background-color: #F7F7F7;
}
.shimmer__play-btn {
  color: #fff;
  font-size: 28px;
  width: 60px;
  height: 60px;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.button__shimmer.button__shimmer-expand .button__shimmer-text {
  max-width: 300px;
}
.photos--lg-shimmer {
  width: 200px;
  height: 200px;
  min-width: 200px;
  display: flex;
  border-radius: 16px;
  margin-bottom: 14px;
}
.block--search-row .photos--lg-shimmer {
  width: 100%;
  min-width: 10px;
}
.shimmer--dc .para__md-gray {
  height: 20px;
  width: 100%;
  margin-bottom: 8px;
}
.shimmer--dc .dc--para-column .para__md-gray:last-child {
  margin-bottom: 0;
}
.map--block-shimmer .shimmer__heading-lg,
.sdl--combine .shimmer__heading-lg {
  max-width: 100%;
  width: auto;
  width: initial;
  min-width: 224px;
}
.spaces--box-shimmer {
  min-height: 21px;
}
.spaces--box-shimmer .para__sm-gray {
  min-height: 16px;
  min-width: 112px;
}
.shimmer--floorplan .floorplan--img {
  height: 350px;
  width: 100%;
}
.map--block-shimmer .map--box {
  border-radius: 20px;
}
.specs--box-shimmer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  min-height: 104px;
  background-color: #F6F6F6;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;
}
.specs--box-shimmer .specsBox__head {
  min-height: 21px;
  width: 100%;
}
.specs--box-shimmer .specsBox__head .specsBox__head-icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
.specs--box-shimmer .specsBox__head .specsBox__head-text {
  width: 100%;
  max-width: 88px;
  height: 14px;
}
.specs--box-shimmer .para__sm-gray {
  height: 18px;
  width: 100%;
  max-width: 88px;
  margin-top: auto;
  margin-bottom: auto;
}
.specsBox--img-shimmer {
  flex-direction: row;
  justify-content: center;
}
.specs--box-shimmer .specsBox__head-img {
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.specs--box-shimmer .para__sm-gray {
  margin-bottom: 6px;
}
.specs--box-shimmer .para--column {
  min-width: 88px;
}
.specs--box-shimmer .para--column .para__sm-gray:last-child {
  margin-bottom: 0;
}
.specsBox--shimmer-alt {
  justify-content: center;
}
.specsBox--shimmer-alt .para__sm-gray {
  max-width: 150px;
}
.subdomain__footer-shimmer {
  background-color: #757879;
  min-height: 306px;
}
.shimmer__md .shimmer__md-text {
  height: 24px;
  width: 100%;
  max-width: 80%;
}
.shimmer__md .shimmer__md-link {
  height: 24px;
  min-width: 85px;
}
.do--btn-canvas {
  display: inline-flex;
}
.do--btn-canvas .db__btns {
  margin-left: 20px;
}
.tab--shimmer {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(188,186,184,0.5);
}
.scroll--hide-x {
  overflow-x: auto;
}
.scroll--hide-x::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.tab--shimmer .tabShimmer--item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  min-height: 52px;
  position: relative;
}
.tab--shimmer .tabShimmer--link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 0px 20px;
  min-width: 60px;
}
.tab--shimmer .tabShimmer__icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
}
.tab--shimmer .tabShimmer__text {
  height: 16px;
  width: 100%;
  min-width: 60px;
  margin-left: 16px;
}
.tab--shimmer .tabShimmer__border {
  height: 2px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.subTours--block-shimmer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: 152px;
}
.subTours--video-shimmer {
  width: 100%;
  height: 112px;
  border-radius: 10px;
  background-color: #F6F6F6;
  margin-bottom: 16px;
}
.subTours--block-shimmer .para__sm-gray {
  height: 20px;
  width: 100%;
  max-width: 150px;
}
.bb--inner-shimmer {
  min-height: 70px;
}
.bb--inner-shimmer .biShimmer__heading {
  height: 30px;
  width: 100%;
  max-width: 90px;
  margin-bottom: 20px;
}
.bb--inner-shimmer .biShimmer__text {
  height: 20px;
  width: 100%;
  max-width: 120px;
}
.bb--alt-shimmer .buildings__next {
  color: #F5F5F5;
}
.dashbord__shimmer-md {
  height: 20px;
  width: 100%;
  max-width: 150px;
}











@-webkit-keyframes fullView {
  100% {
    width: 100%;
  }
}











@keyframes fullView {
  100% {
    width: 100%;
  }
}
.animate {
  -webkit-animation : shimmer 4s infinite linear;
          animation : shimmer 4s infinite linear;
  background: linear-gradient(to right, #F5F5F5 4%, #e2e2e2 25%, #F5F5F5 36%);
  background-size: 1000px 100%;
}
.animate--dark {
  -webkit-animation : shimmer 4s infinite linear;
          animation : shimmer 4s infinite linear;
  background: linear-gradient(to right, #EDEDED 4%, #e2e2e2 25%, #EDEDED 36%);
  background-size: 1000px 100%;
}
.animate--gray {
  -webkit-animation : shimmer 4s infinite linear;
          animation : shimmer 4s infinite linear;
  background: linear-gradient(to right, #e8ecef 4%, #e2e2e2 25%, #e8ecef 36%);
  background-size: 1000px 100%;
}
@-webkit-keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}








@media screen and (min-width: 992px) {
  .shimmer--dc {
    min-height: 520px;
  }
  .shimmer--floorplan {
    min-height: 550px;
  }
}
@media screen and (max-width: 1440px) {
  .pdf__btn-shimmer {
    padding: 10px 12px;
  }
  .pdf__btn-shimmer .pdf__btn-icon {
    margin-right: 12px;
    min-width: 24px;
    height: 28px;
  }
  .pdf__btn-shimmer .pdf__btn-text {
    height: 16px;
  }
  .block--search-row .photos--box-shimmer {
    height: 160px;
  }
}
@media screen and (max-width: 1200px) {
  .db--btns-shimmer {
    width: 100%;
  }
  .search--shimmer-lg .search__text {
    width: 80%;
  }
  .searchBs--shimmer .sb__shimmer-heading {
    width: 70%;
  }
  .db--shimmer {
    margin-top: 20px;
  }
}
@media screen and (max-width: 991px) {
  .alb--alt-shimmer .buildingBtn__shimmer {
    margin-left: 6px;
    margin-right: 6px;
  }
  .alb--alt-shimmer .al--listing-box-sh {
    margin-top: 14px;
  }
  .shimmer__heading-al {
    width: 75%;
  }
  .alb--lg-shimmer .al__address {
    margin-bottom: 20px;
  }
  .tab--shimmer .tabShimmer__text {
    display: none;
  }
  .bb--alt-shimmer .bb--inner-shimmer {
    width: 100%;
    margin-top: 12px;
  }
  .bb--alt-shimmer .bb--sf-shimmer .bb--inner-shimmer:first-child {
    align-items: flex-start;
    padding-right: 8px;
  }
  .bb--alt-shimmer .bb--sf-shimmer .bb--inner-shimmer:last-child {
    align-items: flex-end;
    padding-left: 8px;
  }
}
@media screen and (max-width: 767px) {
  .search--shimmer-lg {
    padding: 4px 15px;
    min-height: 56px;
  }
  .search--shimmer-lg .search__icon {
    width: 16px;
    height: 16px;
    min-width: 16px;
    margin-right: 10px;
  }
  .search--shimmer-lg .search__text {
    height: 16px;
    width: 100%;
  }
  .searchBs--shimmer .sb__shimmer-img {
    margin: 0px auto 20px auto;
    height: 80px;
  }
  .searchBs--shimmer .search--bs-inner {
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
  }
  .searchBs--shimmer .sb__shimmer-heading {
    width: 100%;
    max-width: 240px;
  }
  .searchBs--shimmer {
    flex-direction: column;
  }
  .searchBs--shimmer .search--bs-detail {
    flex-direction: column;
  }
  .searchBs--shimmer .bs__next {
    margin-left: auto;
  }
  .searchBs--shimmer-alt .search--bs-inner {
    margin-bottom: 14px;
    width: 100%;
    flex-direction: column;
  }
  .searchBs--shimmer-alt .sb__shimmer-heading {
    margin: 0px 0px 20px 0px;
  }
  .photos--lg-shimmer,
  .photos--box-shimmer {
    width: 120px;
    height: 120px;
    min-width: 120px;
  }
  .video--box-shimmer {
    width: 180px;
    min-width: 180px;
    height: 130px;
  }
  .viewBy--shimmer {
    margin-top: 16px;
    margin-bottom: 10px;
  }
  .shimmer__heading-al {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .do--btn-canvas,
  .tl--shimmer .tourListing--row .para__md-dark,
  .tl--plus-shimmer .tourListing--row .para__md-dark,
  .tl--alt-shimmer .tourListing--row .para__md-dark,
  .db--btns-shimmer .db__btns {
    width: 100%;
  }
  .placement--shimmer-alt {
    min-height: 165px;
  }
  .db--shimmer {
    min-height: 60px;
  }
  .lt__shimmer {
    margin-left: 0;
    max-width: 76px;
  }
  .dashbaord--opt .button__shimmer {
    width: 100%;
    margin-top: 16px;
    margin-left: 0;
  }
  .button__shimmer .button__shimmer-text {
    max-width: 120px;
  }
  .photos--lg-shimmer {
    width: 100%;
    min-width: 10px;
  }
  .subdomain__footer-shimmer .fp__box {
    align-items: center;
  }
  .do--btn-canvas .db__btns {
    margin-left: 0;
    width: 100%;
    margin-top: 16px;
  }
}
@media screen and (max-width: 480px) {
  .photos--box-shimmer,
  .video--box-shimmer {
    width: 100%;
    min-width: 10px;
  }
}


/* write custom css here */
button:disabled {
  cursor: not-allowed;
}
/*
  Loader
*/
.lds-canvas {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 555;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
}
.lds-canvas .lds-spinner {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
}
.lds-canvas .lds-spinner div {
  transform-origin: 40px 40px;
  -webkit-animation: lds-spinner 1.2s linear infinite;
          animation: lds-spinner 1.2s linear infinite;
}
.lds-canvas .lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 25px;
  left: 39px;
  width: 2px;
  height: 10px;
  border-radius: 33%;
  background: #000000;
}
.lds-spinner {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
}
.lds-spinner div {
  transform-origin: 9px 8px;
  -webkit-animation: lds-spinner 1.2s linear infinite;
          animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  width: 1px;
  height: 5px;
  border-radius: 20%;
  background: #000000;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 Loader
*/
.app__button .lds-spinner {
  padding-top: 1px;
  padding-left: 4px;
}
.app__button:hover .lds-spinner div:after,
.app__button:focus .lds-spinner div:after,
.app__button:active .lds-spinner div:after {
  background: #1c1f20;
}
.app__button .lds-spinner div:after {
  background: #fff;
}
.signin--page .app__button .lds-spinner div:after {
  background-color: #1c1f20;
}
.admin__button .lds-spinner,
.schedule__btn .lds-spinner,
.outline__button .lds-spinner {
  padding-left: 4px;
  padding-top: 1px;
}
.admin__button .lds-spinner div:after,
.schedule__btn .lds-spinner div:after,
.profile--img-upload .lds-spinner div:after {
  background: #fff;
}
.schedule__btn:hover .lds-spinner div:after,
.schedule__btn:focus .lds-spinner div:after,
.schedule__btn:active .lds-spinner div:after {
  background: #EAEDF4;
}
.profile--img-upload .lds-spinner {
  padding-left: 3px;
  padding-top: 5px;
}
.adminSettings--upload-item .lds-spinner {
  padding-left: 3px;
}
.forms--group .lds-spinner {
  position: absolute;
  right: 6px;
  bottom: 14px;
}
.outline__button:hover .lds-spinner div:after,
.outline__button:focus .lds-spinner div:after,
.outline__button:active .lds-spinner div:after {
  background: #fff;
}
.fields--group .error__msg {
  position: absolute;
  left: 0;
  top: 52px;
  color: #e7063c;
  font-size: 12px;
}
.checkbox--set {
  min-width: 250px;
}
.checkbox--set .error__msg {
  position: absolute;
  left: 0;
  top: 26px;
  color: #e7063c;
  font-size: 12px;
}
.custom__file .error__msg {
  position: absolute;
  left: 0;
  top: 32px;
  color: #e7063c;
  font-size: 12px;
}
.uploadPhoto--progress .uploadPhoto__error {
  background-color: red;
}
.spinner--group .form-control {
  padding-right: 32px;
}
.spinner--group .lds-spinner {
  position: absolute;
  right: 11px;
  top: 16px;
}
.spinner--group .lds-spinner div:after {
  background: #000;
}
.spinner--group.listing--dropdown .lds-spinner {
  right: 30px;
}
.google--suggestions--container {
  padding: 4px 0;
  font-size: 14px;
  border: 1px solid #0638e7;
  position: absolute;
  left: 0;
  right: 0;
  top: 47px;
  background-color: #fff;
  z-index: 5;
  max-height: 240px;
  overflow-y: auto;
}
.google--suggestions--container::-webkit-scrollbar {
  width: 3px;
}
.google--suggestions--container::-webkit-scrollbar-thumb {
  background: #bcbab8;
}
.listing--dropdown-pad .dropdown-toggle {
  padding-right: 48px;
}
.google--suggestions--container .google--places--suggestions {
  line-height: 20px;
  padding: 10px 13px;
  color: #6a7074;
}
.google--suggestions--container .google--places--suggestions:hover {
  background: #0638e7;
  color: #fff;
  cursor: pointer;
}
.step--upload-block .lds-spinner {
  padding-top: 5px;
}
.step--upload-block .lds-spinner div:after {
  background: #000;
}
.listing--dropdown .error {
  border: 1px solid #e7063c;
}
.combine--group .error__msg {
  position: absolute;
  top: 52px;
  left: 0;
  color: #e7063c;
  font-size: 12px;
}
.combine--group .error__msg.edit {
  top: 80px;
}
.spinner--group.select--group .lds-spinner {
  right: 30px;
}
.spinner--group.select--group .form-control {
  padding-right: 52px;
}
/*.dashboard--content .lds-canvas .lds-spinner div:after {
  background: #0638E7;
}
.listing .lds-canvas .lds-spinner div:after {
  background: #0638E7;
}*/
.al--block {
  cursor: pointer;
}
.loading--more {
  text-align: center;
}
.loading--more .lds-spinner {
  padding-top: 5px;
  padding-left: 6px;
}
.loading--more .lds-spinner div:after {
  background: #000000;
}
.options__list li a .lds-spinner {
  padding-left: 5px;
}
.options__list li a:hover .lds-spinner div:after,
.options__list li a:focus .lds-spinner div:after {
  background: #0638e7;
}
/* .listing-video {
  border: 2px solid;
  box-shadow: 2px 2px 10px;
}
.listing-video:before {
  position: relative !important;
} */
.building-single-listing {
  font-weight: normal;
}
div.css-1hb7zxy-IndicatorsContainer > span {
  display: none;
}
div.css-yk16xz-control,
div.css-1pahdxg-control {
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  border: 1px solid #bcbab8;
  min-height: 48px !important;
  padding: 6px 12px !important;
}
div.css-yk16xz-control:hover {
  border-color: #bcbab8 !important;
}
div.css-1pahdxg-control:hover,
div.css-1pahdxg-control {
  border-color: #0638e7 !important;
}
.css-1wa3eu0-placeholder {
  font-size: 14px;
  color: #6a7074 !important;
}
.css-1hwfws3 {
  padding: 2px 10px !important;
}
.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  padding: 4px !important;
}
.css-26l3qy-menu {
  border-radius: 0px !important;
  border: none !important;
  box-shadow: 0px 1px 2px rgba(34, 39, 44, 0.1) !important;
  z-index: 55 !important;
}
.css-1uccc91-singleValue {
  font-size: 14px;
  color: #222222;
}
.css-9gakcf-option {
  font-size: 14px !important;
  color: #222222 !important;
  word-break: break-word;
  background-color: #deebff !important;
}
.css-1rhbuit-multiValue {
  border-radius: 0px !important;
  background-color: #1c1f20 !important;
}
.css-g1d714-ValueContainer {
  padding: 0 !important;
}
.css-12jo7m5 {
  font-size: 14px !important;
  padding-left: 8px !important;
  line-height: 20px !important;
  color: #fff !important;
}
.css-xb97g8 {
  border-radius: 0px !important;
  cursor: pointer;
  color: #fff !important;
}
.css-xb97g8:hover {
  background: transparent !important;
  color: #fff !important;
}
.css-4ljt47-MenuList {
  overflow-x: hidden;
}
.css-gg45go-NoOptionsMessage {
  font-size: 14px !important;
  color: #8e91a0 !important;
}
.css-yt9ioa-option,
.css-1n7v3ny-option,
.css-165luzk-option,
.css-19jh2ze-option {
  font-size: 14px !important;
  color: #8e91a0 !important;
  word-break: break-word;
}
.css-yt9ioa-option:focus,
.css-1n7v3ny-option:focus,
.css-165luzk-option:focus,
.css-19jh2ze-option:focus {
  outline: none !important;
}
.css-4ljt47-MenuList::-webkit-scrollbar {
  width: 5px;
}
.css-4ljt47-MenuList::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.css-4ljt47-MenuList::-webkit-scrollbar-thumb {
  background: #e44d43;
  border-radius: 10px;
}
.css-1pahdxg-control:hover {
  border-color: white !important;
}
.css-1hwfws3 > div {
  border-radius: 22px !important;
}
.css-1hwfws3 .css-1wa3eu0-placeholder {
  background-color: transparent;
}
.css-1hwfws3 .css-b8ldur-Input {
  line-height: 18px;
  background-color: transparent;
}
div.css-tlfecz-indicatorContainer > svg.css-6q0nyr-Svg,
div.css-1gtu0rj-indicatorContainer > svg.css-6q0nyr-Svg {
  background: #1c1f20;
  border-radius: 0;
  color: white;
}
.css-a25fsq-multiValue svg.css-6q0nyr-Svg {
  color: white;
  width: 16px;
  height: 16px;
}
select {
  overflow: hidden;
  text-overflow: ellipsis;
}
select option {
  white-space: nowrap;
}
.textarea--group .error__msg,
.errorMsg--set .error__msg {
  top: auto;
  top: initial;
  bottom: -20px;
}
.listing--dropdown .dropdown-item.active {
  background-color: #0638e7;
  color: #fff;
}
.requestTour--box .forms--group .error__msg {
  font-size: 11px;
}
.listing-map-view {
  height: 100%;
  width: 100%;
}
.adminSettings--upload--logo .error__msg {
  position: absolute;
  top: 80px;
  color: #e7063c;
  font-size: 12px;
  margin-bottom: 0;
}
.adminSettings--row .forms--group .error__msg {
  top: 80px;
}
.adminSettings--row .adminSettings--upload--logo .error__msg {
  padding-left: 12px;
}
.profile--info .error__msg {
  color: #e7063c;
  font-size: 12px;
  margin-bottom: 0;
}
.virtual--video--active {
  position: relative;
  overflow: visible;
  z-index: 5;
}
@-webkit-keyframes highlight {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.025);
  }
  from {
    transform: scale(1);
  }
}
@keyframes highlight {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.025);
  }
  from {
    transform: scale(1);
  }
}
.virtual--video--active::after {
  content: '';
  position: absolute;
  transform-origin: center center;
  height: 100%;
  width: 100%;
  -webkit-animation-name: highlight;
          animation-name: highlight;
  -webkit-animation-duration: 1500ms;
          animation-duration: 1500ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  z-index: -1;
  background-color: #7ff87b;
}
.virtual--video-placeholder {
  background: black;
  height: 100%;
  width: 100%;
}
.bg-white-button {
  background-color: white;
}
.gallery--carousel {
  height: 440px;
}
.join--tour .error__msg {
  top: 56px;
}
.placeholder {
  color: lightgray;
}
.error__msg-white {
  color: white !important;
}
.liveTour--page {
  height: 100vh;
  background: #1c1f20;
}
.ca--group-main .error__msg {
  font-size: 12px;
  color: red;
  position: absolute;
}
.ca--group-main .edited__msg {
  font-size: 12px;
}
.custom__file .lds-spinner {
  padding-left: 4px;
}
.ca--cover {
  cursor: pointer;
}
.outline__button:disabled {
  border: 1px solid #222222;
  background-color: transparent;
  color: #222222;
}
.record__voice .lds-spinner {
  margin-top: 2px;
}
.record__voice .lds-spinner div:after {
  background: #0638e7;
}
.record__voice:hover .lds-spinner div:after {
  background: white;
}
.transparent--overlay {
  bottom: 33px;
  background-color: transparent;
}
.hidden--overlay {
  top: auto;
  top: initial;
  bottom: 0;
  height: 40px;
  background-color: transparent;
}
.status-text {
  text-align: center;
  margin-bottom: 0px;
}
.meeting--block {
  cursor: pointer;
}
.quill--group .ql-container {
  min-height: 130px;
  max-height: 300px;
  border: 1px solid #bcbab8;
  border-radius: 0;
  color: #222222;
  background-color: transparent;
  height: auto;
}
.quill--group .ql-editor {
  min-height: 130px;
  max-height: 300px;
  color: #222222;
  font-size: 14px;
  height: auto;
}
.hide--section {
  position: fixed !important;
  bottom: -10000px !important;
  left: -10000px !important;
  opacity: 0 !important;
  pointer-events: none !important;
}
@media screen and (max-width: 1199px) {
  .gallery--carousel {
    height: 672px;
  }
}
@media screen and (max-width: 991px) {
  .gallery--carousel {
    height: 440px;
  }
  .listing-sm-map {
    height: 420px;
  }
}
@media screen and (max-width: 767px) {
  .gallery--carousel {
    height: 672px;
  }
}
@media screen and (max-width: 480px) {
  .gallery--carousel {
    height: auto;
  }
}

.tourListingLoading {
  position: absolute;
}
.liveTour__list {
  overflow-x: visible;
  overflow-x: initial;
}
.liveTour--listing .container--fixed {
  height: 100%;
  overflow: hidden;
}
.ls--row .loading--more {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  max-width: 75px;
  text-align: center;
}
.ls--row .loading--more .lds-spinner {
  padding: 0;
}
.ls--row .loading--more span {
  font-size: 14px;
  color: #6a7074;
}

.lt__list_span {
  display: inline-flex;
  margin-top: -12px;
  margin-bottom: -12px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.upcomingTour--canvas {
  position: relative;
}
.upcomingTour--canvas .lds-canvas {
  position: absolute;
}
.upcomingTour--canvas .loading--more {
  display: flex;
  align-items: center;
  justify-content: center;
}
.upcomingTour--canvas .loading--more .lds-spinner {
  padding-top: 0;
}
.loading-fixed {
  margin-top: -24px;
}
.white--rounded-box {
  position: relative;
}
.white--rounded-box .lds-canvas {
  position: absolute;
}
.dashboard__btn .lds-spinner {
  margin-left: auto;
}
.dashboard__btn .lds-spinner div:after {
  top: 12px;
  bottom: 0;
  left: 8px;
  width: 2px;
  height: 8px;
}
.dashboard__btn:hover .lds-spinner div:after,
.dashboard__btn:focus .lds-spinner div:after {
  background-color: #fff;
}

.empty--photos__control-prev {
  margin-right: 3.2%;
}
.empty--photos__control-next {
  margin-left: 3.2%;
}
.form-option--radius .form-control {
  border-radius: 10px;
}
.subListing--video {
  cursor: pointer;
}
.listing-map-view {
  border-radius: 16px;
}
.linkExplorerEdge__img {
  background: url('/images/link-explorer-edge.svg') bottom center no-repeat;
}
.linkChrome__img {
  background: url('/images/only-chrome.svg') bottom center no-repeat;
}

