
@media screen and (min-width: 576px) {
  .liveTour--building-top,
  .liveTour--building-main {
    margin-top: 30px;
  }
  .users--row {
    overflow-x: auto;
  }
  .users--row-left {
    direction: ltr;
  }
  .users--row-right {
    direction: rtl;
  }
  .users--row-left {
    margin-right: 30px;
  }
  .users--row-right {
    margin-left: 30px;
  }
  .liveTour--content-tenant .users--block {
    z-index: 999;
  }
  .users--block {
    z-index: 5;
  }
  .users--content-tenant .users--box {
    margin-bottom: auto;
  }
  .tourEdit--top-gallery .liveTour--gallery-img {
    height: 104px;
  }
  .placement--block.placement--block-alt {
    padding: 32px 38px;
    max-width: 360px;
  }
  .placement--block-alt .placement__heading {
    font-size: 42px;
    line-height: 54px;
    margin-bottom: 22px;
  }
  .placement--block-alt .placement--location .placementLocation__text {
    font-size: 16px;
  }
  .placement--block-alt .placement--location .placementLocation__icon {
    font-size: 22px;
  }
  .tourPre--top .liveTour__logo {
    width: 151px;
    min-width: 151px;
    height: 39px;
  }
  .tourPre--top .liveTour__heading {
    font-size: 16px;
  }
  .tourPre--top .dashboard__heading {
    font-size: 24px;
  }
  .tourPre--top .specs__list li {
    font-size: 24px;
    line-height: 24px;
  }
  .tourPre--top .building--widget {
    padding: 10px 15px;
    min-height: 66px;
  }
  .tourPre--top .building--widget .bw__icon {
    font-size: 24px;
    margin-right: 10px;
    min-width: 24px;
  }
  .tourPre--top .building--widget .bw__heading {
    font-size: 14px;
    margin-bottom: 5px;
    line-height: 16px;
  }
  .tourPre--top .amenities--widget .bw__heading {
    margin-bottom: 0;
    line-height: 20px;
  }
  .tourPre--top .building--widget .bw__para {
    font-size: 14px;
  }
  .tourPre--top .building--widget .bw__arrow {
    font-size: 5px;
    margin-top: 14px;
  }
  .tourPre--top .certification--widget .bw__icon {
    width: 44px;
    height: 44px;
    min-width: 44px;
  }
  .tourPre--top .certification--widget .bw__heading {
    line-height: 22px;
  }
}
@media screen and (min-width: 768px) {
  .liveTour__list {
    width: 100%;
    overflow-x: auto;
  }
  .users--ov-lg .users--ov-name,
  .users--ov-lg .users--ov-icon {
    font-size: 12px;
  }
  .row--feature .col--feature {
    min-width: 21%;
  }
  .row--feature .col--feature-address {
    min-width: 37%;
  }
  .users--content-tenant .users--row-left {
    margin-bottom: auto;
  }
  .tourEdit--specs .liveTour--building-top .dashboard__heading {
    font-size: 24px;
  }
  .tourEdit--specs .specs__list li {
    font-size: 24px;
    line-height: 24px;
  }
  .tourEdit--specs .specs--widget-alt {
    padding: 10px 14px;
    min-height: 62px;
    margin-bottom: 10px;
  }
  .tourEdit--specs .specs--widget-alt .bw__icon {
    font-size: 14px;
    margin-right: 7px;
    min-width: 15px;
  }
  .tourEdit--specs .specs--widget-alt .bw__heading {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .tourEdit--specs .specs--widget-alt .bw__para {
    font-size: 11px;
  }
  .tourEdit--specs .building--widget .bw__arrow {
    font-size: 5px;
    margin-top: 12px;
  }
  .tourEdit--specs .specs--widget-alt.certification--widget .bw__icon {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }
  .tourEdit--specs .specs--widget-alt.certification--widget .bw__heading {
    font-size: 12px;
    margin-bottom: 0px;
    line-height: 18px;
  }
}
@media screen and (min-width: 992px) {
  .liveTour--page .container-fluid {
    padding-left: 70px;
    padding-right: 70px;
  }
  .tour__btn .tour__btn-desktop {
    display: block;
  }
  .tour__btn .tour__btn-mobile {
    display: none;
  }
  .users--row {
    padding-top: 8px;
  }
  .users--row-left {
    margin-right: 60px;
  }
  .users--row-right {
    margin-left: 60px;
  }
  .tourPre--top-home .placement--block {
    padding: 40px 54px;
    max-width: 460px;
    border-radius: 30px;
  }
  .tourPre--top-home .placement__logo {
    width: 151px;
    height: 39px;
    margin-bottom: 24px;
  }
  .tourPre--top-home .placement__heading {
    font-size: 56px;
    line-height: 72px;
    margin-bottom: 26px;
  }
  .tourPre--top-home .placement--location .placementLocation__icon {
    font-size: 24px;
  }
  .tourPre--top-home .placement--location .placementLocation__text {
    font-size: 18px;
  }
  .tourPre--top .building--widget {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 1280px) {
  .liveTour--page {
    height: 100vh;
  }
}
@media screen and (min-width: 1440px) {
  .placement--block {
    padding: 40px 54px;
    max-width: 460px;
    border-radius: 30px;
  }
  .placement__heading {
    font-size: 56px;
    line-height: 72px;
  }
  .placement--location .placementLocation__icon {
    font-size: 24px;
  }
  .placement--location .placementLocation__text {
    font-size: 18px;
  }
}
@media screen and (min-width: 1440px) and (min-height: 800px) {
  .liveTour--placement,
  .liveTour--gallery,
  .liveTour--custom {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .liveTour--gallery .liveTour--head {
    display: flex;
  }
  .liveTour--gallery-large {
    padding-left: 70px;
    padding-right: 70px;
  }
  .liveTour--gallery .gallery--large-canvas {
    top: 0;
    bottom: 0;
  }
  .gallery__cross {
    top: 0;
  }
  .liveTour--container {
    max-width: 980px;
  }
}
@media screen and (min-width: 1440px) and (min-height: 730px) {
  .liveTour--container {
    max-width: 980px;
  }
  .liveTour--building-top .dashboard__heading {
    font-size: 24px;
  }
  .specs__list li {
    font-size: 24px;
    line-height: 24px;
  }
  .liveTour__logo {
    width: 151px;
    min-width: 151px;
    height: 39px;
  }
  .liveTour__heading {
    font-size: 12px;
  }
  .building--widget {
    padding: 10px 15px;
    min-height: 66px;
  }
  .building--widget .bw__icon {
    font-size: 24px;
    margin-right: 10px;
    min-width: 24px;
  }
  .building--widget .bw__para {
    font-size: 14px;
  }
  .specs--widget .bw__heading {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .certification--widget .bw__icon {
    width: 44px;
    height: 44px;
    min-width: 44px;
  }
  .amenities--widget .bw__heading {
    font-size: 14px;
    line-height: 16px;
  }
  .building--widget .bw__arrow {
    font-size: 5px;
    margin-top: 14px;
  }
  .certification--widget .bw__heading {
    line-height: 22px;
  }
  .liveTour--container {
    max-width: 980px;
  }
}
@media screen and (min-width: 1620px), (min-height: 730px) {
  .building--widget {
    margin-bottom: 20px;
  }
  .liveTour--placement,
  .liveTour--gallery,
  .liveTour--custom {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .liveTour--gallery-large {
    padding-left: 70px;
    padding-right: 70px;
  }
  .liveTour--gallery .liveTour--head {
    display: flex;
  }
  .liveTour--gallery .gallery--large-canvas {
    top: 0;
    bottom: 0;
  }
  .gallery__cross {
    top: 0;
  }
  .liveTour--building-top .dashboard__heading {
    font-size: 24px;
  }
  .specs__list li {
    font-size: 24px;
    line-height: 24px;
  }
  .liveTour__logo {
    width: 151px;
    min-width: 151px;
    height: 39px;
  }
  .liveTour__heading {
    font-size: 12px;
  }
  .building--widget {
    padding: 10px 15px;
    min-height: 66px;
  }
  .building--widget .bw__icon {
    font-size: 24px;
    margin-right: 10px;
    min-width: 24px;
  }
  .building--widget .bw__para {
    font-size: 14px;
  }
  .specs--widget .bw__heading {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .certification--widget .bw__icon {
    width: 44px;
    height: 44px;
    min-width: 44px;
  }
  .amenities--widget .bw__heading {
    font-size: 14px;
    line-height: 16px;
  }
  .building--widget .bw__arrow {
    font-size: 5px;
    margin-top: 14px;
  }
  .certification--widget .bw__heading {
    line-height: 22px;
  }
}
@media screen and (max-width: 1440px), (max-height: 700px) {
  .liveTour--container {
    max-width: 548px;
  }
  .placement--block {
    padding: 18px 25px;
    max-width: 260px;
    border-radius: 20px;
  }
  .placement__logo {
    width: 102px;
    height: 27px;
    margin-bottom: 18px;
  }
  .placement__heading {
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 10px;
  }
  .placement--location .placementLocation__icon {
    font-size: 16px;
  }
  .placement--location .placementLocation__text {
    font-size: 12px;
  }
  .feature--block {
    padding: 20px 15px;
    min-height: 194px;
  }
  .feature--block .featureBlock__icon {
    font-size: 22px;
  }
  .feature--block .featureBlock__heading {
    font-size: 16px;
  }
  .featureBlock--sf .sf__digit {
    font-size: 20px;
  }
  .featureBlock--sf .sf__word {
    font-size: 16px;
  }
  .featureBlock--sf {
    margin-top: 16px;
    flex-direction: column;
  }
  .feature--block-alt {
    padding: 18px 15px;
    min-height: 236px;
  }
  .feature--block-alt .featureBlock__icon {
    font-size: 34px;
  }
  .feature--block-alt .featureBlock__heading {
    font-size: 20px;
  }
  .feature--block-alt .featureBlock--sf {
    margin-top: 22px;
    flex-wrap: wrap;
  }
  .feature--block-alt .featureBlock--sf .sf__digit {
    font-size: 36px;
    white-space: normal;
  }
  .feature--block-alt .featureBlock--sf .sf__word {
    font-size: 20px;
  }
  .liveTour--building-main .row--space-5 {
    margin-left: -3px;
    margin-right: -3px;
  }
  .liveTour--building-main .row--space-5 .col-md-3 {
    padding-left: 3px;
    padding-right: 3px;
  }
  .liveTour--gallery-row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .liveTour--gallery-row .liveTour--gallery-col {
    padding-left: 5px;
    padding-right: 5px;
  }
  .liveTour--gallery-img {
    height: 78px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 1366px) {
  .liveTour--top-broker {
    max-width: 890px;
    margin-left: auto;
    margin-right: auto;
  }
  .liveTour--content-broker {
    margin-top: 5px;
  }
}
@media screen and (max-width: 1280px) {
  .liveTour--top,
  .liveTour--video video {
    height: 380px;
  }
}
@media screen and (max-width: 991px) {
  .liveTour--content {
    border-radius: 0px 0px 20px 20px;
  }
  .users--row {
    padding-bottom: 30px;
  }
  .users--ov {
    top: inherit;
    bottom: -30px;
    right: inherit;
    left: -28px;
  }
  .liveTour__list li a {
    margin-left: 5px;
    margin-right: 5px;
    min-width: 55px;
  }
  .lt__list-box .lt__list-text {
    display: none;
  }
  .feature--block {
    padding: 18px 10px;
    min-height: 170px;
  }
  .feature--block .featureBlock__icon {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .featureBlock--sf {
    margin-top: 10px;
  }
  .feature--block-address h4.featureBlock__heading {
    margin-top: 16px;
  }
  .users--row .users--block:first-child .users--ov {
    left: 0;
  }
  .users--block-lg .users--box {
    width: 94px;
    height: 94px;
    min-width: 94px;
  }
  .users--broker .users--ov {
    left: 0;
  }
  .users--broker {
    right: 0px;
  }
  .users--block-lg {
    max-width: 180px;
  }
  .row--feature {
    margin-left: -4px;
    margin-right: -4px;
  }
  .row--feature .col--feature {
    padding-left: 4px;
    padding-right: 4px;
  }
  .tour__btn {
    padding-left: 6px;
    padding-right: 6px;
    min-width: 38px;
    text-transform: uppercase;
  }
  .gallery__btn {
    padding: 4px 10px;
    min-width: 90px;
    text-transform: inherit;
    margin-top: 4px;
  }
  .feature--block-alt {
    min-height: 210px;
  }
  .tourPre--top .liveTour--gallery-img {
    height: 110px;
  }
}
@media screen and (max-width: 767px) {
  .users--block-md .users--box,
  .users--block-lg .users--box {
    width: 64px;
    height: 64px;
    min-width: 64px;
  }
  .users--row .users--block {
    margin-left: 4px;
    margin-right: 4px;
  }
  .liveTour__list {
    flex-wrap: wrap;
    justify-content: center;
  }
  .users--block-lg {
    max-width: 124px;
  }
  .liveTour--content-broker {
    margin-top: 6px;
  }
  .users--alt .usersAlt__heading {
    font-size: 24px;
    line-height: 22px;
    margin-bottom: 2px;
  }
  .users--alt .usersAlt__subHeading {
    font-size: 11px;
  }
  .liveTour--card .card-header {
    display: none;
  }
  .liveTour--card .collapse {
    display: block;
  }
  .feature--block {
    padding: 12px 10px;
    min-height: 154px;
    margin-bottom: 8px;
  }
  .row--feature {
    flex-wrap: wrap;
  }
  .row--feature .col--feature {
    width: 33.33%;
  }
  .row--feature .col--feature-address {
    width: 100%;
    max-width: 100%;
  }
  .row--feature .col--feature:last-child .feature--block {
    margin-bottom: 0;
  }
  .liveTour--container {
    max-width: 100%;
  }
  .gallery__cross {
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    font-size: 14px;
    color: #050505;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .liveTour--building-main .row--space-5 .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .feature--block-alt .featureBlock__heading {
    font-size: 18px;
  }
  .feature--block-alt .featureBlock--sf {
    margin-top: 16px;
  }
  .feature--block-alt .featureBlock--sf .sf__digit {
    font-size: 24px;
  }
  .feature--block-alt .featureBlock--sf .sf__word {
    font-size: 13px;
  }
  .tourPre--top .liveTour--gallery-img {
    height: 78px;
  }
}
@media screen and (max-width: 575px) {
  .liveTour--top,
  .liveTour--video video {
    height: 250px;
  }
  .placement--block {
    padding: 12px 16px;
    max-width: 172px;
    border-radius: 12px;
  }
  .placement__logo,
  .placement--block-alt .listing__logo {
    width: 76px;
    height: 28px;
    margin-bottom: 8px;
  }
  .placement__heading {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 6px;
  }
  .placement--location .placementLocation__icon {
    font-size: 12px;
    margin-right: 6px;
  }
  .placement--location .placementLocation__text {
    font-size: 10px;
  }
  .users--content {
    padding-bottom: 12px;
  }
  .liveTour__list li a {
    min-height: 40px;
    min-width: 40px;
    padding: 8px 0;
    margin-left: 5px;
    margin-right: 5px;
  }
  .feature--block {
    padding: 10px 8px;
    min-height: 106px;
  }
  .feature--block .featureBlock__icon {
    font-size: 14px;
    margin-bottom: 4px;
  }
  .feature--block .featureBlock__heading {
    font-size: 12px;
  }
  .featureBlock--sf {
    margin-top: 5px;
    line-height: 16px;
  }
  .featureBlock--sf .sf__digit {
    font-size: 13px;
  }
  .featureBlock--sf .sf__word {
    font-size: 11px;
  }
  .feature--block-address h4.featureBlock__heading {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .users--broker {
    top: initial;
    bottom: 0;
  }
  .users--broker .users--ov {
    bottom: initial;
    top: -34px;
    right: 0;
  }
  .liveTour--custom {
    padding: 10px 0;
  }
  .liveTour--content {
    margin-top: 0;
  }
  .amenities--widget .bw__heading {
    font-size: 12px;
    line-height: 18px;
  }
  .users--content {
    flex-direction: column;
  }
  .users--row {
    justify-content: center;
    flex-wrap: wrap;
  }
  .liveTour--card .card-body {
    margin-top: 0;
  }
  .liveTour--building-main .row--space-5 .col-md-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .liveTour--custom, .liveTour--placement {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .liveTour--gallery .gallery--large-canvas {
    top: -15px;
    bottom: -15px;
  }
  .feature--block-alt .featureBlock--sf .sf__digit {
    font-size: 13px;
  }
  .feature--block-alt .featureBlock--sf .sf__word {
    font-size: 11px;
  }
  .feature--block-alt .featureBlock--sf {
    margin-top: 5px;
    line-height: 16px;
  }
  .tourEdit--specs .liveTour--building-main .row--space-5 {
    margin-left: -3px;
    margin-right: -3px;
  }
  .tourEdit--specs .liveTour--building-main .row--space-5 .col {
    padding-left: 3px;
    padding-right: 3px;
  }
  .tourEdit--top-specs .liveTour--container {
    padding-left: 0;
    padding-right: 0;
  }
  .tourEdit--top-specs .liveTour--building-top .dashboard__heading {
    font-size: 14px;
  }
  .tourEdit--top-specs .specs__list li {
    font-size: 11px;
    line-height: 18px;
  }
  .tourPre--top-home .placement--block {
    padding: 18px 25px;
    max-width: 260px;
    border-radius: 20px;
  }
  .tourPre--top-home .placement__logo {
    width: 102px;
    height: 27px;
    margin-bottom: 18px;
  }
  .tourPre--top-home .placement__heading {
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 10px;
  }
  .tourPre--top-home .placement--location .placementLocation__icon {
    font-size: 16px;
  }
  .tourPre--top-home .placement--location .placementLocation__text {
    font-size: 12px;
  }
  .liveTour--gallery-img,
  .tourPre--top .liveTour--gallery-img {
    height: 60px;
  }
}

